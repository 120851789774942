.publishDate {
  border: solid 1px #dedede;
}

.publishDate:hover {
  border: solid 1px #999999;
}

.disabled-input {
  color: #b6b6b6;
}

@media only screen and (min-width: 1400px) {
  .menuPages {
    font-size: 20px;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 2;
    /* letter-spacing: 0.25px; */
    color: #666666 !important;
    border: none !important;
    background-color: #e8e8e8 !important;
    margin: 0px 20px 10px;
  }

  .leftMarginLOSize {
    margin-left: -5%;
  }

  .headerQsTskChkBox {
    width: 4% !important;
  }

  .headerQsTskDesc {
    width: 80% !important;
    left: 4% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .menuPages {
    font-size: 24px;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 2;
    /* letter-spacing: 0.25px; */
    /* margin-top: -60px !important; */
    color: #666666 !important;
    border: none !important;
    background-color: #e8e8e8 !important;
    margin: 0px 20px 10px;
  }

  .leftMarginLOSize {
    margin-left: -3.9%;
  }

  .headerQsTskChkBox {
    width: 6% !important;
  }

  .headerQsTskDesc {
    width: 73% !important;
    left: 5.5% !important;
  }

  .mcq-col-curriculumn {
    width: 22% !important;
    font-size: 12px !important;
    margin-left: 12% !important;
  }
}

/* @media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .headerQsTskDesc {
    padding-right: 4rem !important;
  }
} */

@media only screen and (max-width: 868px) {
  .headerQsTskChkBox {
    width: 8% !important;
  }

  .headerQsTskDesc {
    width: 75.5% !important;
    left: 8% !important;
    /* border-right: 1px solid transparent !important; */
  }

  .mcq-col-curriculumn {
    width: 19% !important;
    font-size: 12px !important;
    margin-left: 15% !important;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 869px) {
  .leftMarginLOSize {
    margin-left: -2.5%;
  }

  .headerQsTskChkBox {
    width: 5.5% !important;
  }

  .headerQsTskDesc {
    width: 66.5% !important;
    left: 5% !important;
    border-right: 1px solid transparent !important;
  }
}

.curricumaCreteHeader {
  height: 75px;
  /* margin: 0 0 23px; */
  padding: 8px 15px 0 43px;
  object-fit: contain;
  background-color: #e8e8e8;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}

@media only screen and (max-width: 999px) {
  .headingCurr {
    /* margin-left: -0.2% !important; */
    font-weight: bold;
    font-stretch: normal;
    margin-bottom: 0;
    font-style: normal;
    letter-spacing: normal;
    color: #686868;
  }

  .menuPages {
    font-size: 15.5px;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 2;
    left: 1.2%;
    /* letter-spacing: 0.25px; */
    color: #666666 !important;
    border: none !important;
    background-color: #e8e8e8 !important;
    margin: 0px 10px 10px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  .headingCurr {
    /* margin-left: 0 !important; */
    font-weight: bold;
    font-stretch: normal;
    margin-bottom: 0;
    font-style: normal;
    letter-spacing: normal;
    color: #686868;
  }

  .notificationsCurriculum {
    position: absolute;
    left: 5%;
    margin-top: -5.4%;
  }

  .menuPages {
    font-size: 18px;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 2;
    /* letter-spacing: 0.25px; */
    /* margin-top: -60px !important; */
    color: #666666 !important;
    border: none !important;
    background-color: #e8e8e8 !important;
    margin: 0px 20px 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .headingCurr {
    /* margin-left: 0 !important; */
    font-weight: bold;
    font-stretch: normal;
    margin-bottom: 0;
    font-style: normal;
    letter-spacing: normal;
    color: #686868;
  }

  .notificationsCurriculum {
    position: absolute;
    left: 35%;
    margin-top: -3.4%;
  }
}

@media only screen and (min-width: 1400px) {
  .headingCurr {
    /* margin-left: 2.9% !important; */
    font-weight: bold;
    left: -0.7%;
    font-stretch: normal;
    margin-bottom: 0;
    font-style: normal;
    letter-spacing: normal;
    color: #686868;
  }

  .notificationsCurriculum {
    position: absolute;
    left: 35%;
    margin-top: -3.4%;
  }
}

.headingItems {
  padding-top: 12px !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #686868;
}

.widthddlCurr {
  width: 80% !important;
}

.txtName {
  width: 204% !important;
  height: 58px;
  padding: 8px;
  border: solid 1px #dedede;
}

.issueBox {
  width: 15%;
  height: 46px;
  padding: 8px;
  margin-left: 12px;
  border: solid 1px #dedede;
}

.column {
  float: left;
  width: 50%;
}

.inlineDisplay {
  display: flex !important;
  margin-bottom: 65px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.chk {
  margin-top: 20px;
  width: 24px;
  height: 24px;
  /* margin: 4px 15px 4px 0; */
}

.tskR {
  display: inline-flex;
  position: absolute;
  padding: 7px 0;
  margin-left: 39px;
}

.ddlretrain {
  width: 77.5% !important;
  height: 46px;
  padding-left: 10px;
  border: solid 1px #dedede !important;
  background: none !important;
  color: #686868 !important;
  border-radius: 0px !important;
}

.dtDiv {
  display: inline;
  position: absolute;
}

.globalTaskDisabled {
  pointer-events: none;
  opacity: 0.4;
  /* background-color: #e8e8e8 !important; */
}

.datePic {
  border: none !important;
  outline: none;
  /* padding: 10px 30px; */
}

.issueDate {
  display: flex;
  margin-left: 12px;
  padding: 8px 5px;
  border: solid 1px #dedede;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.txtAreaTTB {
  width: 95% !important;
  border: solid 1px #dedede;
  height: 77px;
  padding: 9px;
}

@media only screen and (max-width: 1399px) {
  .headingCurrTTB {
    margin-left: 1.7% !important;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #686868;
  }

  .ttb-content {
    margin-left: 1.8%;
    margin-top: 0.3%;
  }
}

@media only screen and (max-width: 1099px) {
  .risk-error {
    width: 92% !important;
    margin-top: -5px;
  }
}

@media only screen and (min-width: 1100px) {
  .risk-error {
    width: 85%;
    margin-top: -5px;
  }
}

@media only screen and (max-width: 1400px) {
  .risk-error {
    width: 87%;
    margin-top: -5px;
  }
}

@media only screen and (min-width: 1400px) {
  .headingCurrTTB {
    margin-left: 0.7% !important;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #686868;
  }

  .ttb-content {
    margin-left: 0.8%;
    margin-top: 0.3%;
  }
}

.headingCurrTTBAppendix {
  margin-left: 3.5% !important;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #686868;
}

.uploadDiv {
  margin-left: 4.8%;
  border: 1px solid #dedede;
  /* padding: 5px 0 0px 5px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  margin-top: 5px;
}

.uploadLeftBorder {
  height: 40px;
  margin-right: -12px;
}

.sizeFontEnable {
  color: #111 !important;
}

.sizeFontDisable {
  color: #bbb !important;
}

.imgSize {
  font-size: 12px;
  display: grid;
  width: 50%;
  text-align: center;
  color: #111 !important;
  justify-content: center;
}

.taskRetInfoIcon {
  /* margin-left: -50%; */
  color: #218dcc !important;
  /* margin-top: 5%; */
  cursor: pointer;
}

.hide {
  display: none;
}

.approveInfoIcon {
  color: #218dcc !important;
  margin-top: 7% !important;
  cursor: pointer;
  margin-left: 10px;
}

.infoapprove {
  width: 390px !important;
  /* height: 100px; */
  /* margin: 25px 88px 112px 0; */
  padding: 15px 15px 15px 19px;
  border-radius: 5px;
  box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
  /* border: solid 1px #4fc3f7; */
  background-color: #ecfafe;
  /* margin-left: 2%; */
  position: absolute;
  top: 0;
  /* margin-top: -9%; */
}

@media only screen and (min-width: 1200px) {
  .loDesc {
    margin-right: -7px;
  }

  .TskRetention {
    margin-left: 1%;
    margin-top: -2%;
  }

  #tskRetInfo:hover+.hide {
    display: inline-table;
  }

  #tskRetInfo1:hover+.hide1 {
    display: inline-table;
  }

  .taskRetInfoIcon1 {
    color: #218dcc !important;
    cursor: pointer;
    position: relative;
    right: 40px;
  }

  .dateErr {
    margin-left: -14.3%;
    margin-top: -6.5%;
  }

  .approveInfoIcon {
    color: #218dcc !important;
    margin-top: 12% !important;
    cursor: pointer;
    margin-left: 10px;
  }

  .infoapprove {
    width: 390px !important;
    /* height: 100px; */
    /* margin: 25px 88px 112px 0; */
    padding: 15px 15px 15px 19px;
    border-radius: 5px;
    box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
    /* border: solid 1px #4fc3f7; */
    background-color: #ecfafe;
    /* margin-left: 2%; */
    position: absolute;
    top: 0;
    /* margin-top: -9%; */
  }
}

@media only screen and (max-width: 1199px) {
  .stDesc {
    margin-right: -4px !important;
  }

  .loDesc {
    margin-right: -4px;
  }

  .TskRetention {
    margin-left: 10px;
    margin-top: -4%;
  }

  #tskRetInfo:hover+.hide {
    display: block;
  }

  #tskRetInfo1:hover+.hide1 {
    display: block;
  }

  .taskRetInfoIcon1 {
    color: #218dcc !important;
    cursor: pointer;
    position: relative;
    float: right;
    right: -40px;
  }

  .dateErr {
    margin-left: 29.7%;
    margin-top: -70.5%;
  }
}

.infoBox {
  width: 50%;
  border-radius: 5px;
}

.infoHeading {
  font-size: 16px;
  font-weight: 900;
}

.infotext {
  font-size: 14px;
  font-weight: 400;
  color: rgba(17, 17, 17, 0.8);
}

.hide1 {
  display: none;
}

.wdddlretrain {
  width: 28% !important;
}

.Step_Description {
  width: 102.8%;
  height: 124px;
  border: solid 1px #dedede;
  padding: 9px;
}

textarea {
  resize: none !important;
}

.lblDisabled {
  pointer-events: none;
  opacity: 0.4;
  padding-top: 2%;
  /* background-color: #e8e8e8 !important; */
}

.stepHeadinglabel {
  /* font-weight: 600; */
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.3;
  margin-left: 0 !important;
  letter-spacing: normal;
  /* color: #111; */
  color: #363636 !important;
}

@media only screen and (max-width: 1199px) {
  .ddlValueSpan {
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    color: #111 !important;
    float: left !important;
    width: 75%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    /* margin-left: 1% !important; */
  }

  .Step_Description {
    width: 103%;
    height: 124px;
    border: 1px solid #dedede;
    padding: 9px;
  }

  .infoDate {
    width: 221px;
    height: 107px;
    padding: 25px 26px 19px 28px;
    border-radius: 5px;
    box-shadow: 0 9px 14px 0 rgb(35 35 35 / 14%);
    background-color: #ecfafe;
    margin-left: 19%;
    top: 10%;
  }

  .infoDate1 {
    width: 300px;
    height: 138px;
    /* position: absolute; */
    /* margin: 25px 88px 112px 0; */
    padding: 25px 26px 19px 28px;
    border-radius: 5px;
    box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
    /* border: solid 1px #4fc3f7; */
    background-color: #ecfafe;
    /* margin-left: 105%; */
    top: 65%;
    left: 19%;
    z-index: 99 !important;
  }

  .approveInfoIcon {
    color: #218dcc !important;
    margin-top: 16% !important;
    cursor: pointer;
    margin-left: 10px;
  }

  .infoapprove {
    width: 275px !important;
    /* height: 100px; */
    /* margin: 25px 88px 112px 0; */
    padding: 15px 15px 15px 19px;
    border-radius: 5px;
    box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
    /* border: solid 1px #4fc3f7; */
    background-color: #ecfafe;
    margin-left: 5%;
    position: absolute;
    top: 0;
    /* margin-top: -9%; */
  }
}

@media only screen and (min-width: 1200px) {
  .ddlValueSpan {
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    color: #111 !important;
    float: left !important;
    width: 85%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    /* margin-left: 1% !important; */
  }

  .infoDate {
    width: 221px;
    height: 107px;
    /* margin: 25px 88px 112px 0; */
    padding: 25px 26px 19px 28px;
    border-radius: 5px;
    box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
    /* border: solid 1px #4fc3f7; */
    background-color: #ecfafe;
    margin-left: 105%;
    /* margin-top: -9%; */
  }

  .infoDate1 {
    width: 300px;
    height: 107px;
    position: absolute;
    /* margin: 25px 88px 112px 0; */
    padding: 25px 26px 19px 28px;
    border-radius: 5px;
    box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
    /* border: solid 1px #4fc3f7; */
    background-color: #ecfafe;
    /* margin-left: 105%; */
    /* margin-top: -9%; */
  }
}

.box2 {
  inline-size: 150px;
  overflow-wrap: break-word;
}

.uploadDivOutcome {
  margin-left: 13px !important;
  height: 41px;
  border: 1px solid #dedede;
  padding: 0 0 0 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 25px;
}

.uploadButton {
  height: 40px;
  width: 40px;
  float: right;
  margin-right: 1px;
  border: none;
  background: none;
  border-left: solid 1px #dedede;
}

.pointerCursor {
  cursor: pointer;
}

.btnSize {
  background-color: #fff !important;
  border-color: green;
}

.imgUploadDisabled {
  pointer-events: none;
  background-color: #e8e8e8 !important;
  border-color: #bbb;
  color: #bbb;
}

.imgUploadEnabled {
  pointer-events: visible !important;
  cursor: pointer !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white !important;
  color: #111;
}

/* mutliple select dropdown styling */

.css-1rhbuit-multiValue {
  border-radius: 128px !important;
}

.css-tj5bde-Svg {
  color: #1111117d;
}

.css-xb97g8:hover {
  background-color: none !important;
}

.css-1pahdxg-control {
  border-color: #dedede !important;
  /* box-shadow: 0 0 0 1px #dedede !important; */
  box-shadow: none !important;
}

.chkAct {
  /* // width: 18px; */
  height: 18px;
  border: solid 1px #b6b6b6;
}

.css-1s2u09g-control {
  border-radius: 0px !important;
  width: 100%;
  min-height: 46px !important;
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 0 !important;
  overflow: auto !important;
  border-style: solid;
  min-height: 46px !important;
}

.disabledItems {
  pointer-events: none;
  /* background-color: #e8e8e8 !important; */
  opacity: 0.4;
}

.nopointer {
  cursor: none !important;
  pointer-events: none;
}

.yesPointer {
  cursor: pointer;
}

.dispayShow {
  display: initial !important;
}

.upDownDisable {
  pointer-events: none;
  color: #e8e8e8 !important;
  opacity: 0.4;
}

.activeSelectedTab {
  /* color: black !important; */
  color: #111 !important;
  /* font-weight: bold !important; */
  font-family: "Roboto-Medium";
}

.dat2Disable {
  pointer-events: none;
  color: #111111 !important;
  background-color: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
}

.dat2DisableStepsActivityIcons {
  pointer-events: none;
  color: #e8e8e8 !important;
  opacity: 0.4;
}

.dispayEvergreen {
  display: flex !important;
}

.dispaySurveyPoll {
  display: flex !important;
}

.dispayVesionClass {
  display: initial !important;
}

.hideInitial {
  display: none !important;
}

.surveyEver {
  display: inline;
  position: absolute;
  margin-top: 9px;
  margin-left: 0px;
  padding-left: 10px;
}

.regionalMargin {
  margin-left: -28%;
  margin-top: 1%;
}

.grid-container1 {
  display: grid;
  grid-template-columns: 45% 40%;
  padding: 12px;
}

.grid-container1>div {
  text-align: left;
  padding: 1% 1.3%;
  font-size: 16px;
  color: #686868;
}

.stepmodalZIndex {
  z-index: 1060 !important;
}

.errMsgoops {
  padding-top: 3px;
  font-size: 12px;
  color: #ff7591 !important;
  text-decoration: none !important;
}

.widtstepscol {
  width: 10% !important;
}

.widtstepscolCritical {
  width: 10% !important;
  left: 10% !important;
}

.widtstepscolStDesc {
  width: 35% !important;
  left: 20% !important;
}

.widtstepscoled {
  width: 18% !important;
  left: 85% !important;
}

.moduleNametopMargin {
  margin-top: -1.6%;
}

.page1sizeError {
  margin-top: -1%;
  margin-left: 67%;
}

.page2sizeError {
  margin-top: -1%;
  margin-left: 67%;
}

.page3sizeError {
  margin-left: 67%;
  margin-top: -1%;
}

.maxSizeError {
  margin-top: -1%;
  margin-left: 3.5%;
}

.imgSizeLearningOutcome {
  margin-top: 4.5%;
  margin-left: -11%;
}

.imgSizeLearningOutcome1 {
  margin-top: -2%;
  margin-left: -66%;
}

@media screen and (max-width: 845px) and (min-width: 800px) {
  /* #addLOActivity .css-b62m3t-container .css-26l3qy-menu .css-11unzgr {
    width: 28.6% !important;
  }

  #addLOActivity .css-b62m3t-container .css-26l3qy-menu .css-4ljt47-MenuList {
    width: 28.6% !important;
  } */

  .loDesc {
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 1400px) {
  /* #addLOActivity .css-b62m3t-container .css-26l3qy-menu .css-11unzgr {
    width: 33.7% !important;
  }

  #addLOActivity .css-b62m3t-container .css-26l3qy-menu .css-4ljt47-MenuList {
    width: 33.7% !important;
  } */

  .imgSizeLearningOutcome {
    margin-top: 3.2%;
    margin-left: -11%;
  }

  .dateFooter {
    margin-left: -15%;
    margin-top: -5.5%;
  }

  .dateFooter1 {
    margin-left: -13.8%;
  }

  .moduleNumbertopMargin {
    margin-top: -3%;
  }

  .moduleNumbertopMargin {
    margin-top: -3%;
  }

  .imgbBoxDAT {
    max-width: 106.5% !important;
    width: 109% !important;
  }
}

.stDesc {
  margin-right: -29px;
  margin-top: 11px;
}

@media screen and (max-width: 1280px) and (min-width: 1110px) {
  .dateFooter {
    margin-left: -3.8% !important;
    margin-top: -13.5% !important;
  }

  .dateFooter1 {
    margin-left: -3.8% !important;
  }
}

@media screen and (max-width: 1399px) and (min-width: 1280px) {
  .dateFooter {
    margin-left: -5.8%;
    margin-top: -13.5%;
  }

  .dateFooter1 {
    margin-left: -5.8%;
  }
}

@media screen and (min-width: 1400px) {
  label#Step_DescriptionErrlbl {
    margin-top: 8px;
  }
}

.loDesc {
  margin-right: -22px;
}

@media screen and (max-width: 1399px) and (min-width: 1100px) {
  label#Step_DescriptionErrlbl {
    margin-top: 3px;
  }

  .stDesc {
    margin-right: -14px;
    margin-top: 4px;
  }

  .loDesc {
    margin-right: -12px;
  }

  .maxSizeError {
    margin-top: -1%;
    margin-left: 3%;
  }

  .page1sizeError {
    margin-top: -2.2%;
    margin-left: 67%;
  }

  .page2sizeError {
    margin-top: -2.2%;
    margin-left: 67%;
  }

  .page3sizeError {
    margin-left: 67%;
    margin-top: -2.2%;
  }


  .moduleNumbertopMargin {
    margin-top: -3%;
  }

  .moduleNametopMargin {
    margin-top: -2%;
  }
}

@media screen and (max-width: 1099px) and (min-width: 868px) {
  .notificationsCurriculum {
    position: absolute;
    left: 39%;
    margin-top: -4.4%;
  }
}

@media screen and (max-width: 868px) {
  .imgSizeLearningOutcome {
    margin-top: 8.5%;
    margin-left: -11%;
  }

  .leftMarginLOSize {
    margin-left: 1%;
  }

  .notificationsCurriculum {
    position: absolute;
    left: 5%;
    margin-top: -5%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .widtstepscolupdn {
    width: 14% !important;
    left: 55% !important;
  }

  .widtstepscolupdncell {
    width: 14% !important;
    left: 54% !important;
  }

  .widtstepscoladds {
    width: 16% !important;
    left: 69% !important;
  }

  .widtstepscoladdscell {
    width: 16% !important;
    left: 69% !important;
  }
}

@media screen and (min-width: 1200px) {
  .widtstepscolupdn {
    width: 14% !important;
    left: 55% !important;
  }

  .widtstepscolupdncell {
    width: 14% !important;
    left: 55% !important;
  }

  .widtstepscoladds {
    width: 16% !important;
    left: 69% !important;
  }

  .widtstepscoladdscell {
    width: 16% !important;
    left: 70.5% !important;
  }
}

@media screen and (max-width: 999px) {
  .widtstepscolupdn {
    width: 14% !important;
    left: 55% !important;
  }

  .widtstepscolupdncell {
    width: 15% !important;
    left: 51% !important;
  }

  .widtstepscoladds {
    width: 16% !important;
    left: 69% !important;
  }

  .widtstepscoladdscell {
    width: 16% !important;
    left: 66.5% !important;
  }
}

@media screen and (max-width: 1099px) {
  .dateFooter {
    margin-left: 18%;
    margin-top: -47.5%;
    width: -moz-fit-content;
    width: fit-content;
  }

  .dateFooter1 {
    margin-left: 23%;
  }

  .moduleNumbertopMargin {
    margin-top: -15%;
  }

  .moduleNametopMargin {
    margin-top: -3%;
  }

  .currLO-imguploaderrormsg-respnsv {
    max-width: 200px;
  }
}

.stepCriticalFont {
  color: red !important;
  /* font-weight: bold !important; */
}

.gridcenterempassign {
  padding-left: 12%;
}

/* Custom styling for the Flatpickr calendar popup */
.curr-flatpickr {
  position: fixed;
  z-index: 9999;
  /* transform: translate(-50%, -50%); */
  /* left: 67% !important;
            top: 35% !important; */
  left: 80% !important;
  top: 25% !important;
}

/* Show the calendar when the input is focused */
.flatpickr-calendar.open {
  display: inline;
}

.flatpickr-calendar.close {
  display: none;
}

.LoBtn {
  height: 45px;
  font-size: 16px;
}

@media only screen and (max-width: 1600px) {
  .curr-flatpickr {
    position: fixed;
    z-index: 9999;
    /* transform: translate(-50%, -50%); */
    /* left: 67% !important;
              top: 35% !important; */
    left: 70% !important;
    top: 24% !important;
  }
}

@media only screen and (max-width: 1399px) {

  /* Custom styling for the Flatpickr calendar popup */
  .curr-flatpickr {
    position: fixed;
    z-index: 9999;
    /* transform: translate(-50%, -50%); */
    /* left: 67% !important;
                top: 35% !important; */
    left: 70% !important;
    top: 24% !important;
  }
}

@media only screen and (max-width: 1199px) {
  .adjustCalender {
    left: 60% !important;
    top: 20% !important;
  }

  .LoBtn {
    height: 45px;
    font-size: 12px;
  }

  /* Custom styling for the Flatpickr calendar popup */
  .curr-flatpickr {
    position: fixed;
    z-index: 9999;
    /* transform: translate(-50%, -50%); */
    /* left: 67% !important;
              top: 35% !important; */
    left: 55% !important;
    top: 38% !important;
  }
}

@media only screen and (max-width: 800px) {

  /* Custom styling for the Flatpickr calendar popup */
  .curr-flatpickr {
    position: fixed;
    z-index: 9999;
    /* transform: translate(-50%, -50%); */
    /* left: 67% !important;
                  top: 35% !important; */
    left: 35% !important;
    top: 38% !important;
  }
}

.currLO-flexcontainer {
  display: flex;
  gap: 5px;
}

.currLO-flexitem-img img {
  width: 90%;
}

.currLO-flexitem-img p {
  font-size: 12px;
  color: #111 !important;
  text-align: center;
  opacity: 1;
}

.imgdimension-text {
  margin-top: -15px !important;
}

.currLO-bottomrow-drpdowncontainer {
  width: 100%;
}

.currLO-bottomrow-container {
  width: 90%;
}

.currLOimgupload-flexcontainer {
  display: flex;
  justify-content: space-between;
}

.imgupload__uploadinputitem {
  width: 100%;
  margin-left: 0px !important;
}

.imgupload__removeitem {
  width: 10%;
  text-align: center;
}

.imgupload__sizeitem {
  width: 29%;
}

.currLOerrormsg-respnsv {
  width: max-content;
}

.currLO-imguploaderrormsg-respnsv {
  width: max-content;
  margin-top: -26px;
  margin-bottom: 15px;
}

@media screen and (max-width: 992px) {
  .adjustCalender {
    left: auto !important;
    top: 6% !important;
  }

  .currLOsampleimg-respnsvecont {
    padding-inline: 27px;
    margin-top: -27px;
    margin-bottom: 18px;
  }

  .currLO-flexcontainer {
    width: 90%;
  }

  .currLOerrormsg-respnsv {
    max-width: 200px;
  }
}

.curraddsteps__criticalcontainer {
  position: relative;
  width: 75%;
  min-width: 220px;
}

.dispayNone {
  display: none;
}

.currdoc-ddlflexcontainer {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
}

.currdoc-kebabdropdown {
  /* color: black !important; */
  background-color: white !important;
  border: none;
  padding: 0;
}

.approved-flexcontainer {
  display: flex;
}

.module-retention-flexcontainer,
.evergreen-flexcontainer,
.survey-poll-flexcontainer {
  gap: 16px;
  line-height: 2.1;
}

@media only screen and (min-width: 1400px) {
  .add-threat-level {
    margin-left: 8px;
  }

  .add-threat-err-msg {
    margin-left: 8px;
  }

  .mcq-col-curriculumn {
    width: 15% !important;
  }
}

.mcq-col-curriculumn {
  width: 15%;
  font-size: 12px;
  margin-left: 17%;
}