#wrapper {
    width: 100%;
    max-height: 714px;
    min-height: 500px;
    overflow: auto;
    padding-right: 150px;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: separate;
    /* Don't collapse */
    border-spacing: 0;
}

.custom-row-height {
    height: 65px;
}

td {
    padding: 22px;
}

.custom-heading-height {
    height: 30px;
}

.border-question {
    border-bottom: 0.5px solid #dedede;
    border-top: 0px;
    border-left: 0px;
    border-right: 0.5px solid #dedede;
    display: table-cell;
    vertical-align: middle;
}

.first-cell {
    border-left: 0.5px solid #dedede;
    /* Apply left border to the first cell in each row */
    border-top: 0.5px solid #dedede;
    /* Apply top border to the first cell in each column */
}

.border-question-row {
    border-bottom: 0.5px solid #dedede;
    border-top: 0px;
    border-left: 0.5px solid #dedede;
    /* Apply left border to the first cell in each row */
    border-right: 0.5px solid #dedede;
}

.border-bottom {
    border-bottom: 0.5px solid #dedede;
}

/* table th {
    border-bottom: 0.5px solid #cecece;
    border-right: 0.5px solid #cecece;
}

table td {
    border-bottom: 0.5px solid #cecece;
    border-right: 0.5px solid #cecece;
}

table th:first-child,
table td:first-child {
    border-left: 0.5px solid #cecece;
} */

.other-cells {

    position: sticky;
    top: 0;
    background-color: #fff;

}

.first-cell {
    position: sticky;
    top: 0;
    background-color: #fff;

}

.colspan-border {
    border-top: 0.5px solid #dedede;
    border-bottom: 0.5px solid #dedede;
    border-left: none;
    border-right: 0.5px solid #dedede;
}

.option-label {
    color: #111 !important;
    cursor: pointer;
}

.col-heading {
    font-family: "Roboto";
    border-top: 0.5px solid #dedede;
    border-bottom: 0.5px solid #dedede;
    border-right: 0.5px solid #dedede;
    border-left: 0.5px solid #dedede;
    font-size: 16px;
    cursor: pointer;

}

.critical-step {
    color: red
}

.cell-grey {
    background-color: #faf6f6;
}

.col-subheading {
    font-family: "Roboto";
    font-size: 16px;
    display: table-cell;
    vertical-align: middle;
}

.competency-score {
    font-family: "Roboto-Medium";
    font-size: 24px;
    min-height: 30px;
}

.not-assessed {
    font-family: "Roboto-Light";
    font-size: 16px;
    color: #c4c4c4 !important;
    min-height: 30px;
}

.matrix-table {
    width: auto;
    white-space: nowrap;
    /* Prevent text wrapping within cells */
}

.cell-content {
    white-space: wrap;
    min-width: 375px;
}

.header-content {
    white-space: wrap;
    min-width: 450px;
    padding-top: 5px;
    padding-bottom: 0px;

}

.matrix-table th,
.matrix-table td {
    width: 200px;
    /* Set a fixed width for columns or adjust as needed */
    box-sizing: border-box;

    /* Ensure width includes padding and border */
}