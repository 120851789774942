.customtooltip {
  position: relative;
  display: inline-block;
}

.removebtn {
  cursor: pointer;
}

.customtooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: transparent;
  text-align: center;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 170%;
  left: 30%;
  margin-left: -53px;
  font-family: 'Roboto', sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.55px;
  color: #111;
}

.tooltiptextwidth {
  width: 80px !important;
  text-align: right !important;
}

.customtooltip:hover .tooltiptext {
  visibility: visible;
}


.customtooltip1 {
  position: relative;
  display: inline-block;
}


.customtooltip1 .tooltiptext1 {
  visibility: hidden;
  width: 120px;
  background-color: transparent;
  text-align: center;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 190%;
  left: 60%;
  margin-left: -53px;
  font-family: 'Roboto', sans-serif !important;
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.55px;
  color: #111;
}

.customtooltip1:hover .tooltiptext1 {
  visibility: visible;
}