body {
  margin: 0;
  /* background-color: #FAFAFA; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0px !important;
  max-width: 100%;

}

code {
  font-family: "Roboto-Medium", sans-serif !important;
}

*:focus {
  outline: none;
}

.h2 {
  font-size: 24px !important;
  font-family: "Roboto-Medium", sans-serif !important;
}

.h3 {
  font-size: 18px !important;
  font-family: "Roboto-Medium", sans-serif !important;
}

.h4 {
  font-size: 16px !important;
  font-family: "Roboto-Regular", sans-serif !important;
}

.h4-1 {
  font-size: 16px !important;
  font-family: "Roboto-Medium", sans-serif !important;
}

.h4-2 {
  font-size: 16px !important;
  font-family: "Roboto", sans-serif !important;
}

.h5 {
  font-size: 14px !important;
  font-family: "Roboto-Regular", sans-serif !important;
}

.h5-1 {
  font-size: 14px !important;
  font-family: "Roboto-Medium", sans-serif !important;
}

.h5-2 {
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
}

.h6 {
  font-size: 12px !important;
  font-family: "Roboto-Regular", sans-serif !important;
}

.h6-1 {
  font-size: 12px !important;
  font-family: "Roboto", sans-serif !important;
}

.mt-n5 {
  margin-top: -1.25rem;
}

.mt-n4 {
  margin-top: -1rem;
}

.mt-n3 {
  margin-top: -0.85rem;
}

/* .mt-55{
  margin-top: -0.7rem!important;
} */

.mt-c-n6 {
  margin-top: 1rem !important;
}

.h7 {
  font-size: 11px !important;
  font-family: "Roboto-Regular", sans-serif !important;
}

.h8 {
  font-size: 16px;
  font-weight: 500;
  color: #686868;
}

.h9 {
  font-size: 18px;
  font-weight: 500;
  color: #686868;
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
  .userH {
    font-size: 16px;
    font-weight: 600;
    color: #686868;
    white-space: nowrap;
    top: -6px;
    position: relative;
  }
}

@media only screen and (max-width: 1000px) {
  .userH {
    font-size: 16px;
    font-weight: 600;
    color: #686868;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 1100px) {
  .userH {
    font-size: 20px;
    font-weight: 600;
    color: #686868;
    white-space: nowrap;
  }
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("Content/Fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("Content/Fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Light";
  src: url("Content/Fonts/Roboto/Roboto-Light.ttf") format("truetype");
}

@media (max-width: 992px) {
  .mt-mt-n5 {
    margin-top: -1.25rem;
  }

  .mt-md-n4 {
    margin-top: -1rem;
  }

  .mtd-md-n3 {
    margin-top: -0.85rem;
  }

  .mt-md-55 {
    margin-top: 1.85rem !important;
  }
}

@media (min-width: 1000px) {
  .mt-c-n6 {
    margin-top: -3.3rem !important;
  }
}



/* 
svg {
  color: #111;
} */

.dropdown-toggle.btn:not(.dropdown-toggle-split)>svg:last-child {
  left: 5px !important;
  top: -1px;
}

/* width */
::-webkit-scrollbar {
  width: 18px;
  background: white;
  scroll-margin: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #686868c4;
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

/* .css-tlfecz-indicatorContainer {
  color: hsl(0, 0%, 80%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px !important;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
} */