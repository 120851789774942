.wrapper {
    position: relative;
}

.wrapper span {
    position: absolute;
    left: 120px;
    top: 12px;
    width: 50%;
    z-index: 90;
    background-color: white;
}

.wrapperTTB {
    position: relative;
}

.wrapperTTB span {
    position: absolute;
    left: 120px;
    top: 8px;
    width: 50%;
    z-index: 90;
    background-color: white;
}

.linkToActivity {
    z-index: 99;
}