.toast-success {
  position: absolute;
  padding: 8px;
  background-color: #dbfaea;
  border: 1px solid #2ee68c;
  border-radius: 3px;
  top: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.toast-text {
  font-size: 13px !important;
  color: #111;
  font-family: "Roboto";
}

.popup-text {
  font-size: 16px !important;
  color: #111;
  font-family: "Roboto";
}

.toast-border {
  border-bottom: 1px solid #dedede;
}

.toast-footer {
  align-self: center;
  border-top: none;
}

.toast-success img {
  margin-left: 15px;
  margin-right: 15px;
}

.notransform {
  transform: none !important;
}

.toast-success-new {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1111;
  padding: 8px 26px 8px 8px;
  background-color: #dbfaea;
  border: 1px solid #2ee68c;
  border-radius: 3px;
  font-size: 14px;
}

.toast-error-new {
  position: absolute;
  left: 50%;
  top: 8%;
  transform: translate(-50%, -50%);
  z-index: 1111;
  padding: 8px 26px 8px 8px;
  background-color: #ffe4e9;
  border: 1px solid #ff7591;
  border-radius: 3px;
  font-size: 16px;
}

.toast-img {
  margin-left: 15px;
  margin-right: 15px;
}

@media screen and (max-width: 999px) {
  .toast-success-div {
    max-width: 215% !important;
    display: flex;
    z-index: 1100;
    font-size: 12px;
  }

  .toast-800 {
    margin-left: -66%;
  }

  .assessments-toast {
    top: 31px !important;
  }

  .newsfeed-toast {
    top: 20% !important;
  }

  .toast-success-new,
  .toast-error-new {
    font-size: 12px;
    text-align: center;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .newsfeed-toast {
    top: 25% !important;
  }
}

@media screen and (min-width: 1400px) {
  .newsfeed-toast {
    top: 18% !important;
  }
}

@media screen and (min-width: 1024px) {
  .toast-success-div {
    max-width: 315% !important;
    display: inline-flex;
    z-index: 1100;
    padding-right: 26px;
    margin-left: 10%;
  }

  .toast-800 {
    margin-left: -50px;
  }

  .assessments-toast {
    top: 31px !important;
    left: -55px;
  }
}

.toast-error {
  width: max-content;
  position: absolute;
  padding: 8px 7% 8px 4%;
  background-color: #ffe4e9;
  border: 1px solid #ff7591;
  border-radius: 3px;
  top: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.toast-error img {
  margin-left: 15px;
  margin-right: 15px;
}

.toast-confirm-delete {
  position: absolute;
  padding: 8px;
  background-color: #fdfcdd;
  border: 1px solid #ffee5c;
  border-radius: 3px;
  top: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.toast-confirm-delete img {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -5px;
}

.toast-confirm-discard {
  position: absolute;
  padding: 8px;
  background-color: #d5f4fe;
  /* border: 1px solid #4fc3f7; */
  border-radius: 3px;
  top: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.toast-confirm-discard img {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -5px;
}

.toast-popup-dim {
  width: 30%;
}

.toast-popup-dim2 {
  /* margin-top: 13%; */
  padding-left: 25%;
  padding-right: 25%;
  text-align: center;
}

.toast-popup-dim1 {
  /* margin-top: 13%; */
  padding-left: 20%;
  padding-right: 20%;
  text-align: center;
}

.toast-popup-nobutton-dim {
  background-color: white !important;
  color: black !important;
  width: 68px;
}

.toast-popup-yesbutton-dim {
  background-color: #007f00 !important;
  color: white !important;
}

.toast-success-text {
  white-space: nowrap;
  font-family: "Roboto", sans-serif !important;
}

@media (min-width: 450px) and (max-width: 800px) {
  .toast-error span {
    margin-right: 60px;
  }

  .toast-success span {
    margin-right: 60px;
  }
}

@media (min-width: 400px) {
  .toast-confirm-delete span {
    margin-right: 30px;
  }

  .toast-confirm-discard span {
    margin-right: 20px;
  }
}

@media (min-width: 400px) and (max-width: 450px) {
  .toast-confirm-delete span {
    margin-right: 30px;
  }

  .toast-confirm-discard span {
    margin-right: 20px;
  }
}

.trainee-submitpopup {
  width: 580px !important;
}

.disclaimer-toast {
  width: 500px;
  font-size: 13px;
  font-family: "Roboto-Medium";
  margin-top: 5px;
}

@media (max-width: 730px) {

  .disclaimer-toast {
    width: 400px;
  }
}

.toast-width {
  width: 340px;
}


@media (max-width: 630px) {

  .disclaimer-toast {
    width: 350px;
    font-size: 12px;
    margin-top: 0px;
  }
}

@media (max-width: 575px) {

  .disclaimer-toast {
    width: 270px;
    font-size: 11px;
    margin-top: 0px;
  }
}

@media (max-width: 515px) {
  .toast-img {
    margin-left: 3px;
    margin-right: 10px;
  }
}

@media (max-width: 520px) {
  .toast-success-new {
    font-size: 11px;
  }
}