.empdesc-lefthead {
  /* margin-left: -0.5% !important; */
  width: 21% !important;
  font-size: 12px !important;
}

.trngchkbx-lefthead {
  width: 5% !important;
  font-size: 12px !important;
}

.assign-flatpickr {
  position: fixed !important;
  top: 35% !important;
  left: 60% !important;
}

.assignemployee-flatpickr {
  position: fixed !important;
  top: 35% !important;
  left: 75% !important;
}

@media screen and (max-width: 1200px) {
  .assignemployee-flatpickr {

    left: 60% !important;
  }
}



@media screen and (max-width: 768px) {
  .assignemployee-flatpickr {

    left: 30% !important;
  }

  .assign-flatpickr {

    left: 30% !important;
  }
}

.trngmod-lefthead {
  margin-left: -19.5% !important;
  width: 22% !important;
  font-size: 12px !important;
}

@media screen and (min-width: 1399px) {
  /* .LabelAdj{
  margin-left: -1%;
} */
}

/* @media screen and (min-width: 1400px) {
  .LabelAdj{
    margin-left: 1.3%;
  }
} */
.trngmod-leftcell {
  margin-left: -19.35 !important;
  width: 20% !important;
  font-size: 12px;
}

.trngmoddesc-lefthead {
  margin-left: -21% !important;
  width: 38% !important;
  font-size: 12px !important;
}

.trngmoddesc-leftcell {
  margin-left: -20.9% !important;
  width: 37% !important;
  font-size: 12px !important;
}

.trngdatedesc-lefthead {
  margin-left: -7.5% !important;
  width: 35% !important;
  font-size: 12px !important;
}

.trngdatedesc-leftcell {
  margin-left: -7.5 !important;
  /* width: 58% !important; */
  font-size: 12px !important;
}

.empdesc-leftcell {
  margin-left: -0.7% !important;
  width: 32% !important;
  font-size: 12px;
}

.jobtitle-lefthead {
  margin-left: 2% !important;
  width: 20% !important;
  font-size: 12px !important;
}

.jobtitle-leftcell {
  margin-left: 2% !important;
  width: 20% !important;
  font-size: 12px !important;
}

.locationCode-lefthead {
  margin-left: 4% !important;
  width: 15% !important;
  font-size: 12px !important;
}

.locationCode-leftcell {
  margin-left: 4% !important;
  width: 15% !important;
  font-size: 12px !important;
}

.location-lefthead {
  margin-left: 5% !important;
  width: 19% !important;
  font-size: 12px !important;
}

.location-leftcell {
  margin-left: 5% !important;
  width: 19% !important;
  font-size: 12px !important;
}

.setup-lefthead {
  margin-left: 7% !important;
  width: 14% !important;
  font-size: 12px !important;
}

.setup-leftcell {
  margin-left: 10% !important;
  width: 14% !important;
  font-size: 12px !important;
}

.empeditdel_head {
  margin-left: 4% !important;
  width: 13% !important;
  font-size: 12px !important;
}

.empeditdel_cell {
  margin-left: 4% !important;
  width: 13% !important;
  font-size: 12px !important;
}

.widthempddlinPercent {
  width: 114% !important;
}

.ddlEmpJob {
  width: 112%;
  height: 46px;
  /* margin-left: -3.6%; */
  border: solid 1px #dedede;
  background: none !important;
  color: #686868 !important;
  border-radius: 0px !important;
}

.dateOperation {
  display: none;
}

.datePicTskToEmp {
  border: none !important;
  padding: 10px;
}

.datePicTskToEmp flatpickr-input:active {
  color: white !important;
}

.agheaderoverride {
  padding: 10px !important;
}

.createEmpFrWrdLmt {
  color: #ff7591 !important;
  text-decoration: none !important;
  margin-top: -2.5%;
  margin-left: -5%;
  font-size: 11px;
}

.assignDateReq {
  color: #ff7591 !important;
  text-decoration: none !important;
  margin-top: -2.5%;
  margin-left: 0%;
  font-size: 11px;
}

.createEmpSrWrdLmt {
  color: #ff7591 !important;
  text-decoration: none !important;
  margin-top: -2.5%;
  margin-left: -5%;
  font-size: 11px;
}

.createEmpJobDDLReq {
  color: #ff7591 !important;
  text-decoration: none !important;
  margin-top: -2.5%;
  margin-left: -2%;
  font-size: 11px;
}

.createEmpLocDDLReq {
  color: #ff7591 !important;
  text-decoration: none !important;
  margin-top: -2.5%;
  margin-left: 25.4%;
  font-size: 11px;
}

.createEmpTrngDDLReq {
  color: #ff7591 !important;
  text-decoration: none !important;
  margin-top: -2.5%;
  margin-left: 1%;
  font-size: 11px;
}

.ag-header-container .ag-header-cell-label {
  padding-left: 0 !important;
  padding-top: 5px;
}

.displayNametxt {
  height: 44px;
  margin-left: 4px;
  width: 99%;
}

.chckboxEmp {
  height: 25px;
  width: 50px;
  margin-left: -25px;
}

.trnddl {
  width: 126%;
  max-width: 130%;
  margin-left: -11%;
}

.trndiv {
  top: -30px;
  margin-left: 0%;
}

.displayNameDiv {
  top: -24px;
  margin-top: -1%;
  width: 100.3%;
  max-width: 102%;
}

.ddlLoc {
  width: 105.3%;
  max-width: 106%;
  margin-left: 7px;
}

.ddlJobTitle {
  width: 104%;
  margin-left: -10px;
}

.EmpSurWrdLmtDiv {
  margin-right: 0.2%;
}

.EmpSurErrWrdLmtDiv {
  margin-right: 2%;
}

.empSurCol {
  max-width: 103.5%;
  width: 114%;
  margin-left: 18.5%;
}

.EmpForErrWrdLmt {
  margin-top: -2.5%;
}

.EmpForWrdLmtDiv {
  margin-right: 2%;
}

.empForErrRow {
  position: relative;
  top: -10px;
  margin-left: 1%;
  width: 103%;
  max-width: 104%;
}

.empTitle {
  margin-bottom: 0px;
  padding-left: 1.6%;
  padding-top: 2.5%;
  margin-left: -1.7%;
  color: #363636 !important;
}

.empCreateTitle {
  margin-bottom: 0px;
  padding-left: 1.6%;
  padding-top: 2.5%;
  margin-left: -1.7%;
  color: #363636 !important;
}

.datePicEmp {
  border: none !important;

  /* padding: 10px 30px; */
}

.datepicEmpCursor {
  cursor: context-menu !important;
}

.assignPoPup {
  color: rgb(187, 187, 187);
  padding: 10px 0px 0px 10px;
  height: 46px;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .errLocDiv {
    margin-left: 0%;
  }

  .assign-popup-posempTask {
    width: 50% !important;
    /* padding-top: 9%; */
    /* margin-left: 25% !important; */
  }

  .empSurCol {
    max-width: 103.5%;
    width: 114%;
    margin-left: 18.5%;
  }

  .assign-popup-pos {
    width: 60% !important;
  }
}

@media only screen and (max-width: 1199px) {
  .errLocDiv {
    margin-left: 0%;
  }

  .assign-popup-posempTask {
    width: 90% !important;
    /* margin-left: 4% !important; */
  }

  .empSurCol {
    max-width: 103.5%;
    width: 114%;
    margin-left: 18.5%;
  }

  .assign-popup-pos {
    width: 100% !important;
    /* padding-top: 10%; */
    margin-left: 1.8% !important;
  }

  .createEmpLocDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: 25.4%;
    font-size: 11px;
  }

  .empSurErrRow {
    position: relative;
    top: -7px;
    margin-left: 3%;
  }
}

.flatpickr-input[readonly] {
  cursor: pointer;
  outline: none;
  border: 1px solid transparent;
  width: 70%;
  color: #686868;
}

.flatpickr-input {
  cursor: pointer;
  outline: none;
  border: 1px solid transparent;
}

.assignFlatpickr {
  width: 90%;
  height: 46px;
  border: 1px solid rgb(222, 222, 222);
  padding: 8px 5px 5px 5px;
}


.headTraindate {
  margin-left: -2.7%;
}

.headTraindateColor {
  color: #363636 !important;
}

.toastalignmentLTD {
  z-index: 1079;
  position: absolute;
  padding: 49%;
}

.assignEmpFlatpick {
  vertical-align: middle !important;
}

.gridHtWdassignTask {
  height: 572px;
  width: 103.3%;
  /* padding-left: 0.6rem !important; */
  margin-left: -1.8%;
}

.toastDivForAssnModToEmp {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
}

.assignModToEmpTitle {
  margin-top: 2%;
}

.assgnModToEmpLanding {
  height: 55.5vh;
  margin-left: -0.9%;
  width: 100%;
}

.assngModToEmpLocation {
  color: #686868;
  margin-left: -3px;
}

.assngModToEmpEmpName {
  margin-left: 2%;
  color: #686868;
}

.empNameTxtBox {
  width: 105.5%;
  max-width: 82%;
  margin-left: 3.9%;
  height: 41px;
  color: #bbb;
  padding: 10px 0 0 8px;
}

.locTxtBox {
  width: 105.5%;
  max-width: 94.5%;
  margin-left: 1.5%;
  height: 41px;
  color: #bbb;
  padding: 10px 0 0 8px;
}

.floatRightCreateEmp {
  margin: 1.5%;
  position: relative;
  right: -3%;
  float: right;
  top: 0%;
}

.floatRightCreateEmpTrain {
  margin: 1.5%;
  position: relative;
  right: -3%;
  float: right;
  top: 0%;
}

.errMsgDiv {
  margin-left: 0px;
  margin-top: -5px;
}



.sameEmpErrorDiv {
  margin-top: 1%;
}

@media screen and (max-width: 1099px) {
  .errLocDiv {
    margin-left: 23%;
  }

  .empSurCol {
    max-width: 102.5%;
    width: 114%;
    margin-left: 20.5%;
  }

  .gridHtWdassignTask {
    height: 572px;
    width: 108.5%;
    /* padding-left: 0.6rem !important; */
    margin-left: -4.6%;
  }

  .assgnModToEmpLanding {
    height: 550px;
    margin-left: -0.9%;
    width: 100%;
  }

  .assgnEmpToModGridLanding {
    height: 550px;
    margin-left: -0.9%;
    width: 100%;
  }

  .assgnEmpToModGridLandingModuleNo {
    margin-left: 0% !important;
    width: 25% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingModuleName {
    margin-left: -8.5% !important;
    width: 49.5% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingAssn {
    margin-left: 7.5% !important;
    width: 26% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingModuleNoCell {
    margin-left: 0.2% !important;
    width: 25% !important;
    font-size: 14px !important;
  }

  .assgnEmpToModGridLandingModuleNameCell {
    margin-left: -8% !important;
    width: 49.5% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingAssnCell {
    margin-left: 14% !important;
    width: 22% !important;
    font-size: 12px !important;
  }

  .assignModToEmp {
    width: 100% !important;
    /* margin-left: 8% !important; */
  }

  .toastDivForAssnModToEmp {
    margin-left: 5%;
  }

  .assignModToEmpTitle {
    margin-top: 7%;
    margin-left: -3%;
  }

  .trngchkbx-lefthead {
    width: 7% !important;
    font-size: 12px !important;
  }

  .trngmod-lefthead {
    margin-left: -19.5% !important;
    width: 22% !important;
    font-size: 12px !important;
  }

  .trngchkbx-leftcell {
    margin-left: -1% !important;
    width: 11% !important;
    font-size: 12px;
  }

  .trngmod-leftcell {
    margin-left: -19.3% !important;
    width: 20% !important;
    font-size: 12px;
  }

  .trngmoddesc-lefthead {
    margin-left: -21% !important;
    width: 38% !important;
    font-size: 12px !important;
  }

  .trngmoddesc-leftcell {
    margin-left: -20.9% !important;
    width: 37% !important;
    font-size: 12px !important;
  }

  .trngdatedesc-lefthead {
    margin-left: -7.5% !important;
    width: 35% !important;
    font-size: 12px !important;
  }

  .trngdatedesc-leftcell {
    margin-left: -7.5% !important;
    /* width: 58% !important; */
    font-size: 12px !important;
  }

  .assngModToEmpLocation {
    color: #686868;
    margin-left: -10px;
  }

  .assngModToEmpEmpName {
    margin-left: -4.5%;
    color: #686868;
  }

  .empNameTxtBox {
    width: 105.5%;
    max-width: 82%;
    margin-left: 0.9%;
    height: 41px;
    color: #bbb;
    padding: 10px 0 0 8px;
  }

  .locTxtBox {
    width: 105.5%;
    max-width: 94.5%;
    margin-left: 1.5%;
    height: 41px;
    color: #bbb;
    padding: 10px 0 0 8px;
  }

  .floatRightCreateEmp {
    margin: 0.5%;
    position: relative;
    right: -3%;
    float: right;
    top: 8%;
  }

  .floatRightCreateEmpTrain {
    margin: 0.5%;
    position: relative;
    right: -8%;
    float: right;
    /* top: -69%; */
  }

  .errMsgDiv {
    margin-left: 0px;
    margin-top: -5px;
    width: 172%;
    max-width: 187%;
    white-space: nowrap;
  }

  .empForErrRow {
    position: relative;
    top: -10px;
    margin-left: 1%;
    width: 103%;
    max-width: 104%;
  }

  .EmpForWrdLmtDiv {
    margin-right: 2%;
  }

  .EmpForWrdLmt {
    margin-top: -5%;
    margin-right: -2%;
  }

  .ddlJobTitle {
    width: 113%;
    max-width: 110.2%;
    margin-left: -11px;
  }

  .createEmpJobDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: 0.5%;
    font-size: 11px;
  }

  .trndiv {
    top: -30px;
    margin-left: -4%;
  }

  .chckboxEmpCol {
    display: flex;
    margin-top: 0%;
    margin-left: 30%;
    align-items: baseline;
  }

  .empCreateTitle {
    margin-bottom: 0px;
    padding-left: 1.6%;
    padding-top: 7.5%;
    margin-left: -3.7%;
    color: #363636 !important;
  }


  .EmpSurWrdLmt {
    margin-top: -6%;
    margin-right: -1.6%;
  }

  .LabelAdj {
    margin-left: 27.8%;
  }

  .sameEmpErrorDiv {
    margin-top: 1%;
  }

  .createEmpLocDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: -0.5%;
    font-size: 11px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
  .ddlLoc {
    width: 109.3%;
    max-width: 113%;
    margin-left: 23%;
  }

  .foreName {
    min-width: 102.5%;
  }

  .createEmpTrngDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: 0;
    font-size: 11px;
  }
}

@media only screen and (max-width: 999px) {
  .ddlLoc {
    width: 112.3%;
    max-width: 113%;
    margin-left: 23%;
  }

  .createEmpTrngDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: -4%;
    font-size: 11px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1399px) {
  .errLocDiv {
    margin-left: -3.5%;
  }

  .empSurCol {
    max-width: 103.5%;
    width: 114%;
    margin-left: 18.5%;
  }

  .gridHtWdassignTask {
    height: 572px;
    width: 103.9%;
    /* padding-left: 0.6rem !important; */
    margin-left: -2.2%;
  }

  .assgnModToEmpLanding {
    height: 520px;
    margin-left: -0.9%;
    width: 141%;
  }

  .assgnEmpToModGridLanding {
    height: 550px;
    margin-left: -0.9%;
    width: 100%;
  }

  /* .assignModToEmp{
    width: 60% !important;
    padding-top: 0%;
    margin-left: 19% !important;
    margin-top:3% !important;
  } */

  .assgnEmpToModGridLanding {
    height: 530px;
    margin-left: -0.9%;
    width: 100%;
  }

  .assgnEmpToModGridLandingModuleNo {
    margin-left: 0% !important;
    width: 25% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingModuleName {
    margin-left: -8.5% !important;
    width: 50.5% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingAssn {
    margin-left: 8.5% !important;
    width: 26% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingModuleNoCell {
    margin-left: 0% !important;
    width: 25% !important;
    font-size: 14px !important;
  }

  .assgnEmpToModGridLandingModuleNameCell {
    margin-left: -8.2% !important;
    width: 51.5% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingAssnCell {
    margin-left: 14% !important;
    width: 22% !important;
    font-size: 12px !important;
  }

  .toastDivForAssnModToEmp {
    position: absolute;
    top: -0.5%;
  }

  .toastDivForAssnEmpToMod {
    position: relative;
    top: -43px;
    left: -120px;
  }

  .assignModToEmpTitle {
    margin-top: 5%;
    margin-left: -2.5%;
  }

  .trngchkbx-lefthead {
    width: 5% !important;
    font-size: 12px !important;
  }

  .trngmod-lefthead {
    margin-left: -20.5% !important;
    width: 25% !important;
    font-size: 12px !important;
  }

  .trngchkbx-leftcell {
    /* margin-left: -2.6% !important; */
    width: 10% !important;
    font-size: 12px;
  }

  .trngmod-leftcell {
    margin-left: -20.5% !important;
    width: 25% !important;
    font-size: 12px;
  }

  .trngmoddesc-lefthead {
    margin-left: -21% !important;
    width: 38% !important;
    font-size: 12px !important;
  }

  .trngmoddesc-leftcell {
    margin-left: -20.9% !important;
    width: 37% !important;
    font-size: 12px !important;
  }

  .trngdatedesc-lefthead {
    margin-left: -8.5% !important;
    width: 35% !important;
    font-size: 12px !important;
  }

  .trngdatedesc-leftcell {
    margin-left: -8.5% !important;
    width: 34% !important;
    font-size: 12px !important;
  }

  .assngModToEmpLocation {
    color: #686868;
    margin-left: -7px;
  }

  .assngModToEmpEmpName {
    margin-left: -1.6%;
    color: #686868;
  }

  .empNameTxtBox {
    width: 105.5%;
    max-width: 82%;
    margin-left: 1.4%;
    height: 41px;
    color: #bbb;
    padding: 10px 0 0 8px;
  }

  .locTxtBox {
    width: 105.5%;
    max-width: 94.5%;
    margin-left: 1.5%;
    height: 41px;
    color: #bbb;
    padding: 10px 0 0 8px;
  }

  .floatRightCreateEmp {
    margin: 1.5%;
    position: relative;
    right: -3%;
    float: right;
    top: 0%;
  }

  .floatRightCreateEmpTrain {
    margin: 1.5%;
    position: relative;
    right: -5%;
    float: right;
    top: -3%;
  }

  .errMsgDiv {
    margin-left: 0px;
    margin-top: -5px;
  }

  .empForErrRow {
    position: relative;
    top: -10px;
    margin-left: -1%;
    width: 103%;
    max-width: 104%;
  }

  .EmpForErrWrdLmt {
    margin-top: -4%;
    margin-right: -0.5%;
  }

  .EmpForWrdLmtDiv {
    margin-right: 2%;
    width: 101%;
    max-width: 101%;
  }

  .createEmpSrWrdLmt {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -4.5%;
    margin-left: -9%;
    font-size: 11px;
  }

  .createEmpFrWrdLmt {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -3.5%;
    margin-left: -5%;
    font-size: 11px;
  }

  .EmpForWrdLmt {
    margin-top: -3%;
  }

  .EmpSurErrWrdLmt {
    margin-top: -4.2%;
    margin-right: 0%;
  }

  .ddlLoc {
    width: 109.3%;
    max-width: 110%;
    margin-left: 20%;
  }

  .ddlJobTitle {
    width: 105.7%;
    max-width: 107%;
    margin-left: -10px;
  }

  .createEmpJobDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: 0.5%;
    font-size: 11px;
  }

  .trndiv {
    top: -22px;
    margin-left: -1.2%;
  }

  .createEmpTrngDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: 0;
    font-size: 11px;
    white-space: nowrap;
  }

  .chckboxEmpCol {
    display: flex;
    margin-top: 2%;
    margin-left: 24%;
    align-items: baseline;
  }

  .empCreateTitle {
    margin-bottom: 0px;
    padding-left: 1.6%;
    padding-top: 4.5%;
    margin-left: -2.4%;
    color: #363636 !important;
  }


  .EmpSurWrdLmt {
    margin-top: -3.5%;
    margin-right: 0.5%;
  }

  .trainerlbl {
    margin-left: -5px;
  }

  .LabelAdj {
    margin-left: 23%;
  }

  .sameEmpErrorDiv {
    margin-left: 22%;
  }

  .createEmpLocDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: 24.8%;
    font-size: 11px;
  }

  .empSurErrRow {
    position: relative;
    top: -7px;
    margin-left: 3%;
  }
}

@media screen and (min-width: 1400px) {
  .errLocDiv {
    margin-left: -2.5%;
  }

  .empSurCol {
    max-width: 103.5%;
    width: 114%;
    margin-left: 18.5%;
  }

  .createEmpLocDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: 22.8%;
    font-size: 11px;
  }

  .gridHtWdassignTask {
    height: 572px;
    width: 103.3%;
    /* padding-left: 0.6rem !important; */
    margin-left: -1.8%;
  }

  .assgnModToEmpLanding {
    height: 520px;
    margin-left: -0.9%;
    width: 100%;
  }

  .assgnEmpToModGridLanding {
    height: 520px;
    margin-left: -0.9%;
    width: 100%;
  }

  .assgnEmpToModGridLandingModuleNo {
    margin-left: 0% !important;
    width: 25% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingModuleName {
    margin-left: -8.5% !important;
    width: 53.5% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingAssn {
    margin-left: 11.5% !important;
    width: 22% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingModuleNoCell {
    margin-left: 0% !important;
    width: 25% !important;
    font-size: 14px !important;
  }

  .assgnEmpToModGridLandingModuleNameCell {
    margin-left: -8.2% !important;
    width: 53.5% !important;
    font-size: 12px !important;
  }

  .assgnEmpToModGridLandingAssnCell {
    margin-left: 14% !important;
    width: 22% !important;
    font-size: 12px !important;
  }

  .assignModToEmp {
    width: 53% !important;
    /* padding-top: 0%; */
    margin-left: 24% !important;
    /* margin-top:3% !important; */
  }

  /* .toastDivForAssnModToEmp{
    margin-left: 24%;
  } */

  .assignModToEmpTitle {
    margin-top: 2%;
    margin-left: -0.4%;
  }

  .trngchkbx-lefthead {
    width: 5% !important;
    font-size: 12px !important;
  }

  .trngmod-lefthead {
    margin-left: -20% !important;
    width: 22% !important;
    font-size: 12px !important;
  }

  .trngchkbx-leftcell {
    /* margin-left: -0.7% !important; */
    width: 8% !important;
    font-size: 12px;
  }

  .trngmod-leftcell {
    margin-left: -20% !important;
    width: 20% !important;
    font-size: 12px;
  }

  .trngmoddesc-lefthead {
    margin-left: -23% !important;
    width: 38% !important;
    font-size: 12px !important;
  }

  .trngmoddesc-leftcell {
    margin-left: -23% !important;
    width: 37% !important;
    font-size: 12px !important;
  }

  .trngdatedesc-lefthead {
    margin-left: -10% !important;
    width: 35% !important;
    font-size: 12px !important;
  }

  .trngdatedesc-leftcell {
    margin-left: -10% !important;
    /* width: 58% !important; */
    font-size: 12px !important;
  }

  .assngModToEmpLocation {
    color: #686868;
    margin-left: -1px;
  }

  .assngModToEmpEmpName {
    margin-left: 2%;
    color: #686868;
  }

  .empNameTxtBox {
    width: 105.5%;
    max-width: 82%;
    margin-left: 3.4%;
    height: 41px;
    color: #bbb;
    padding: 10px 0 0 8px;
  }

  .locTxtBox {
    width: 105.5%;
    max-width: 94.5%;
    margin-left: 1.5%;
    height: 41px;
    color: #bbb;
    padding: 10px 0 0 8px;
  }

  .floatRightCreateEmp {
    margin: 1.5%;
    position: relative;
    right: -3%;
    float: right;
    top: -5%;
  }

  .floatRightCreateEmpTrain {
    margin: 1.9%;
    position: relative;
    right: -5%;
    float: right;
    top: 2%;
  }

  .errMsgDiv {
    margin-left: 0px;
    margin-top: -5px;
  }

  .empForErrRow {
    position: relative;
    top: -10px;
    margin-left: 1%;
    width: 103%;
    max-width: 104%;
  }

  .EmpForErrWrdLmt {
    margin-top: -2.5%;
    margin-right: 1.8%;
  }

  .EmpForWrdLmtDiv {
    margin-right: 2%;
  }

  .createEmpSrWrdLmt {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: -7.1%;
    font-size: 11px;
  }

  .createEmpFrWrdLmt {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: -5%;
    font-size: 11px;
  }

  .EmpForWrdLmt {
    margin-top: -2%;
    margin-right: 1.5%;
  }

  .EmpSurErrWrdLmt {
    margin-top: -3%;
    margin-right: 1%;
  }

  .ddlLoc {
    width: 107.3%;
    max-width: 108%;
    margin-left: 19.5%;
  }

  .ddlJobTitle {
    width: 103.5%;
    max-width: 104%;
    margin-left: -10px;
  }

  .createEmpJobDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: 0.5%;
    font-size: 11px;
  }

  .trndiv {
    top: -30px;
    margin-left: 0%;
  }

  .createEmpTrngDDLReq {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -2.5%;
    margin-left: 2%;
    font-size: 11px;
  }

  .chckboxEmpCol {
    display: flex;
    margin-top: 2%;
    margin-left: 22.2%;
    align-items: baseline;
  }

  .empCreateTitle {
    margin-bottom: 0px;
    padding-left: 1.6%;
    padding-top: 2.5%;
    margin-left: -1.7%;
    color: #363636 !important;
  }



  .EmpSurWrdLmt {
    margin-top: -2%;
    margin-right: -1.8%;
  }

  .LabelAdj {
    margin-left: 21.5%;
  }

  .sameEmpErrorDiv {
    margin-left: 4.5%;
  }

  .empSurErrRow {
    position: relative;
    top: -7px;
    margin-left: 3%;
  }

  .trainerlbl {
    margin-left: -10px;
  }

  .trnLbl {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
  .createEmpSrWrdLmt {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -6.5%;
    margin-left: -11%;
    font-size: 11px;
  }

  .createEmpFrWrdLmt {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -4.5%;
    margin-left: -9.5%;
    font-size: 11px;
  }

  .EmpSurErrWrdLmt {
    margin-right: -1.5%;
    margin-top: -5.7%;
  }

  .EmpForErrWrdLmt {
    margin-top: -5.5%;
    margin-right: -0.5%;
  }
}

@media only screen and (max-width: 999px) {
  .createEmpSrWrdLmt {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -9.5%;
    margin-left: -15%;
    font-size: 11px;
  }

  .createEmpFrWrdLmt {
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -7.5%;
    margin-left: -12.5%;
    font-size: 11px;
  }

  .EmpSurErrWrdLmt {
    margin-right: -2.5%;
    margin-top: -13.5%;
  }

  .EmpForErrWrdLmt {
    margin-top: -14.5%;
    margin-right: -2.5%;
  }

  .trainerlbl {
    margin-left: -5px;
  }
}

.manageemp-successtoast {
  top: 18.2%;
  left: -3%;
}

@media screen and (min-width: 1920px) {
  .manageemp-successtoast {
    top: 10.6%;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .manageemp-successtoast {
    top: 19.6%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .manageemp-successtoast {
    top: 17.3%;
  }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
  .manageemp-successtoast {
    top: 25%;
    left: -1%;
  }

  #manageemp__toast .toast-success span {
    margin-right: 22px;
  }
}

.modtoemp-headerconntainer {
  display: flex;
}

.modtoemp-header__empname {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.modtoemp-header__location {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  padding-left: 5.5%;
}

.modtoemp-header__locationflexcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}