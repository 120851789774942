.suModalLayout {
  display: grid;
  grid-template-columns: 45% 45%;
  padding: 0px 20px;
  column-gap: 10%;
}

.css-2613qy-menu {
  position: absolute !important;
}

.userdropdown {
  font-size: 16px !important;
  font-weight: normal !important;
  color: #111 !important;
  float: left !important;
}

.marginleft15 {
  margin-left: 15px;
}

.spinnerstyle {
  color: #111;
  top: 45%;
  position: fixed;
}

.bggreen {
  background-color: #007f00;
}

.colwhite {
  color: white;
}

.breadcrumbtag {
  width: 15px;
  height: 10px;
  margin-top: 4px;
}

.savebtn {
  float: right;
  font-weight: bold;
  font-size: 16px;
  border: solid 1px #007f00 !important;
}

.createbtn {
  padding: 2%;
  padding-right: 0px;
  margin-bottom: 20px;
}

.agheight {
  height: 85vh;
}

.top10 {
  top: 10%;
}

.top20 {
  top: 20%;
}

.successtoast {
  margin-top: -17px;
  margin-bottom: 17px;
}

.migration {
  float: left;
  margin-left: 25px;
}

.textcolor {
  color: #686868;
}

.mb5 {
  margin-bottom: 5px;
}

.width100 {
  width: 100%;
  overflow: auto;
}

.width50 {
  width: 90%;
}

.width80 {
  width: 80% !important;
}

.ddtoggle {
  width: 100%;
  /* margin-left:0px; */
}

.border1 {
  border: 1px solid #dedede;
}

.paddbg {
  background-color: #007f00 !important;
  /* padding:9px 25px; */
}

.mt18 {
  margin-top: 18px;
}

.redstar {
  color: red;
}

.width60 {
  width: 60%;
}

.errorborder {
  border: solid 1px red !important;
}



.downright {
  position: relative;
  right: -4%;
  top: 4px;
  float: right;
}

.savebtn:hover {
  border: solid 1px #007f00 !important;
}

.width210 {
  width: 210px !important;
}

.username {
  color: #111111 !important;
}

.aggridalignment {
  height: 570px;
  width: 100%;
}

.paddbg:hover {
  background-color: #007f00 !important;
}

.inputfield:hover {
  border: 1px solid #dedede !important;
  border-color: #dedede !important;
}

.form-control:focus {
  border-color: #dedede !important;
}

.savebtn:focus {
  border-color: #007f00 !important;
}

.css-1insrsq-control {
  height: 46px !important;
}



.ddmenu {
  position: fixed !important;
  will-change: unset !important;
  top: unset !important;
  left: 0 !important;
  transform: scale(0.685, 1) !important;
  height: 200px;
  font-size: medium;
  width: 100%;
  zoom: 92%;
  border-radius: 3px;
  padding: 0.5rem;
}

/* .css-4ljt47-MenuList {
    width: 68.5% !important;
    background: white;
    z-index: 999999999999;
    position: fixed !important;
  } */

.width75 {
  width: 75%;
}

.infoIconsystemUser {
  color: #218dcc !important;
  /* margin-top: 2% !important; */
  cursor: pointer;
  margin-left: 10px;
}

.infoBoxsystemUser {
  width: 50%;
  border-radius: 5px;
}

.infosystemUser {
  width: 275px !important;
  /* height: 100px; */
  /* margin: 25px 88px 112px 0; */
  padding: 15px 15px 15px 19px;
  border-radius: 5px;
  box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
  /* border: solid 1px #4fc3f7; */
  background-color: #ecfafe;
  margin-left: 7px;
  position: absolute;
  bottom: 0;
  /* margin-top: -9%; */
}

.approved-flexcontainer {
  display: flex;
}

@media only screen and (max-width: 1199px) {
  .infosystemUser {
    width: 200px !important;
    /* height: 100px; */
    /* margin: 25px 88px 112px 0; */
    padding: 15px 15px 15px 19px;
    border-radius: 5px;
    box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
    /* border: solid 1px #4fc3f7; */
    background-color: #ecfafe;
    margin-left: 51px;
    position: absolute;
    bottom: 0;
    /* margin-top: -9%; */
  }
}

@media (max-width: 768px) {
  .enabled-select {
    margin-top: 10px;
  }
}