.boldtext {
  font-weight: 600;
}

/* 
.gridcellicons1 {
  /* padding-left: 2%;
    // width: 100%;
    position: fixed; */

/* display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: -45%; */
/* }  */
/* @media screen and (min-width: 1100px) {
  .gridcellicons2 {
    padding-left: 3%;
    position: fixed;
  }

  .gridcellicons {
    padding-left: 4%;
    position: fixed;
  }

  .gridcellicons-region {
    padding-left: 4%;
    position: fixed;
  }
} */

@media screen and (max-width: 1099px) {
  .gridcellicons2 {
    padding-left: 1%;
    /* width: 100%; */
    position: fixed;
  }

  .gridcellicons1 {
    margin-left: 0%;
    white-space: nowrap;
  }

  .gridcellicons-region {
    padding-left: 4%;
    position: fixed;
    margin-top: -4px;
  }
}

th {
  /* background-color: #e8e8e8; */
  /* border-left: lightgrey solid 2px !important; */
  border: #bbb solid 1px;
  padding: 8px 5px;
  text-align: center;
  min-width: 180px;
}

/* tr {
  /* border: lightgrey solid 1px; */
/* }  */

td {
  text-align: center;
  border: #dedede solid 1px;
}

.testclass {
  display: grid;
  grid-template-columns: repeat(10, 1fr) 100px;
  grid-gap: 5px;
}

.errorborder {
  border: solid 1px red !important;
}

.assessmenterror {
  color: #ff7591 !important;
  font-size: 12px;
  text-decoration: none;
  /* margin: 0px 5px; */
}

.assessmenterror:hover {
  color: #ff7591 !important;
  font-size: 12px;
  text-decoration: none;
  /* margin: 0px 5px; */
}

.ag-row {
  z-index: 0;
}

.ag-row-focus {
  z-index: 1;
}

/* custom-select.css */
.react-select-container {
  width: 200px;
  /* Adjust the width as needed */
}


.react-select__option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



.char-limit {
  color: #0f0f0f !important;
}

.stickheader {
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  background-color: white;
  z-index: 1;
}

.stickheader2 {
  position: sticky;
  position: -webkit-sticky;
  top: 105px;
  background-color: white;
  /* z-index: 100; */
  padding-top: 4px;
  height: 43px;
}

.stickheader3 {
  position: sticky;
  position: -webkit-sticky;
  top: 160px;
  background-color: white;
  /* z-index: 100; */
  padding-top: 4px;
  height: 43px;
}

.stickheader4 {
  position: sticky;
  position: -webkit-sticky;
  /* top: 160px; */
  background-color: white;
  /* z-index: 100; */
  padding-top: 4px;
  height: 43px;
}

.flatpickrstyle {
  border: none;
  padding-left: 32px;
  margin-left: -32px;
  width: 200px;
}

.stickheader1 {
  position: sticky;
  position: -webkit-sticky;
  top: 50px;
  background-color: white;
  /* z-index: 100; */
  padding-top: 4px;
  height: 43px;
}


.startDatertr {
  padding: 4px;
  border: solid 1px #dedede;
  display: flex;
  align-items: flex-end;
}

.flatpickr-wrapper {
  width: 100% !important;
  display: inline-block;
}

/* 
.tableoverflow {
  /* overflow: scroll; */
/* } */

.fontcolor {
  opacity: 1 !important;
  columns: unset !important;
}

.panelSticky {
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
  padding: 0;
  background: white;
  left: 0;
  min-width: 50%;
}

.table-pos {
  padding: 0;
  /* top: -20px; */
}

@media screen and (max-width: 999px) {
  .assessment-dropdown {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .reset-Btn-rtr {
    font-family: "Roboto", sans-serif !important;
    background-color: white;
    margin-top: 15px;
    font-weight: 600;
    opacity: 0.8;
    padding: 8px 15px;
    font-size: 10px;
    float: right;
    transform: translateX(50px);
    line-height: 1.4375;
  }

  .reset-Btn {
    font-family: "Roboto", sans-serif !important;
    background-color: white;
    margin-top: -5px;
    font-weight: 600;
    opacity: 0.8;
    padding: 8px;
    font-size: 10px;
    transform: translateX(-29px);
  }

  .assessment-assign-employee {
    margin-left: 36%;
  }

  .infoassign {
    margin-left: 6% !important;
    margin-top: 15% !important;
  }

  .info-msg-critical-qus {
    width: 295px !important;
    height: auto;
    right: 28px;
    bottom: 20px;
    padding: 10px 10px 16px 15px;
    border-radius: 5px;
    box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
    border: solid 1px #4fc3f7;
    background-color: #ecfafe;
    color: #111;
    z-index: 3;
    position: absolute;
  }

  .rtr-qus-option-div {
    height: 180px !important;
  }
}

@media screen and (min-width: 1000px) {
  .assessment-assign-employee {
    padding-top: 5px;
    margin-left: -2%;
  }

  .reset-Btn {
    font-family: "Roboto", sans-serif !important;
    background-color: white;
    margin-top: 18px;
    font-weight: 600;
    opacity: 0.8;
    transform: translateX(15px);
  }
}

.error-adj-assessment {
  position: relative;
  top: -10px;
}

.error-limit-assessment {
  position: relative;
  top: -6.5px;
  left: 2%;
}

@media screen and (min-width: 1000px) and (max-width: 1399px) {
  .reset-Btn-rtr {
    font-family: "Roboto", sans-serif !important;
    background-color: white;
    margin-top: 15px;
    font-weight: 600;
    opacity: 0.8;
    padding: 10px 20px;
    font-size: 12px;
    float: right;
    transform: translateX(85px);
    line-height: 1.4375;
  }
}

@media screen and (min-width: 1400px) {
  .reset-Btn-rtr {
    font-family: "Roboto", sans-serif !important;
    background-color: white;
    margin-top: 15px;
    font-weight: 600;
    opacity: 0.8;
    padding: 10px 20px;
    font-size: 12px;
    float: right;
    transform: translateX(135px);
    line-height: 1.4375;
  }

  .infoassign {
    margin-top: 4% !important;
  }
}

.RecordTrainingTable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 480px;
  margin: 1.5% 1%;
  width: 99%;
  overflow: scroll;
  margin-right: 30%;
}

.RTRModal {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  column-gap: 0%;
  margin: 5px;
  width: 100%;
  left: 5px;
  margin-bottom: 15px;
}

.recordresult-successtoast {
  top: 0%;
  left: 25%;
}

@media screen and (max-width: 800px) {
  .recordresult-successtoast {
    top: 0%;
    left: 1%;
  }

  #notrecordresult__toast .toast-success span {
    margin-right: 19px !important;
  }

  .trashOnSmallerScreen {
    margin-left: 0px !important;
  }
}

.assignTabs {
  font-size: 24px;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 2;
  position: relative;

  letter-spacing: 0.25px;
  color: #686868 !important;
  border: none !important;
  background-color: #e8e8e8 !important;
}

.headerAssign {
  font-family: "Roboto", sans-serif !important;
  font-size: 24px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  float: left;
  line-height: 0.88 !important;
  letter-spacing: normal;
  color: #686868 !important;
}

.assignInfoIcon {
  color: #218dcc !important;
  /* margin-top: 12% !important; */
  cursor: pointer;
  /* margin-left: 10px; */
}

.hide {
  display: none;
}

#tskRetInfo:hover+.hide {
  display: inline-table;
}

.infoassign {
  width: 320px !important;
  /* height: 100px; */
  /* margin: 25px 88px 112px 0; */
  padding: 15px 15px 15px 19px;
  border-radius: 5px;
  box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
  /* border: solid 1px #4fc3f7; */
  background-color: #ecfafe;
  /* margin-left: 23%; */
  position: absolute;
  top: 0;
  margin-top: 7%;
}

.row-header-container p {
  align-self: center;
  margin-bottom: 0px;
}

.date-row-heading {
  height: 61px;
  display: flex;
}

.emp-row-heading,
.loc-row-heading,
.score-row-heading {
  height: 43px;
  display: flex;
}

.assigndate-allemployees {
  display: flex;
  gap: 18px;
  padding-inline: 12px;
  margin-top: 18px;
  margin-inline: 5px;
}

.confirmpart2valtext {
  padding: 32px 15px 0px 10px;
  margin-bottom: 0px;
  font-size: 21px;
  align-self: center;

  /* color: #686868; */
}

.info-box-critical-qus {
  cursor: pointer;
  height: 25px;
  width: 25px;
  margin: 2px;
}

.info-msg-critical-qus {
  width: 310px;
  height: auto;
  right: 28px;
  bottom: 20px;
  padding: 10px 10px 16px 15px;
  border-radius: 5px;
  box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
  border: solid 1px #4fc3f7;
  background-color: #ecfafe;
  color: #111;
  z-index: 3;
  position: absolute;
}

.reset-Btn-rtr:hover {
  background-color: white;
}