.h1{
    font-size: 36px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 500;
}

.h2{
    font-size: 24px;
    font-family: 'Roboto', sans-serif !important;
}

.h3{
    font-size: 18px;
    font-family: 'Roboto', sans-serif !important;
}

.h4{
    font-size: 16px;
    font-family: 'Roboto', sans-serif !important;
}

.h5{
    font-size: 14px;
    font-family: 'Roboto', sans-serif !important;
}

.h6{
    font-size: 12px;
    font-family: 'Roboto', sans-serif !important;
    color: #0f0f0f !important   ;
}

.h7{
    font-size: 11px;
    font-family: 'Roboto', sans-serif !important;
}

.bold{
    font-weight: 700;
}

.medium{
    font-weight: 600;
}

.regular{
    font-weight: 400;
}

.light{
    font-weight: 300;
}