.p0 {
  padding-left: 0px !important;
}


.me-md-n30 {
  margin-right: 10px !important;
}

.disable-image {
  pointer-events: none;
}

.character-count {
  margin-top: 9px !important;
}

.w-83 {
  width: 83% !important;
}

.textbox {
  height: 45px;
  margin-top: 4px;
  border: solid 1px #d8d8d8;
  padding: 10px;
}



@media (min-width: 768px) {

  .chkConfirmlbl {
    margin-left: 5px !important;
  }

  .ms-md-6 {
    margin-left: 2.8rem !important;
  }

  .me-md-n30 {
    margin-right: 30px !important;
  }

  .me-md-n67 {
    margin-right: 67px !important;
  }
}

.btn-brand-secondary {
  border-color: rgb(233, 233, 233) !important;
}


.newstxtField {
  margin-left: 2%;
  width: 96%;
}

.newstxtDescField {
  margin-left: 2%;
  width: 96%;
  height: 240px;
  margin-bottom: -3%;
}

.errTagNewsDesc {
  margin-left: -3%;
}

.priorityDisplay {
  margin-top: 15;
  margin-left: -2%;
  width: 106%;
}

.priorityImgStyle {
  height: 20;
  margin-top: -5;
}

.frmChkMrLeft {
  margin-left: -2%;
}

@media screen and (min-width:1000px) and (max-width:1099px) {
  .thNwFdWordLmtNoErr {
    margin-right: 5.5% !important;
    margin-top: 0.5%;
  }

  .thNwFdWordLmtErr {
    margin-right: 5% !important;
    margin-top: -3.5%;
  }

  .errDivNewsDesc {
    /* margin-left: 1.6%; */
    margin-top: 0.9%;
  }

  .errTagNewsDesc {
    margin-left: -5%;
  }

  .errDivNewsHead {
    margin-left: 1.6%;
    margin-top: 0;
  }
}

@media screen and (max-width:999px) {
  .thNwFdWordLmtNoErr {
    margin-right: 6% !important;
    margin-top: 0.5%;
  }

  .thNwFdWordLmtErr {
    margin-right: 6% !important;
    margin-top: -4%;
  }

  .errDivNewsDesc {
    /* margin-left: 1.6%; */
    margin-top: 0.2%;
  }

  .errTagNewsDesc {
    margin-left: -6%;
  }

  .errDivNewsHead {
    margin-left: 1.6%;
    margin-top: -0.5%;
  }
}

@media screen and (max-width:1099px) {

  .chkConfirmlbl {
    margin-left: 5px !important;
  }

  .newsHdWrdLmtErr {
    margin-top: 0%;
    margin-left: 91.8%;
  }

  .newsHdWrdLmtNoErr {
    margin-top: 0%;
    margin-left: 91%;
  }

  .newsDescWrdLmtErr {
    margin-top: -1%;
    margin-left: 86.5%;
  }

  .newsDescWrdLmtNoErr {
    margin-top: -2%;
    margin-left: 86%;
  }

  .errTagNewsHead {
    margin-left: -1%;
  }

  .thNFWordLmtErr {
    margin-right: 5.5% !important;
    margin-top: -5%;
  }




}

@media screen and (min-width:1100px) and (max-width:1399px) {
  .newsHdWrdLmtErr {
    margin-top: 0%;
    margin-left: 95.5%;
  }

  .newsHdWrdLmtNoErr {
    margin-top: 0.5%;
    margin-left: 97%;
  }

  .newsDescWrdLmtErr {
    margin-top: -1%;
    margin-left: 93.5%;
  }

  .newsDescWrdLmtNoErr {
    margin-top: 0.5%;
    margin-left: 95%;
  }

  .errTagNewsHead {
    /* margin-left: 0.4%; */
  }

  .errTagNewsDesc {
    /* margin-left: -1.8%; */
  }

  .thNwFdWordLmtNoErr {
    margin-right: 4.5% !important;
    margin-top: -0.5%;
  }

  .thNwFdWordLmtErr {
    margin-right: 4.6% !important;
    margin-top: -3%;
  }

  .thNFWordLmtErr {
    margin-right: 5.5% !important;
    margin-top: -2.8%;
  }

  .errDivNewsHead {
    /* margin-left: 1.6% ; */
    margin-top: -0.5%;
  }

  .errDivNewsDesc {
    margin-top: -0.5%;
  }

  .chkConfirmlbl {
    margin-left: 5px !important;
  }
}


@media screen and (min-width:1400px) {
  .newsHdWrdLmtErr {
    /* margin-top: 0.5%; */
    margin-left: 98%;
  }

  .newsHdWrdLmtNoErr {
    margin-top: 0.5%;
    margin-left: 98.2%;
  }

  .newsDescWrdLmtErr {
    /* margin-top: 0.5%; */
    margin-left: 96.5%;
  }

  .newsDescWrdLmtNoErr {
    margin-top: 0.5%;
    margin-left: 96.5%;
  }

  .errTagNewsHead {
    margin-left: 0.5%;
  }

  .errTagNewsDesc {
    margin-left: -1.4%;
  }

  .thNwFdWordLmtNoErr {
    margin-right: 4% !important;
    margin-top: -0.3%;
  }

  .thNwFdWordLmtErr {
    margin-right: 4% !important;
    margin-top: -2.5%;
  }

  .thNFWordLmtErr {
    margin-right: 5.5% !important;
  }

  .errDivNewsHead {
    margin-left: 1.8%;
    margin-top: 0%;
  }

  /* .errDivNewsDesc{
    margin-left: 1.6%;
    margin-top: -1.2%;
  } */

  .chkConfirmlbl {
    margin-left: 5px !important;
  }
}