.empLocStyle {
  width: 105.5%;
  height: 41px;
  color: #bbb;
  padding: 10px 0 0 8px;
}

.empName {
  margin-left: 2%;
  color: #686868;
}
.locName {
  margin-left: -3px;
  color: #686868;
}

.datePickrPDLeft {
  padding-left: 0px;
}

.wrdLmtAddlNoErr {
  margin-top: -3.5%;
  margin-right: -2.5%;
}
.wrdLmtAddlErr {
  margin-top: -4%;
  margin-right: -7.5%;
}

.AddlDiv {
  margin-right: 0%;
  width: 106%;
  max-width: 105%;
}

.AddlDetailsDivWidth {
  width: 103%;
  max-width: 103%;
}

.AddlDeatilsNoErr {
  margin-top: 1%;
  margin-right: -7.5%;
}

@media screen and (min-width: 1400px) {
  .empLocStyle {
    width: 105.5%;
    height: 41px;
    color: #bbb;
    padding: 10px 0 0 8px;
  }

  .empName {
    margin-left: 2%;
    color: #686868;
  }
  .locName {
    margin-left: -3px;
    color: #686868;
  }

  .datePickrPDLeft {
    padding-left: 0px;
  }

  .wrdLmtAddlNoErr {
    margin-top: -3.5%;
    margin-right: -2.5%;
  }
  .wrdLmtAddlErr {
    margin-top: -3%;
    margin-right: -5%;
  }

  .AddlDiv {
    margin-right: 0%;
    width: 106%;
    max-width: 105%;
  }

  .AddlDetailsDivWidth {
    width: 103%;
    max-width: 103%;
  }

  .AddlDeatilsNoErr {
    margin-top: 3%;
    margin-right: -5%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1399px) {
  .empLocStyle {
    width: 105.5%;
    height: 41px;
    color: #bbb;
    padding: 10px 0 0 8px;
  }

  .empName {
    margin-left: 2%;
    color: #686868;
  }
  .locName {
    margin-left: -3px;
    color: #686868;
  }

  .datePickrPDLeft {
    padding-left: 0px;
  }

  .wrdLmtAddlNoErr {
    margin-top: -3.5%;
    margin-right: -2.5%;
  }
  .wrdLmtAddlErr {
    margin-top: -4%;
    margin-right: -7%;
  }

  .AddlDiv {
    margin-right: 0%;
    width: 106%;
    max-width: 105%;
  }

  .AddlDetailsDivWidth {
    width: 103%;
    max-width: 103%;
  }

  .AddlDeatilsNoErr {
    margin-top: 1%;
    margin-right: -7%;
  }
}

@media screen and (max-width: 1099px) {
  .empLocStyle {
    width: 105.5%;
    max-width: 100% !important;
    height: 41px;
    color: #bbb;
    padding: 10px 0 0 8px;
  }

  .empName {
    margin-left: 2%;
    color: #686868;
  }
  .locName {
    margin-left: -3px;
    color: #686868;
  }

  .datePickrPDLeft {
    padding-left: 0px;
  }

  .wrdLmtAddlNoErr {
    margin-top: -3.5%;
    margin-right: -2.5%;
  }
  .wrdLmtAddlErr {
    margin-top: -4.8%;
    margin-right: -9.5%;
  }

  .AddlDiv {
    margin-right: 0%;
    width: 106%;
    max-width: 105%;
  }

  .AddlDetailsDivWidth {
    width: 103%;
    max-width: 103%;
  }

  .AddlDeatilsNoErr {
    margin-top: -0.5%;
    margin-right: -9.5%;
  }
}

.ag-select-pagesize-emptomod {
  width: auto;
  max-width: 7%;
  padding: 0 0 0 2px;
  position: relative;
  bottom: 6.5%;
  left: 44.5%;
}

.ag-select-pagesize-assignempnew {
  width: auto;
  max-width: 4%;
  padding: 0 0 0 2px;
  position: relative;
  bottom: 6.4%;
  left: 66%;
}

.ag-select-pagesize-modtoemp1 {
  width: auto;
  max-width: 7%;
  padding: 0 0 0 2px;
  position: relative;
  bottom: 6.5%;
  left: 57%;
}

.ag-select-pagesize-modtoemp {
  width: auto;
  max-width: 7%;
  padding: 0 0 0 2px;
  position: relative;
  bottom: 6.1%;
  left: 56%;
}

@media screen and (min-width: 1920px) {
  .ag-select-pagesize-emptomod {
    bottom: 6.7%;
    left: 62%;
  }

  .ag-select-pagesize-assignempnew {
    bottom: 6.1%;
    left: 76%;
  }

  .ag-select-pagesize-modtoemp1 {
    bottom: 6.7%;
  }

  .ag-select-pagesize-modtoemp {
    bottom: 5.9%;
    left: 71%;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .ag-select-pagesize-emptomod {
    left: 40.5%;
  }

  .ag-select-pagesize-assignempnew {
    bottom: 6.5%;
    left: 63.7%;
  }

  .ag-select-pagesize-modtoemp1 {
    left: 54%;
    bottom: 6.7%;
  }

  .ag-select-pagesize-modtoemp {
    left: 53%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .ag-select-pagesize-emptomod {
    max-width: 8.8%;
    bottom: 6.3%;
    left: 24%;
  }

  .ag-select-pagesize-assignempnew {
    max-width: 5%;
    left: 55.2%;
    bottom: 6.6%;
  }

  .ag-select-pagesize-modtoemp1 {
    max-width: 9.5%;
    bottom: 6.4%;
    left: 16%;
  }

  .ag-select-pagesize-modtoemp {
    max-width: 9.5%;
    left: 45%;
  }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
  .ag-select-pagesize-emptomod {
    bottom: 6.3%;
    left: 44%;
  }

  .ag-select-pagesize-assignempnew {
    max-width: 7%;
    left: 42%;
    bottom: 6.5%;
  }

  .ag-select-pagesize-modtoemp1 {
    bottom: 6.3%;
    left: 38.5%;
  }

  .ag-select-pagesize-modtoemp {
    max-width: 9%;
    left: 27.5%;
  }
}
@media screen and (min-height: 900px) {
  .bigScreenLoader_overlay {
    height: 88vh;
  }
}
