.actions-render-cell {
  overflow: visible;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.hideAsterisk {
  display: none;
}
.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

/* .ag-body-viewport-wrapper */
/* .ag-root,
.ag-body-viewport,
.ag-cell-value {
  overflow: visible !important;
} */
.dropdown-toggle::after {
  display: none !important;
}

.RenderIcons {
  display: flex;
  text-align: center;
  justify-content: unset;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
}

.TitleStyle {
  color: '#363636' !important;
  font-family: 'Roboto' !important;
  font-size: '32px' !important;
  font-weight: 'bold' !important;
}

.WordCount {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.55px;
  text-align: right;
  color: #0f0f0f;
}

.RequiredField {
  width: 183px;
  height: 21px;
  margin: 0 3px 0 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #cc0000;
}

.gridSize {
  height: 567px;
  width: 96%;
  padding-left: 2%;
  padding-right: 5%;
}

.loceditdel_cell {
  margin-left: -1%;
}

.AssignDeanonymise {
  margin-left: 136%;
}

.ddlPUManageReg {
  width: 96.5%;
  margin-left: 2px;
}

.ddlCTYManageReg {
  width: 96.3%;
  margin-left: 2px;
}

.ddlPUValManageReg {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #111 !important;
  float: left !important;
  margin-left: -1.5% !important;
  text-align: left;
  width: 86%;
}

.ddlDeAnonymise {
  width: 100%;
  /* margin-left: 2px; */
}

.locTxtField {
  height: 46;
}

.ctyTxtField {
  height: 46 !important;
  margin-left: -1px;
}

.assignSiteMgEmail-lefthead {
  margin-left: -27% !important;
  width: 72% !important;
  font-size: 12px !important;
}

.assignSiteMgName-lefthead {
  margin-left: -26% !important;
  width: 33% !important;
  font-size: 12px !important;
}

.assignSiteMgEmail-leftcell {
  margin-left: -27% !important;
  width: 72% !important;
  font-size: 12px !important;
}

.assignSiteMgName-leftcell {
  margin-left: -26% !important;
  width: 72% !important;
  font-size: 12px !important;
}

.deanonymiseEmail-lefthead {
  margin-left: -27% !important;
  width: 72% !important;
  font-size: 12px !important;
}

.deanonymiseName-lefthead {
  margin-left: -26% !important;
  width: 33% !important;
  font-size: 12px !important;
}

.deanonymiseEmail-leftcell {
  margin-left: -27% !important;
  width: 72% !important;
  font-size: 12px !important;
}

.deanonymiseName-leftcell {
  margin-left: -26% !important;
  width: 72% !important;
  font-size: 12px !important;
}

.trngRegchkbx-lefthead {
  width: 8% !important;
  font-size: 12px !important;
}

.trngRegchkbx-leftcell {
  margin-left: -0.7% !important;
  width: 8% !important;
  font-size: 12px;
}

.ddlDeanonymiseReason {
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #111 !important;
  float: left !important;
  margin-left: -0.3% !important;
  text-align: left;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
}

.stopOverflow {
  overflow: hidden;
}

.manageRegASMDEANONYMISEOverlay {
  padding-top: 6%;
}

.regEditIcon {
  position: fixed;
  /* margin-left: -3%; */
}

.regDelIcon {
  position: fixed;
  margin-left: 4%;
}

.gridMgReg {
  padding-right: 0% !important;
  height: 525px;
}

.headRegTraindate {
  margin-left: -2.7%;
}

.frmRegChkMrLeft {
  margin-left: -2%;
}

.assignReg-popup-pos {
  width: 42% !important;
  /* padding-top: 10%; */
  margin-left: 27% !important;
}

.deanonymise-popup-pos {
  width: 42% !important;
  /* padding-top: 10%; */
  margin-left: 27% !important;
}

.AddlDetailsRegDivWidth {
  width: 103%;
  max-width: 103%;
  padding-inline: 15px;
}

.wrdLmtRegAddlErr {
  margin-top: -3%;
  margin-right: -3%;
}

.addlDetailsRegStyle {
  margin-top: -4%;
  margin-left: -1%;
}

@media screen and (max-width: 1099px) {
  .deanonymiseErr {
    margin-top: -1.5%;
    margin-left: 0.5% !important;
  }

  .deanonymisedetailserr {
    margin-left: -2%;
    margin-top: -1%;
  }

  .ddlCTYManageReg {
    width: 95.2%;
    margin-left: 2px;
  }

  .ddlPUManageReg {
    width: 95.5%;
    margin-left: 2px;
  }

  .regEditIcon {
    position: fixed;
    /* margin-left: -3%; */
  }

  .regDelIcon {
    position: fixed;
    margin-left: 4%;
  }

  .deanonymiseEmail-lefthead {
    margin-left: -27% !important;
    width: 61% !important;
    font-size: 12px !important;
  }

  .deanonymiseName-lefthead {
    margin-left: -26% !important;
    width: 33% !important;
    font-size: 12px !important;
  }

  .deanonymiseEmail-leftcell {
    margin-left: -27% !important;
    width: 72% !important;
    font-size: 12px !important;
  }

  .deanonymiseName-leftcell {
    margin-left: -26% !important;
    width: 33% !important;
    font-size: 12px !important;
  }

  .assignSiteMgEmail-lefthead {
    margin-left: -27% !important;
    width: 61% !important;
    font-size: 12px !important;
  }

  .assignSiteMgName-lefthead {
    margin-left: -26% !important;
    width: 33% !important;
    font-size: 12px !important;
  }

  .assignSiteMgEmail-leftcell {
    margin-left: -27% !important;
    width: 72% !important;
    font-size: 12px !important;
  }

  .assignSiteMgName-leftcell {
    margin-left: -26% !important;
    width: 33% !important;
    font-size: 12px !important;
  }

  .trngRegchkbx-lefthead {
    width: 8% !important;
    font-size: 12px !important;
  }

  .trngRegchkbx-leftcell {
    /* margin-left: -3.7% !important; */
    width: 12% !important;
    font-size: 12px;
  }

  .gridHtWdassignTaskMgReg {
    height: 572px;
    width: 109.3%;
    padding-left: 0.6rem !important;
    margin-left: -4.8%;
  }

  .manageRegASMDEANONYMISEOverlay {
    padding-top: 12%;
  }

  .headRegTraindate {
    margin-left: -4.7%;
    font-weight: bold;
  }

  .headRegTraindateDean {
    margin-left: -4.2%;
    font-weight: bold;
  }

  .headRegAssignSiteTraindate {
    margin-left: 2.6%;
    font-weight: bold;
  }

  .frmRegChkMrLeft {
    margin-left: -1.8%;
  }

  .assignReg-popup-pos {
    width: 108% !important;
    /* padding-top: 1.9%; */
    margin-left: -5% !important;
  }

  .AddlDetailsRegDivWidth {
    width: 106.5%;
    max-width: 106.5%;
  }

  .wrdLmtRegAddlNoErr {
    margin-top: -3.5%;
    margin-right: -0.9%;
  }

  .wrdLmtRegAddlErr {
    margin-top: -3.5%;
    margin-right: -0.8%;
  }

  .addlDetailsRegStyle {
    margin-top: -4%;
    margin-left: -1.2%;
  }

  .deanonymise-popup-pos {
    width: 65% !important;
    /* padding-top: 6.5%; */
    margin-left: 19% !important;
  }

  .siteMngPopUpchk {
    margin-left: 0.5%;
    margin-top: -1%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1399px) {
  .deanonymiseErr {
    margin-top: -1%;
  }

  .deanonymisedetailserr {
    margin-left: -1.5%;
    margin-top: 1%;
  }

  .ddlCTYManageReg {
    width: 94.7%;
    margin-left: 2px;
  }

  .ddlPUManageReg {
    width: 94.2%;
    margin-left: 2px;
  }

  .regEditIcon {
    position: fixed;
    margin-left: 1%;
  }

  .regDelIcon {
    position: fixed;
    margin-left: 3.5%;
  }

  .gridMgReg {
    padding-right: 0% !important;
    height: 525px;
  }

  .deanonymiseEmail-lefthead {
    margin-left: -27% !important;
    width: 61% !important;
    font-size: 12px !important;
  }

  .deanonymiseName-lefthead {
    margin-left: -26% !important;
    width: 33% !important;
    font-size: 12px !important;
  }

  .deanonymiseEmail-leftcell {
    margin-left: -27% !important;
    width: 72% !important;
    font-size: 12px !important;
  }

  .deanonymiseName-leftcell {
    margin-left: -26% !important;
    width: 34% !important;
    font-size: 12px !important;
  }

  .assignSiteMgEmail-lefthead {
    margin-left: -27% !important;
    width: 61% !important;
    font-size: 12px !important;
  }

  .assignSiteMgName-lefthead {
    margin-left: -26% !important;
    width: 33% !important;
    font-size: 12px !important;
  }

  .assignSiteMgEmail-leftcell {
    margin-left: -27% !important;
    width: 72% !important;
    font-size: 12px !important;
  }

  .assignSiteMgName-leftcell {
    margin-left: -26% !important;
    width: 34% !important;
    font-size: 12px !important;
  }

  .trngRegchkbx-lefthead {
    width: 8% !important;
    font-size: 12px !important;
  }

  .trngRegchkbx-leftcell {
    /* margin-left: -2.7% !important; */
    width: 10% !important;
    font-size: 12px;
  }

  .gridHtWdassignTaskMgReg {
    height: 572px;
    width: 105%;
    padding-left: 0.6rem !important;
    margin-left: -2.7%;
  }

  .manageRegASMDEANONYMISEOverlay {
    padding-top: 8%;
  }

  .headRegTraindate {
    margin-left: -4.3%;
    font-weight: bold;
  }

  .headRegTraindateDean {
    margin-left: -3.3%;
    font-weight: bold;
  }

  .headRegAssignSiteTraindate {
    margin-left: 2.6%;
    font-weight: bold;
  }

  .frmRegChkMrLeft {
    margin-left: -1%;
  }

  .assignReg-popup-pos {
    width: 77% !important;
    /* padding-top: 6.5%; */
    margin-left: 10.8% !important;
  }

  .AddlDetailsRegDivWidth {
    width: 106%;
    max-width: 105%;
  }

  .wrdLmtRegAddlNoErr {
    margin-top: -2.5%;
    margin-right: 0.8%;
  }

  .wrdLmtRegAddlErr {
    margin-top: -3%;
    margin-right: -1%;
  }

  .addlDetailsRegStyle {
    margin-top: -3.2%;
    margin-left: -1.2%;
  }

  .deanonymise-popup-pos {
    width: 44% !important;
    /* padding-top: 10%; */
    margin-left: 27% !important;
  }

  .siteMngPopUpchk {
    margin-left: 0.5%;
    margin-top: -1%;
  }
}

@media screen and (min-width: 1400px) {
  .deanonymiseErr {
    margin-top: -0.5%;
  }

  .deanonymisedetailserr {
    margin-left: -1%;
    margin-top: 3%;
  }

  .ddlCTYManageReg {
    width: 96.3%;
    margin-left: 2px;
  }

  .ddlPUManageReg {
    width: 96.5%;
    margin-left: 2px;
  }

  .regEditIcon {
    position: fixed;
    margin-left: 2%;
  }

  .regDelIcon {
    position: fixed;
    margin-left: 4%;
  }

  .gridMgReg {
    padding-right: 0% !important;
    height: 525px;
  }

  .deanonymiseEmail-lefthead {
    margin-left: -27% !important;
    width: 61% !important;
    font-size: 12px !important;
  }

  .deanonymiseName-lefthead {
    margin-left: -26% !important;
    width: 33% !important;
    font-size: 12px !important;
  }

  .deanonymiseEmail-leftcell {
    margin-left: -27% !important;
    width: 72% !important;
    font-size: 12px !important;
  }

  .deanonymiseName-leftcell {
    margin-left: -26% !important;
    width: 72% !important;
    font-size: 12px !important;
  }

  .assignSiteMgEmail-lefthead {
    margin-left: -27% !important;
    width: 61% !important;
    font-size: 12px !important;
  }

  .assignSiteMgName-lefthead {
    margin-left: -26% !important;
    width: 33% !important;
    font-size: 12px !important;
  }

  .assignSiteMgEmail-leftcell {
    margin-left: -27% !important;
    width: 72% !important;
    font-size: 12px !important;
  }

  .assignSiteMgName-leftcell {
    margin-left: -26% !important;
    width: 72% !important;
    font-size: 12px !important;
  }

  .trngRegchkbx-lefthead {
    width: 8% !important;
    font-size: 12px !important;
  }

  .trngRegchkbx-leftcell {
    margin-left: -0.7% !important;
    width: 8% !important;
    font-size: 12px;
  }

  .gridHtWdassignTaskMgReg {
    height: 572px;
    width: 103.3%;
    padding-left: 0.6rem !important;
    margin-left: -1.8%;
  }

  .manageRegASMDEANONYMISEOverlay {
    padding-top: 6%;
  }

  .headRegTraindate {
    margin-left: -2.7%;
    font-weight: bold;
  }

  .headRegTraindateDean {
    margin-left: -2.2%;
    font-weight: bold;
  }

  .headRegAssignSiteTraindate {
    margin-left: 1.4%;
    font-weight: bold;
  }

  .frmRegChkMrLeft {
    margin-left: -1%;
  }

  .assignReg-popup-pos {
    width: 65% !important;
    /* padding-top: 13%; */
    margin-left: 18% !important;
  }

  .AddlDetailsRegDivWidth {
    width: 103%;
    max-width: 103%;
  }

  .wrdLmtRegAddlNoErr {
    margin-top: -2.5%;
    margin-right: 0.3%;
  }

  .wrdLmtRegAddlErr {
    margin-top: -2.4%;
    margin-right: 0.5%;
  }

  .addlDetailsRegStyle {
    margin-top: -2.5%;
    margin-left: -1;
  }

  .deanonymise-popup-pos {
    width: 42% !important;
    /* padding-top: 15%; */
    margin-left: 28.5% !important;
  }

  .siteMngPopUpchk {
    margin-left: 0.5%;
    margin-top: -1%;
  }
}

.edit-delete-dropdown-region {
  left: -35px !important;
}
