.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    -webkit-animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

.mainpage {
  margin-left: 35%;
  margin-right: 40%;
  margin-top: 10%;
  text-align: center;
}

.modal-header {
  background-color: #e8e8e8;
  padding: 5px !important;
}



.modalheaderassign {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: end;
}

@keyframes pop-animate {
  0% {
    transform: scale(0) rotate(0deg);
  }

  60% {
    transform: scale(0.8) rotate(0deg);
  }

  100% {
    transform: scale(0.8) rotate(0deg);
  }

  150% {
    transform: scale(1) rotate(0deg);
  }
}

.visible {
  display: block;
}

.visible>.modal-content {
  display: block;
  transform: scale(1);
}

@-webkit-keyframes scale {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  150% {
    opacity: 1;
    -webkit-transform: scale(0.67);
  }
}

@-moz-keyframes scale {
  0% {
    opacity: 0;
    -moz-transform: scale(0.67);
  }

  100% {
    opacity: 1;
    -moz-transform: tscale(1);
  }

  150% {
    opacity: 1;
    -webkit-transform: scale(0.67);
  }
}