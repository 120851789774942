.gridHtWd {
  height: 630px;
  width: 100%;
}

.right-border {
  border-right: #f8f8f8 solid 1px !important;
}

.rowDataInput {
  position: absolute;
  bottom: 1.5%;
  right: 450px;
  z-index: 1;
  background-color: #fff;
}

.ag-theme-material .ag-cell {
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  font-family: "Roboto-Light" !important;
  color: #111 !important;
  white-space: nowrap;
}

/* Remove right border next to the filter icon in a pinned left column */
.ag-theme-material .ag-pinned-left-header {
  border-right: none !important;
}

.ag-header-container .ag-header-cell-text,
.ag-theme-material .ag-pinned-left-header .ag-header-cell-label {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  color: #111111;
  text-align: center !important;
  white-space: nowrap;
}

/* Custom class for centering the header text */
.ag-theme-material .ag-center-header .ag-header-cell-label {
  justify-content: center;
  display: flex;
}

.ag-cell-focus,
.ag-cell-range-selected {
  border: 1px solid #fff !important;
  /* Light grey color for the border */
}

/* Remove cell highlight in AG Grid */
.ag-cell-focus .ag-cell-inner {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.ag-theme-material .ag-cell.ag-cell-range-selected {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.ag-cell-range-selected .ag-cell-inner {
  background-color: transparent !important;
}

.hide-lg {
  display: block !important;
}

.ag-root.ag-layout-normal,
.ag-root.ag-layout-auto-height {
  overflow: auto !important;
  flex: 1 1 auto;
  width: 0;
}

.ag-header-container .ag-header-cell-label {
  padding-left: 10px;
  padding-top: 5px;
}

.ag-paging-panel .ag-unselectable {
  border: none;
}

.ag-theme-alpine .ag-root-wrapper {
  border: 1px solid #6868680f;
}

.ag-body-viewport .ag-layout-normal {
  overflow-y: auto;
}

.ag-icon-asc {
  font-family: "agGridMaterial" !important;
}

.ag-icon-menu {
  font-family: "agGridMaterial" !important;
}

.ag-sort-order {
  display: none;
}

.ag-header-icon {
  font-family: "agGridMaterial" !important;
}

.ag-icon {
  font-family: "agGridMaterial" !important;
}

.ag-header-select-all {
  margin-right: 4px !important;
}

.ag-theme-material .ag-paging-panel {
  border-top: none !important;
  font-family: "Roboto-Light" !important;
  font-size: 14px;
  color: #111;
}

/* Custom CSS for row hover color */
.ag-theme-material .ag-row-hover {
  background-color: #fff !important;
}

@media (min-width: 1199px) {
  .hide-lg {
    display: none !important;
  }
}

@media (min-width: 1199px) {
  .hide-lg {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .ag-header-cell.md-hide-header {
    display: none;
  }

  .ag-cell.md-hide-cell {
    display: none;
  }

  .ag-theme-material .ag-cell {
    white-space: nowrap;
  }
}

@media (max-width: 767px) {
  .ag-theme-material .ag-cell {
    font-size: 13px;
    white-space: nowrap;
  }

  .ag-header-container .ag-header-cell-text,
  .ag-theme-material .ag-pinned-left-header .ag-header-cell-label {
    font-size: 13px;
    white-space: nowrap;
  }

  .ag-theme-material .ag-paging-panel {
    font-size: 10px;
  }

  .ag-header-cell.sm-hide-header {
    display: none;
  }

  .ag-cell.sm-hide-cell {
    display: none;
  }

  .rowDataInput {
    display: none;
  }
}