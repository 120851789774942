.date-label {
  display: contents;
  color: #686868;
}

.info-boxR {
  width: 290px;
  height: auto;
  /* margin-left: 62%; */
  /* margin-top: -9%; */
  padding: 10px 10px 16px 15px;
  border-radius: 5px;
  box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
  border: solid 1px #4fc3f7;
  background-color: #ecfafe;
  color: #111;
  z-index: 3;
  position: absolute;
}

.btn-brand-primary-c:hover {
  color: #fff !important;
}

.info-headingR {
  font-size: 16px;
  font-weight: 900;
}

.info-textR {
  box-sizing: border-box;
  font-size: 14px;
  white-space: pre-wrap;
  font-weight: 400;
  color: #111;
}

.info-textR>p {
  box-sizing: border-box;
  font-size: 14px;
  /* font-weight: 400; */
  color: #111;
}

.favChckBox {
  opacity: 1;
  display: flex;
  align-items: baseline;
  margin-left: 1%;
  margin-top: 10px;
}

.date-border {
  border: 1px solid red !important;
}

/* .date-border span {
  border-left: 1px solid red !important;
} */
.startDate {
  display: flex;
  max-width: 239px;
  width: 100%;
  padding: 6px;
  /* white-space: nowrap; */
  height: 41px;
  border: solid 1px #dedede;
}

.dateicon_item {
  align-self: start;
}

.datepicker_item {
  align-self: end;
  margin-left: 4px;
  margin-right: -2.5rem;
}

.reportdate-flexcontainer {
  display: flex;
  flex-direction: column;
}

.datePick-report {
  border: none !important;
  outline: none;
  z-index: 1001 !important;
}

.ddlReport {
  /* width: 530px; */
  width: 32vw;
  height: 46px;
  overflow: hidden;
  border: solid 1px #dedede !important;
  background: none !important;
  color: #686868 !important;
  border-radius: 0px !important;
  display: inline-block;
}

.widthddl-report {
  width: 32vw;
}

.favreportname {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #111 !important;
  float: left !important;
  text-align: left;
  width: 86%;
}

.input-favrep {
  width: 27.7vw;
  height: 42.5px;
  border: 1px solid #dedede;
  padding-left: 10px;
}

.savemyreport {
  color: #686868;
  margin-bottom: 9px;
}

.errMsgOopsReport {
  font-size: 11px;
  color: #ff7591 !important;
  text-decoration: none !important;
  margin-top: 1%;
  margin-left: -2.2%;
}

.gridHtWdOTFActivityGrid {
  height: 525px;
  width: 84%;
  /* padding-left: 0.6rem !important;
  margin-left: -2%; */
}

.gridHeaderStyleLabel {
  width: 20% !important;
  left: 10% !important;
}

.gridHeaderStyleActdesc {
  width: 50% !important;
  left: 30% !important;
}

.gridHeaderStyleBoxSize {
  width: 20% !important;
  left: 80% !important;
}

@media screen and (min-width: 1400px) {
  .overlayheadingOTFInfo {
    color: white;
    font-size: 36px;
    font-weight: bold;
    position: absolute;
    left: 0;
    margin-top: -4.5%;
  }

  .overlayheadingOTFInfo {
    position: relative;
    top: 0;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1198px) {
  .overlayheadingOTFInfo {
    position: relative;
    top: -5px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1199px) {
  .overlayheadingOTFInfo {
    color: white;
    font-size: 35px;
    font-weight: bold;
    position: absolute;
    left: 0;
    margin-top: -6.5%;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1199px) {
  .overlayheadingOTFInfo {
    color: white;
    font-size: 35px;
    font-weight: bold;
    position: absolute;
    left: 0;
    margin-top: -11.5%;
  }

  .overlayheadingOTFInfo {
    position: relative;
    top: 4px;
  }
}

.tblInfo td {
  border: none;
  color: #686868 !important;
}

@media only screen and (min-width: 1000px) {
  .postionInfoOTF {
    bottom: 25%;
    margin-left: 12px;
    width: 298px !important;
    margin: 8px 0px 0px 10px;
  }
}

@media only screen and (max-width: 999px) {
  .postionInfoOTF {
    bottom: 100%;
    right: -84%;
    margin-left: 12px;
    width: 298px !important;
    margin: 8px 0px 0px 10px;
  }

  .overlayheadingOTFInfo {
    position: relative;
    top: 0;
  }
}

.infoContentPadding {
  padding-left: 20px;
}

.boxsizeHeading {
  font-size: 14px;
  font-weight: bold;
  color: #686868;
  width: 100px;
}

.textLenHeading {
  font-weight: bold;
  font-size: 14px;
  color: #686868;
  width: 100px;
}

.info-headingOTF {
  font-size: 16px;
  font-weight: bold;
  color: #111 !important;
  margin-left: 6px;
}

.txtinfo {
  font-weight: 300;
  font-size: 14px;
  color: #686868;
}

.taskRetInfoIconOTF {
  margin-left: -10% !important;
  margin-top: 5% !important;
  color: #218dcc !important;
  cursor: pointer;
}

.reportsdatevalid {
  margin-top: 5.5%;
  margin-left: -5.5%;
}

.runbuttonreport {
  font-size: 16px !important;
  color: #fff;
  background-color: #007f00;
}

.removebuttonreport {
  font-size: 16px !important;
  background-color: white !important;
  color: rgb(17, 17, 17) !important;
}

.removebuttonreport:hover {
  background-color: white !important;
  color: #007f00 !important;
}

.favNameTopMargin {
  margin-top: -2.8% !important;
}

.chkBoxReport {
  cursor: pointer;
}

#saveiconreports svg g:hover {
  /* fill: #007f00; */
  stroke: #007f00;
}

.multiselectEmpdll {
  width: 32vw;
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .favNameTopMargin {
    margin-top: -3.8% !important;
  }

  .errMsgOopsReport {
    margin-left: -3.3%;
  }

  /* .multiselectEmpdll {
    width: 29vw;
  } */
}

@media screen and (max-width: 1199px) {
  .favNameTopMargin {
    margin-top: -10.5% !important;
  }

  .errMsgOopsReport {
    margin-left: -4.4%;
  }

  /* .multiselectEmpdll {
    width: 29vw;
  } */
}

.myfavinfo-rep {
  display: inline-block;
}

.myfavinfodesc-rep {
  display: inline-block;
}

.myfav-flexcontainer {
  display: flex;
  width: 90%;
}

@media screen and (max-width: 1199px) {
  .marginMyFavIcon {
    margin-left: -29%;
  }

  .floatRightReports {
    position: relative;
    right: -6%;
    top: 20%;
    float: right;
    vertical-align: middle;
  }
}

@media screen and (min-width: 1200px) {
  .floatRightReports {
    position: relative;
    /* right: 8%; */
    top: 10%;
    float: right;
    vertical-align: middle;
  }
}

.css-tj5bde-Svg {
  height: 17px;
}

@media screen and (min-width: 1000px) {
  .res-margin {
    margin-left: -12px;
  }
}

.reportnameflexcontainer {
  display: flex;
}


@media (max-width: 1000px) {
  .reportsdropdownitem {
    flex: 0 0 81vw;
  }
}

@media (min-width: 1001px) {
  .reportsdropdownitem {
    flex: 0 0 32vw;
  }
}

.reportinfoiconitem {
  align-self: center;
}

.reportddlsinglelabel {
  cursor: pointer;
}

.rep-drop-icon {
  position: relative;
  right: 0%;
  top: 20%;
}

.btn-otf {
  border: 1px solid green !important;
  color: #111 !important;
  background-color: white !important;
  font-weight: 600;
}

.btn-otf:hover {
  border: 1px solid green !important;
}