.pageHeader {
  background-color: #e8e8e8;
  margin-top: -8px;
}

.pageHeader .home {
  height: 14px;
  margin-top: 4px;
}

.pageHeader .separator {
  height: 14px;
  margin-top: 4px;
}

.gridCrudIcons {
  padding-right: 10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.gridcenter {
  padding-left: 30%;
}

.gridCrudIcons img {
  cursor: pointer;
  margin-right: 10px;
}

.notifications {
  position: relative;
  bottom: 25px;
  text-align: center;
}

.pageSize {
  width: 80px;
}

.notifications {
  position: absolute;
  text-align: center;
  top: 0;
}

.pagination {
  top: 0%;
}

.managenewsfeed-headerlabel {
  width: 417px;
  height: 21px;
  margin: 0px 1202.5px 0 0;
  /* font-family: Univers; */
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 0.58; */
  letter-spacing: normal;
  color: #111;
}

.managenewsfeed-createbutton {
  margin: 63px 1202.5px 0 0;
}


.newsFeedLabel:hover {
  color: green !important;
}

.gridManageNewsFeed {
  height: 55.5vh;
  width: 85.5%;
}

.manageNewsFeedCreateEdit {
  width: 80%;
  margin-left: 10% !important;
  padding-top: 12%;
}

.manageNewsFeedCreateEditHeading {
  font-weight: bold;
  margin-left: 2px !important;
}

@media screen and (max-width: 1099px) {
  .gridManageNewsFeed {
    height: 530px;
    width: 100%;
  }

  .gridHtWdmanagethreat {
    height: 530px;
    width: 100%;
    /* padding-top: 5%;
      padding-bottom: 3%;
      margin-left: 1.2%; */
  }

  .gridThreatDescStyle {
    width: 80% !important;
  }

  .gridThreatActStyle {
    width: 21% !important;
    margin-left: 30%;
  }

  .manageNewsFeedCreateEdit {
    width: 80%;
    margin-left: 10% !important;
    padding-top: 0%;
  }

  .manageNewsFeedCreateEditHeading {
    font-weight: bold;
    margin-left: -7px !important;
  }


}

@media screen and (min-width: 990px) and (max-width: 1099px) {
  .gridManageNewsFeed {
    height: 530px;
    width: 150%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1099px) {
  .headlinehead {
    margin-left: -1% !important;
    width: 61.8% !important;
    font-size: 12px !important;
  }

  .headlinecell {
    margin-left: -0.7% !important;
    width: 59% !important;
    font-size: 12px !important;
  }

  .priorityhead {
    margin-left: -19.5% !important;
    width: 18.8% !important;
    font-size: 12px !important;
  }

  .prioritycell {
    margin-left: -21% !important;
    width: 19.5% !important;
    font-size: 12px !important;
  }

  .newsEditDelhead {
    margin-left: -9.5% !important;
    width: 22% !important;
    font-size: 12px !important;
  }

  .newsEditDelcell {
    margin-left: -10% !important;
    width: 24% !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 999px) {
  .headlinehead {
    margin-left: -1% !important;
    width: 68.8% !important;
    font-size: 12px !important;
  }

  .headlinecell {
    margin-left: -0.7% !important;
    width: 59% !important;
    font-size: 12px !important;
  }

  .priorityhead {
    margin-left: 0 !important;
    width: 15.8% !important;
    font-size: 12px !important;
  }

  .prioritycell {
    margin-left: 0 !important;
    width: 15.8% !important;
    font-size: 12px !important;
  }

  .newsEditDelhead {
    margin-left: 0 !important;
    width: 17% !important;
    font-size: 12px !important;
  }

  .newsEditDelcell {
    margin-left: 0 !important;
    width: 17% !important;
    font-size: 12px !important;
  }

  /* .gridManageNewsFeed {
      height: 530px;
      width: 100%;
    } */
}

@media screen and (min-width: 1100px) and (max-width: 1399px) {
  .gridManageNewsFeed {
    height: 520px;
    width: 130%;
  }

  .gridHtWdmanagethreat {
    height: 520px;
    width: 100%;
    /* padding-top: 5%;
      padding-bottom: 3%;
      margin-left: 1.2%; */
  }

  .gridThreatDescStyle {
    width: 80% !important;
  }

  .gridThreatActStyle {
    width: 24% !important;
    margin-left: 30%;
  }

  .headlinehead {
    margin-left: -0.8% !important;
    width: 58% !important;
    font-size: 12px !important;
  }

  .headlinecell {
    margin-left: -0.8% !important;
    width: 65% !important;
    font-size: 12px !important;
  }

  .priorityhead {
    margin-left: -2.8% !important;
    width: 20% !important;
    font-size: 12px !important;
  }

  .prioritycell {
    margin-left: -4.8% !important;
    width: 15% !important;
    font-size: 12px !important;
  }

  .newsEditDelhead {
    margin-left: 1.2% !important;
    width: 25% !important;
    font-size: 12px !important;
  }

  .newsEditDelcell {
    margin-left: 2.2% !important;
    width: 25% !important;
    font-size: 12px !important;
  }

  .manageNewsFeedCreateEdit {
    width: 80%;
    margin-left: 10% !important;
    padding-top: 0%;
  }

  .manageNewsFeedCreateEditHeading {
    font-weight: bold;
    margin-left: 0px !important;
  }
}

@media screen and (min-width: 1400px) {
  .gridManageNewsFeed {
    height: 520px;
    width: 130%;
  }

  .gridHtWdmanagethreat {
    height: 520px;
    width: 100%;
    /* padding-top: 5%;
      padding-bottom: 3%;
      margin-left: 1.2%; */
  }

  .gridThreatDescStyle {
    width: 82% !important;
  }

  .gridThreatActStyle {
    width: 18% !important;
    margin-left: 32%;
  }

  .headlinehead {
    margin-left: 0% !important;
    width: 70% !important;
    font-size: 12px !important;
  }

  .headlinecell {
    margin-left: 0.2% !important;
    width: 54% !important;
    font-size: 12px !important;
  }

  .priorityhead {
    margin-left: 29.2% !important;
    width: 12.5% !important;
    font-size: 12px !important;
  }

  .prioritycell {
    margin-left: 27.5% !important;
    width: 12% !important;
    font-size: 12px !important;
  }

  .newsEditDelhead {
    margin-left: 31% !important;
    width: 18% !important;
    font-size: 12px !important;
  }

  .newsEditDelcell {
    margin-left: 31.5% !important;
    width: 14% !important;
    font-size: 12px !important;
  }

  .manageNewsFeedCreateEdit {
    width: 80%;
    margin-left: 10% !important;
    padding-top: 7%;
  }

  .manageNewsFeedCreateEditHeading {
    font-weight: bold;
    margin-left: 6px !important;
  }
}