.grid-container {
  display: grid;
  grid-template-columns: 45% 45%;
  grid-gap: 13px 12px;
  padding: 13px 9px;
}

.icon-margin {
  margin-top: 7px;
}

.errMsgCAS {
  font-size: 11px !important;
  color: #ff7591 !important;
  margin-bottom: -20px;
  text-decoration: none !important;
}

.grid-container>div {
  text-align: left;
  padding: 0% 1.3%;
  font-size: 16px;
  color: #686868;
}

.grid-container2 {
  display: grid;
  grid-template-columns: 82% 65%;
  padding: 2px;
}

.textInputarea {
  border: 1px solid #e8e5e5;
  padding: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.question_DescriptionUPK {
  width: 98%;
  height: 131px;
  padding: 1.5%;
  border: solid 1px #dedede;
}

.answeroptionddlWidthinPer {
  width: 20% !important;
}

.question_Description {
  width: 100%;
  height: 135px;
  border: solid 1px #dedede;
}

.question_DescriptionCAS {
  width: 102.5%;
  height: 127px;
  border: solid 1px #dedede;
}

.question_DescriptionDAT {
  width: 101%;
  height: 127px;
  border: solid 1px #dedede;
}

/* .dropdown-menu {
  width: 380px !important;
} */

.widthddl {
  width: 100% !important;
}

.widthddlinPercent {
  width: 97% !important;
}

.widthddlinPercentCAS {
  width: 97% !important;
}

.paddingTop {
  padding-top: 15px;
}

.topMargin {
  /* margin-top: 3px !important; */
}

.answerOptions {
  width: 98% !important;
  height: 65px !important;
  border: solid 1px #dedede;
}

.answerOptionsDATCompliance {
  width: 98.5% !important;
  height: 65px !important;
  border: solid 1px #dedede;
}

.answerOptionsDATNonCompliance {
  width: 99% !important;
  height: 65px !important;
  border: solid 1px #dedede;
}

.bckDisable {
  background-color: hsl(0, 0%, 95%) !important;
}

.ddlValue {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  color: #111 !important;
  float: left !important;
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 85%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}

.marginLeft {
  margin-left: 25px !important;
}

.imgbBox1 {
  height: 300px;
  margin-top: -10% !important;
  width: 100%;
  max-width: 120%;
}

/* .dropdown-menu-scroll {
  overflow-x: hidden;
} */

.ddlRequired {
  width: 320px;
  height: 35px;
  margin-top: -4px;
  margin-left: -15px;
}

.redBorder {
  border: 1px solid red !important;
}

.imgbBox {
  height: 300px;
  background-color: #d8d8d8;
  margin-top: 2% !important;
  width: 104%;
  max-width: 120%;
}

.imgbBoxCAS {
  height: 300px;
  background-color: #d8d8d8;
  margin-top: 2% !important;
  width: 105.3%;
  max-width: 120%;
}

.imgbBoxDAT {
  max-width: 115.5% !important;
  width: 110% !important;
}

.imgbBoxUPK {
  max-width: 103% !important;
  width: 103% !important;
  height: 300px;
  background-color: #d8d8d8;
}

@media screen and (max-width: 991px) {
  .imgCASDiv {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .img-remove {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .imgCASDiv {
    display: none;
  }

  .imgbBoxUPK {
    max-width: 110% !important;
    width: 108.3% !important;
    height: 300px;
    background-color: #d8d8d8;
  }

  .mrdesc {
    margin-left: -3% !important;
    margin-top: -1.8% !important;
    width: 85%;
  }

  .qsErrDivUPK {
    margin-top: 0%;
    margin-right: 6%;
  }

  .OptCErrlbl {
    margin-left: 4%;
  }

  .OptDErrlbl {
    margin-left: 4%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .imgbBoxUPK {
    max-width: 107% !important;
    width: 107% !important;
    height: 300px;
    background-color: #d8d8d8;
  }
}

@media screen and (min-width: 1200px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .floatRight {
    position: relative;
    right: -2%;
    top: 20%;
    float: right;
  }

  .qsErrDivUPK {
    margin-top: 1%;
    margin-right: 4%;
  }

  .UPKModel2Err {
    font-size: 11px;
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-top: -4.5% !important;
  }

  .modelAns2UPKWordLmtErrDiv1 {
    margin-top: -4.2% !important;
    margin-left: 94% !important;
  }

  .errMsgUPKOptADiv {
    margin-left: 0.4%;
  }

  .errMsgUPKOptBDiv {
    margin-left: 0.4%;
  }

  .errMsgUPKOptCDiv {
    margin-left: 0.4%;
  }

  .errMsgUPKOptDDiv {
    margin-left: 0.4%;
  }

  .OptCErrlbl {
    margin-left: 0.5%;
  }

  .OptDErrlbl {
    margin-left: 0.5%;
  }

  .optCWrdLmtErrMrTop {
    margin-top: 0% !important;
    margin-right: -1% !important;
  }

  .optDWrdLmtErrMrTop {
    margin-top: -0.2% !important;
    margin-right: -1% !important;
  }

  .modelAns2Div {
    margin-left: 0%;
    width: 100%;
  }

  .floatRight1 {
    margin: 2%;
    position: absolute;
    right: 5%;
    top: 15%;
  }

  .floatRight2 {
    position: relative;
    right: -2%;
    float: right;
    top: 8%;
  }

  .moveRight1 {
    right: 0%;
  }

  .moveRight1DrvCom {
    right: -4.5%;
  }
}

@media screen and (max-width: 1199px) and (min-width: 800px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .floatRight {
    position: absolute;
    right: 8%;
    /* top: 30%; */
    vertical-align: middle;
  }

  .floatRight2 {
    position: relative;
    right: -5%;
    top: 0%;
    float: right;
    vertical-align: middle;
  }

  .errMsgUPKOptADiv {
    margin-left: 4.9%;
  }

  .errMsgUPKOptBDiv {
    margin-left: 4.9%;
  }

  .errMsgUPKOptCDiv {
    margin-left: 4.9%;
  }

  .errMsgUPKOptDDiv {
    margin-left: 4.9%;
  }

  .floatRight1 {
    margin: 2%;
    position: relative;
    right: -18px;
    float: right;
    top: 0%;
    width: 10%;
  }

  .moveRight1 {
    right: 0%;
  }

  .moveRight1DrvCom {
    right: 0%;
  }
}

@media screen and (max-width: 800px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .floatRight {
    margin: -35px 2%;
    position: relative;
    right: -37%;
    float: right;
  }

  .floatRight1 {
    margin: 1%;
    position: absolute;
    right: 4%;
    top: 12%;
  }

  .moveRight1 {
    right: -12%;
  }

  .moveRight1DrvCom {
    right: 3%;
  }
}

.paddingLeftUPK {
  padding-left: 15px;
}

.modalFooterUPKYes {
  margin-bottom: -92px !important;
}

.modalFooterUPKNo {
  margin-bottom: -65px !important;
}

.mcqEmpty {
  margin-left: -206px;
}

.mcqValue {
  margin-left: -15px;
}

.bckDisable {
  pointer-events: none;
  background-color: hsl(0, 0%, 95%) !important;
}

.displayHiden {
  display: none;
}

.mcqLeftMarging {
  margin-left: -206px;
}

.btnDisabled {
  pointer-events: none;
}

.paddTopUPK {
  padding-top: 3.2%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .paddTopUPK {
    padding-top: 10%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .paddTopUPK {
    padding-top: 8.5%;
  }
}

.paddTopDAT {
  padding-top: 3.5%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .paddTopDAT {
    padding-top: 8%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .paddTopDAT {
    padding-top: 9%;
  }
}

.disableYScroll {
  overflow-x: scroll !important;
}

.pddLeftDATCompliance {
  padding-left: 3%;
  margin-bottom: 20px;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .pddLeftDATCompliance {
    padding-left: 8.6%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .pddLeftDATCompliance {
    padding-left: 6%;
  }
}

.ComplianceWordLimitNoErrStyle {
  margin-top: -2.5%;
  margin-right: -0.6%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .ComplianceWordLimitNoErrStyle {
    margin-top: 0.5%;
    margin-right: 2.5%;
  }

  .pddLeftDATNonCompliance {
    padding-left: 8% !important;
  }

  .lengthCompliance {
    margin-top: 0% !important;
  }

  .NonComplianceWordLimitNoErrStyle {
    margin-top: 0.6%;
    margin-right: -4.2%;
  }

  .DATNonComplianceDiv {
    margin-top: 9%;
    margin-left: 1%;
  }

  .mrTopDrvDAT {
    margin-top: -6%;
  }

  .ddlDrvComMrTop {
    top: -13%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .ComplianceWordLimitNoErrStyle {
    margin-top: -4.5%;
  }
}

.ComplianceWordLimitErrStyle {
  margin-top: -4.3%;
  margin-right: 1.8%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .ComplianceWordLimitErrStyle {
    margin-top: -3.2%;
    margin-right: 2.8% !important;
  }

  .noncomerroop {
    margin-top: -0.2% !important;
    font-size: 11px !important;
    margin-left: 2.2%;
  }

  .errMsgMdAnsUPK {
    margin-left: 0.8% !important;
    width: 80%;
    margin-top: -2% !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .ComplianceWordLimitErrStyle {
    margin-top: -3.5%;
  }

  .NonComplianceWordLimitErrStyle {
    margin-top: -6.5% !important;
    margin-right: -4% !important;
  }

  .NonComplianceTxtBoxStyle {
    margin-top: -11.5%;
  }

  .noncomerroop {
    margin-top: -5%;
    font-size: 11px !important;
  }

  .mrdesc {
    margin-left: -2% !important;
    margin-top: -2% !important;
  }
}

.ComplianceWordLimitNoErrDivStyle {
  margin-left: -3.5%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .ComplianceWordLimitNoErrDivStyle {
    margin-left: -0.5%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .ComplianceWordLimitNoErrDivStyle {
    margin-left: -1%;
  }
}

.ComplianceWordLimitErrDivStyle {
  margin-right: 1.5%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .ComplianceWordLimitErrDivStyle {
    margin-right: 0%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .ComplianceWordLimitErrDivStyle {
    margin-right: 1%;
  }
}

.ComplianceErrMsg {
  margin-left: 8.5%;
  margin-top: -2%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .ComplianceErrMsg {
    margin-left: 16%;
    margin-top: -5%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .ComplianceErrMsg {
    margin-left: 13%;
    margin-top: -3%;
  }
}

.pddLeftDATNonCompliance {
  padding-left: 3%;
}

.pddLeftDATCompliance {
  padding-left: 3%;
  margin-bottom: 20px;
}

.ComplianceTxtBoxStyle {
  margin-left: -10px;
  width: 98.5% !important;
  height: 65px !important;
  border: solid 1px #dedede;
  margin-top: -45px;
}

.errMsgQ {
  font-size: 11px !important;
  color: #ff7591 !important;
  text-decoration: none !important;
}

@media screen and (min-width: 1400px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -23.3% !important;
    margin-left: 0% !important;
  }

  .mcqPanelPosition1 {
    margin-top: -1.4% !important;
    margin-left: 0% !important;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .ComplianceTxtBoxStyle {
    width: 96.5% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-left: -1.7%;
    margin-top: -6.4%;
  }

  .errMsgUPKDesc {
    font-size: 11px;
    color: #ff7591 !important;
    text-decoration: none !important;
  }

  .errMsgUPKDesc {
    margin-left: 0% !important;
    width: 80% !important;
  }

  .upkWordLmtErr {
    margin-right: 3.2% !important;
    margin-top: -1% !important;
  }

  .upkABWordLmtErr {
    margin-right: 2.8% !important;
    margin-top: -1% !important;
  }

  .errMsgUPKOptBDiv {
    margin-left: 1.5% !important;
  }

  .errMsgUPKOptCDiv {
    margin-left: 1% !important;
  }

  .errMsgUPK {
    font-size: 11px;
    color: #ff7591 !important;
    /* margin-left: 1% !important; */
    text-decoration: none !important;
  }

  .errMsgUPKOptADiv {
    margin-left: 1.5% !important;
  }

  .errMsgUPKOptDDiv {
    margin-left: 1% !important;
  }

  .errMsgMdAnsUPK {
    font-size: 11px;
    color: #ff7591 !important;
    margin-left: 0.5% !important;
    text-decoration: none !important;
  }

  .optCWrdLmtMrTop {
    margin-top: 1.2% !important;
    margin-right: -1% !important;
  }

  .upkWordLmtDNoErr {
    margin-right: 2.2% !important;
    margin-top: 3px !important;
  }

  .optDWrdLmtMrTop {
    margin-top: -0.1% !important;
    margin-right: 0.1% !important;
  }

  .upkWordLmtNoErr {
    margin-right: 2.8% !important;
    margin-top: 3px !important;
  }

  .pddLeftUPK {
    padding-left: 2.6% !important;
  }

  .ComplianceWordLimitErrStyle {
    margin-top: -5.6%;
    margin-right: 2.4%;
  }

  .ComplianceWordLimitNoErrDivStyle {
    margin-left: -4.5%;
  }

  .CASOptWrdLmtErr {
    margin-top: -1.6% !important;
  }

  .paddTopCAS {
    padding-top: 3% !important;
  }

  .questnCASMrTopErr {
    margin-top: 3% !important;
    margin-right: -4% !important;
  }

  .qsErrCAS {
    margin-left: 0.4% !important;
    width: 82%;
  }

  .questnMrTopNoErr {
    margin-top: 0.5% !important;
    margin-right: 0.5% !important;
  }

  .questnUPKMrTopNoErr {
    margin-top: -0.5% !important;
    margin-right: -0.4% !important;
  }

  .questnCASMrTopNoErr {
    margin-top: 0.5% !important;
    margin-right: -0.5% !important;
  }

  .modelAns2UPKWordLmt1 {
    margin-top: -4% !important;
    margin-right: -9.5% !important;
  }

  .modelAns2Div {
    margin-left: 1.4% !important;
    width: 96.8% !important;
  }

  .mcqOptionsEnable {
    margin-left: -1% !important;
  }

  .modelAns1UPKWordLmt {
    margin-top: 0.5% !important;
    margin-right: -6.8% !important;
  }

  .CASOptWrdLmt {
    margin-top: -0.5% !important;
  }

  .answerOptions {
    width: 98% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-bottom: 0.5%;
  }

  .ComplianceWordLimitNoErrStyle {
    margin-top: -3.8% !important;
    margin-right: -0.6% !important;
  }

  .reqDAT {
    margin-top: -4.2% !important;
    margin-left: -1.6% !important;
    font-size: 11px !important;
  }

  .marginLeft {
    margin-left: 32px !important;
  }

  .pddLeftDATCompliance {
    padding-left: 2%;
  }

  .pddLeftDATNonCompliance {
    padding-left: 3.4%;
  }

  .NonComplianceTxtBoxStyle {
    margin-left: -1.8%;
    width: 99.7% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-top: -16.8%;
    max-width: 105%;
  }

  .NonComplianceWordLimitNoErrStyle {
    margin-top: -15.8%;
    margin-right: -0.3%;
  }

  .DrivingCompetencyErrMsg {
    margin-left: -1% !important;
    margin-top: 0% !important;
  }

  .paddTopDAT {
    padding-top: 3%;
  }

  .QDATDesc {
    margin-left: -2% !important;
    margin-top: -1% !important;
    width: 106% !important;
    max-width: 106% !important;
  }

  .NonComplianceWordLimitErrStyle {
    margin-top: -18.4%;
    margin-right: 0.6%;
  }

  .noncomerroop {
    margin-top: -16.2%;
    margin-left: -1.7%;
    font-size: 11px !important;
  }

  .Q-toastMsgs {
    top: 12% !important;
    left: -4%;
  }

  .pddLeft {
    padding-left: 2.7% !important;
  }
}

.rowMRLeft {
  margin-left: 2%;
}

.row1MRLeft {
  margin-left: 1%;
}

@media screen and (max-width: 1400px) and (min-width: 1100px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .rowMRLeft {
    margin-left: 1.6%;
  }

  .row1MRLeft {
    margin-left: 1.2%;
  }

  .marginLeft {
    margin-left: 33px !important;
  }

  .modelAns1UPKWordLmt {
    margin-top: 0.5%;
    margin-right: -8.3% !important;
  }

  .questnUPKMrTopNoErr {
    margin-top: -0.6% !important;
    margin-right: 1% !important;
  }

  .upkMCQOptn {
    width: 98% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-left: -0.5% !important;
  }

  .modelAnswer1 {
    width: 103.5% !important;
    border: solid 1px #dedede;
    height: 75%;
  }

  .modelAns1UPKWordLmtErr {
    margin-top: -4.5% !important;
    margin-right: -4% !important;
  }

  .widthAnswer {
    width: 109.5% !important;
  }

  .ComplianceTxtBoxStyle {
    width: 96% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-left: -1.7%;
    margin-top: -6%;
  }

  .pddLeftDATCompliance {
    padding-left: 3.4%;
  }

  .pddLeftDATNonCompliance {
    padding-left: 4.3%;
  }

  .NonComplianceTxtBoxStyle {
    margin-left: -1.2%;
    width: 99.5% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-top: -15.5%;
    max-width: 105%;
  }

  .ComplianceWordLimitErrStyle {
    margin-top: -4.5%;
    margin-right: 2.4%;
  }

  .NonComplianceWordLimitNoErrStyle {
    margin-top: 0%;
    margin-right: -4%;
  }

  .NonComplianceWordLimitErrStyle {
    margin-top: -14%;
    margin-right: -0.3%;
  }

  .paddTopDAT {
    padding-top: 4.5%;
  }

  .noncomerroop {
    margin-top: -10.5%;
    margin-left: -0.7%;
    font-size: 11px !important;
  }

  .questnMrTopErr {
    margin-top: -4.2%;
  }

  .questnUPKMrTopErr {
    margin-top: -4.2%;
  }

  .questnCASMrTopErr {
    margin-top: -4.2%;
  }

  .mrLeft {
    margin-left: 2.8% !important;
  }

  .pddLeftUPK {
    padding-left: 4.7% !important;
  }

  .errMsgDAT {
    margin-left: 0.3% !important;
  }

  .errMsgMdAnsUPK {
    margin-left: -0.4% !important;
  }

  .upkmr13 {
    margin-left: 2.9% !important;
    width: 94.5% !important;
  }

  .modelAns2UPKWordLmtErrDiv {
    margin-top: -4.5% !important;
    margin-left: 93.8% !important;
  }

  .Q-toastMsgs {
    top: 20% !important;
    left: -3%;
  }
}

@media screen and (max-width: 1099px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .ComplianceTxtBoxStyle {
    width: 95.2% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-left: -1.3%;
    margin-top: 0.3%;
  }

  .pddLeftDATCompliance {
    padding-left: 6.5%;
  }

  .NonComplianceTxtBoxStyle {
    margin-left: 1.4%;
    width: 98.8% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-top: -2.5%;
    max-width: 105%;
  }

  .NonComplianceWordLimitErrStyle {
    margin-top: -3.5%;
    margin-right: -4%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
  .Q-toastMsgs {
    top: 17% !important;
    left: -3%;
  }
}

@media only screen and (max-width: 999px) {
  .Q-toastMsgs {
    top: 25% !important;
    left: 0%;
  }
}

/* Non-Compliance */

/* .NonComplianceTxtBoxStyle {
  margin-left: -1%;
  width: 102.2% !important;
  height: 65px !important;
  border: solid 1px #dedede;
  margin-top: -15%;
  max-width: 105%;
} */

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .NonComplianceTxtBoxStyle {
    width: 97.5% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-left: 1.8%;
    margin-top: -16.5%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .NonComplianceTxtBoxStyle {
    width: 98.5% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-left: 0.5%;
    margin-top: -14.5%;
  }
} */

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .NonComplianceWordLimitNoErrStyle {
    margin-top: -15%;
    margin-right: -2.5%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .NonComplianceWordLimitNoErrStyle {
    margin-top: -13.5%;
    margin-right: -2.5%;
  }
} */

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .NonComplianceWordLimitErrStyle {
    margin-top: -35%;
    margin-right: -5.5%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .NonComplianceWordLimitErrStyle {
    margin-top: -30.5%;
    margin-right: -2.5%;
  }
} */

.NonComplianceWordLimitNoErrDivStyle {
  margin-left: -1%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .NonComplianceWordLimitNoErrDivStyle {
    margin-left: -0.5%;
  }
}

.modelAns2UPKWordLmtErrDiv1 {
  margin-top: -4%;
  margin-left: 96%;
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .NonComplianceWordLimitNoErrDivStyle {
    margin-left: -1%;
  }
}

.NonComplianceErrMsg {
  margin-left: -0.5%;
  margin-top: -3%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .NonComplianceErrMsg {
    margin-left: 5%;
    margin-top: -8%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .NonComplianceErrMsg {
    margin-left: 3%;
    margin-top: -3%;
  }
}

.DrivingCompetencyErrMsg {
  margin-left: -1%;
  margin-top: 1%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .DrivingCompetencyErrMsg {
    margin-left: 3%;
    margin-top: 0%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .DrivingCompetencyErrMsg {
    margin-left: 2%;
    margin-top: 1%;
  }
}

/* UPK and CAS */

.mrLeft {
  margin-left: 1.6%;
  /* padding-left: 2.5% !important; */
}

.pddLeft {
  padding-left: 3.4%;
}

.pddLeftUPK {
  padding-left: 1.9%;
}

@media screen and (max-width: 900px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .pddLeft {
    padding-left: 5.8% !important;
  }

  .CASOptWrdLmtErr {
    margin-top: -3.5% !important;
    margin-right: -0.6% !important;
  }

  .errMsgDATmr {
    margin-left: -3% !important;
  }

  .questnMrTopErr {
    margin-top: -4% !important;
    margin-right: -12% !important;
  }

  .questnUPKMrTopErr {
    margin-top: -13% !important;
  }

  .questnCASMrTopErr {
    margin-top: -13% !important;
  }
}

@media screen and (max-width: 1099px) and (min-width: 901px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .marginLeft {
    margin-left: 35.6px !important;
  }

  .errMsgDAT {
    margin-left: 1% !important;
  }

  .errMsgMdAnsUPK {
    margin-left: 0.5% !important;
  }

  .marRighttremaining {
    margin-right: 4.2%;
    margin-top: -11%;
  }

  .CASOptWrdLmtErr {
    margin-top: -3.8%;
  }

  .questnMrTopErr {
    margin-top: -14%;
  }

  .questnUPKMrTopErr {
    margin-top: -14%;
  }

  .questnCASMrTopErr {
    margin-top: -14%;
  }

  .questnMrTopNoErr {
    margin-top: 11.5% !important;
    margin-right: -0%;
  }

  .questnUPKMrTopNoErr {
    margin-top: 11.5% !important;
    margin-right: -0%;
  }

  .questnCASMrTopNoErr {
    margin-top: 11.5% !important;
    margin-right: -0%;
  }

  .CASOptWrdLmtErr {
    margin-top: -2.5% !important;
  }

  .pddLeftDATCompliance {
    padding-left: 4.6% !important;
  }

  .ComplianceWordLimitNoErrStyle {
    margin-top: -2.1% !important;
  }

  .pddLeftDATNonCompliance {
    padding-left: 5.6% !important;
  }

  .NonComplianceWordLimitNoErrDivStyle {
    margin-top: -6% !important;
  }

  .NonComplianceWordLimitNoErrStyle {
    margin-right: -2%;
  }

  .upkmr13 {
    margin-left: 0.9% !important;
    width: 96.5% !important;
  }

  .modelAns2UPKWordLmtErrDiv {
    margin-top: -5.5% !important;
    margin-left: -102% !important;
  }

  .modelAns2UPKWordLmtErrDiv1 {
    margin-top: -4.4% !important;
    margin-left: 93% !important;
  }
}

.optionMargin {
  margin-left: 3px;
}

.QDATDesc {
  margin-left: -2%;
  margin-top: -1%;
  width: 104%;
  max-width: 104%;
}

.reqDAT {
  margin-top: 0.2%;
  margin-left: -0.6%;
  font-size: 11px !important;
}

.mcqPanel {
  margin-left: 0%;
}

.UPKModel2Err {
  font-size: 11px;
  color: #ff7591 !important;
  text-decoration: none !important;
}

.errMsgUPKDesc {
  font-size: 11px;
  color: #ff7591 !important;
  text-decoration: none !important;
}

.errMsgUPKDesc {
  margin-left: 1.6%;
  width: 80%;
}

@media screen and (max-width: 1400px) and (min-width: 1100px) {
  .showAns2 {
    display: inline !important;
    margin-top: -3% !important;
  }

  .optCWrdLmtMrTop {
    margin-top: 2.2% !important;
    margin-right: 0.1% !important;
  }

  .optDWrdLmtMrTop {
    margin-top: 0.2% !important;
    margin-right: 0.1% !important;
  }

  .optCWrdLmtErrMrTop {
    margin-top: 0.5% !important;
    margin-right: 0% !important;
  }

  .optDWrdLmtErrMrTop {
    margin-top: -0.2% !important;
    margin-right: 0% !important;
  }

  .errMsgUPKDesc {
    margin-left: -0.4% !important;
  }

  .paddTopUPK {
    padding-top: 4.2% !important;
  }

  .UPKModel2Err {
    margin-left: -1.5% !important;
    margin-top: -4.5%;

    font-size: 11px;
    color: #ff7591 !important;
    text-decoration: none !important;
  }

  .modelAns2UPKWordLmtErrDiv1 {
    margin-top: -4.5% !important;
    margin-left: 93.4% !important;
  }

  .modelAns2UPKWordLmtErrDiv {
    margin-top: -4.5% !important;
    margin-left: 94.5% !important;
  }

  .modelAns2UPKWordLmt1 {
    margin-top: 1% !important;
    margin-right: 23% !important;
  }

  .upkWordLmtNoErr {
    margin-right: 1.5% !important;
    margin-top: 3px;
  }

  .upkWordLmtDNoErr {
    margin-right: 1.5% !important;
    margin-top: 3px;
  }

  .mcqPanel {
    margin-left: -0.5%;
  }

  .reqDAT {
    margin-top: -3.2%;
    margin-left: -1.2%;
    font-size: 11px !important;
  }

  .QDATDesc {
    margin-left: -3%;
    margin-top: -1%;
    width: 106%;
    max-width: 106%;
  }

  .mrdesc {
    margin-left: 0% !important;
    margin-top: -1% !important;
  }

  .DrivingCompetencyErrMsg {
    margin-left: 0%;
    margin-top: 0%;
  }

  .optionMargin {
    margin-left: 0px;
  }

  .pddLeft {
    padding-left: 3.9% !important;
  }

  .CASOptWrdLmtErr {
    margin-top: -2% !important;
    margin-right: -1% !important;
  }

  .CASOptWrdLmt {
    margin-top: 0% !important;
    margin-right: -1% !important;
  }

  .errMsgDAT {
    margin-left: 1% !important;
    margin-top: -1%;
  }

  .errMsgMdAnsUPK {
    margin-left: 0.6% !important;
  }

  .errMsgUPKOptBDiv {
    margin-left: 1.4% !important;
  }

  .errMsgUPKOptADiv {
    margin-left: 1.4% !important;
  }

  .errMsgUPKOptCDiv {
    margin-left: 1.4% !important;
  }

  .errMsgUPKOptDDiv {
    margin-left: 1.4% !important;
  }

  .errMsgUPK {
    margin-left: 2% !important;
  }

  .upkWordLmtErr {
    margin-right: 1.4% !important;
    margin-top: -1.8% !important;
  }

  .upkABWordLmtErr {
    margin-right: 1.4% !important;
    margin-top: -1.5% !important;
  }

  .upkWordLmtNoErr {
    margin-right: 1.5% !important;
    margin-top: 0.5% !important;
  }

  .upkWordLmtDErr {
    margin-right: 1.5% !important;
    margin-top: -1%;
  }

  .questnMrTopErr {
    margin-top: -3% !important;
    margin-right: -0.8% !important;
  }

  .questnUPKMrTopErr {
    margin-top: -3% !important;
    margin-right: 3.2% !important;
  }

  .questnCASMrTopErr {
    margin-top: -3% !important;
    margin-right: 0% !important;
  }

  .paddTopCAS {
    padding-top: 4.4% !important;
  }

  .NonComplianceWordLimitErrDivStyle {
    margin-top: 0%;
  }

  .noncomerroop {
    margin-top: -12%;
    margin-left: -0.7%;
    font-size: 11px !important;
  }

  .NonComplianceWordLimitNoErrDivStyle {
    margin-top: -11%;
    margin-right: -4%;
  }

  .ComplianceWordLimitNoErrStyle {
    margin-top: -2.5%;
    margin-right: 0.5%;
  }

  .NonComplianceWordLimitNoErrStyle {
    margin-top: -0.4%;
    margin-right: -1.2%;
  }
}

@media screen and (max-width: 845px) and (min-width: 800px) {
  .showAns2 {
    display: inline !important;
    margin-top: 0% !important;
    margin-left: -0.8% !important;
  }

  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .pddLeft {
    padding-left: 8.5%;
  }

  .pddLeftUPK {
    padding-left: 5% !important;
  }

  .mrLeft {
    margin-left: 4.8%;
    /* padding-left: 2.3% !important; */
  }
}

@media screen and (max-width: 1260px) and (min-width: 1005px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .pddLeft {
    padding-left: 6.5% !important;
  }

  .pddLeftUPK {
    padding-left: 3% !important;
  }

  .mrLeft {
    margin-left: 2.8% !important;
    /* padding-left: 2.5% !important; */
  }
}

@media screen and (max-width: 1005px) and (min-width: 845px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .pddLeft {
    padding-left: 6.5%;
  }

  .pddLeftUPK {
    padding-left: 4% !important;
  }

  .mrLeft {
    margin-left: 4%;
    /* padding-left: 2.3% !important; */
  }
}

@media screen and (max-width: 1170px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .pddLeftUPK {
    padding-left: 6.5% !important;
  }

  .mrLeft {
    margin-left: 2.8%;
    /* padding-left: 3.3% !important; */
  }

  .modelAns1UPKWordLmt {
    margin-top: -0.9% !important;
    margin-right: -15% !important;
  }
}

.upkMCQOptn {
  width: 98% !important;
  height: 65px !important;
  border: solid 1px #dedede;
  margin-left: -1%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .upkMCQOptn {
    width: 100.3% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-left: -1.5%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .upkMCQOptn {
    width: 96.5% !important;
    height: 65px !important;
    border: solid 1px #dedede;
    margin-left: -1.5%;
  }
}

.upkWordLmtNoErr {
  margin-right: 3.2%;
  margin-top: 3px;
}

.upkWordLmtDNoErr {
  margin-right: 3.2%;
  margin-top: 3px;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .upkWordLmtNoErr {
    margin-right: 3%;
  }

  .CASOptWrdLmt {
    margin-top: 0% !important;
    margin-right: -0.8% !important;
  }

  .questnMrTopNoErr {
    margin-top: -0.5% !important;
    margin-right: -1% !important;
  }

  .reqDAT {
    margin-top: 0% !important;
    margin-left: -0.6% !important;
    font-size: 11px !important;
  }

  .optCWrdLmtErrMrTop {
    margin-top: 0.5% !important;
    margin-right: 0% !important;
  }

  .optDWrdLmtErrMrTop {
    margin-top: -2.2% !important;
    margin-right: 3% !important;
  }

  .errMsgUPK {
    font-size: 11px;
    color: #ff7591 !important;
    margin-left: -0.8% !important;
    text-decoration: none !important;
  }

  .errMsgUPKDesc {
    margin-left: -0.4% !important;
    width: 80% !important;
    margin-top: -2% !important;
  }

  .optCWrdLmtMrTop {
    margin-top: 4.2% !important;
    margin-right: 0% !important;
  }

  .optDWrdLmtMrTop {
    margin-top: 0.2% !important;
    margin-right: 3% !important;
  }

  .upkWordLmtDNoErr {
    margin-right: 0%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .upkWordLmtNoErr {
    margin-right: 4.5%;
  }

  .upkWordLmtDNoErr {
    margin-right: 4.5%;
  }
}

.upkWordLmtErr {
  margin-right: 3.2%;
  margin-top: -1%;
}

.upkABWordLmtErr {
  margin-right: 3.2%;
  margin-top: -1%;
}

.upkWordLmtDErr {
  margin-right: 3.2%;
  margin-top: -1%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .upkWordLmtErr {
    margin-right: 3%;
    margin-top: -3%;
  }

  .upkABWordLmtErr {
    margin-right: 3%;
    margin-top: -2.6%;
  }

  .upkWordLmtDErr {
    margin-right: 0%;
    margin-top: -0.5%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .upkWordLmtErr {
    margin-right: 4.5%;
  }

  .upkABWordLmtErr {
    margin-right: 4.5%;
  }

  .upkWordLmtDErr {
    margin-right: 4.5%;
  }
}

.mrErrMsgUPK {
  margin-left: 0.5%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .mrErrMsgUPK {
    margin-left: 1.5%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .mrErrMsgUPK {
    margin-left: 1.5%;
  }
}

.modelAnswer1 {
  width: 102%;
  border: solid 1px #dedede;
  padding: 1.5%;
  height: 75%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .modelAnswer1 {
    width: 109.5%;
    border: solid 1px #dedede;
    height: 75%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .modelAnswer1 {
    width: 104.5%;
    border: solid 1px #dedede;
    height: 75%;
  }
}

.mrLeftUPKQuestionErr {
  margin-left: 0%;
  margin-top: -4%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .mrLeftUPKQuestionErr {
    margin-left: 3%;
    margin-top: -6%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .mrLeftUPKQuestionErr {
    margin-left: 2%;
    margin-top: -4%;
  }
}

.thWordLmtErr {
  margin-right: 4% !important;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .thWordLmtErr {
    margin-right: 5.5% !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .thWordLmtErr {
    margin-right: 5% !important;
  }
}

.thNewsFeedWordLmtNoErr {
  margin-right: 4% !important;
  margin-top: -0.5%;
}

.thWordLmtNoErr {
  margin-right: 4% !important;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .thWordLmtNoErr {
    margin-right: 5.5% !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .thWordLmtNoErr {
    margin-right: 5% !important;
  }
}

.UPKHead {
  padding-top: 2%;
  /* padding-left: 1.8%; */
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .UPKHead {
    padding-top: 5.5%;
    padding-left: 3.2%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .UPKHead {
    padding-top: 4.5%;
    padding-left: 2.2%;
  }
}

.modelAns1UPKWordLmt {
  margin-top: 0.5%;
  margin-right: -6%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .modelAns1UPKWordLmt {
    margin-top: -0.8%;
    margin-right: -10%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .modelAns1UPKWordLmt {
    margin-top: -4.8%;
    margin-right: -9% !important;
  }
}

.modelAns1UPKWordLmtErr {
  margin-top: -2.6%;
  margin-right: -2%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .modelAns1UPKWordLmtErr {
    margin-top: -15%;
    margin-right: -10%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .modelAns1UPKWordLmtErr {
    margin-top: -3%;
  }
}

.modelAns1UPKWordLmtErrDiv {
  margin-right: -2%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .modelAns1UPKWordLmtErrDiv {
    margin-right: -6%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .modelAns1UPKWordLmtErrDiv {
    margin-right: -3.5%;
  }
}

.modelAns1UPKWordLmtDiv {
  margin-right: 4%;
}

.modelAns2UPKWordLmtErr {
  margin-top: 1%;
  margin-right: 27%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .modelAns2UPKWordLmtErr {
    margin-top: 26%;
    margin-right: 30%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .modelAns2UPKWordLmtErr {
    margin-top: 1%;
  }
}

.modelAns2UPKWordLmt {
  margin-top: -1%;
  margin-right: 2.5%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .modelAns2UPKWordLmt {
    margin-top: 4.5%;
    margin-right: -91.5%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .modelAns2UPKWordLmt {
    margin-top: 1%;
    margin-right: -93%;
  }

  .modelAns2UPKWordLmt1 {
    margin-top: 1%;
    margin-right: -194.5%;
  }
}

.modelAnswer2 {
  width: 100% !important;
  border: solid 1px #dedede;
  height: 250px !important;
  margin-left: 3px;
}

.modelAnswer2lbl {
  padding-left: 0.3%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .modelAnswer2lbl {
    padding-left: 4%;
  }

  .UPKModel2Err {
    font-size: 11px;
    color: #ff7591 !important;
    text-decoration: none !important;
    margin-left: 2%;
    margin-top: -5%;
  }

  .modelAns2UPKWordLmt1 {
    margin-top: -21%;
    margin-right: 62.5%;
  }

  .modelAns2Div {
    margin-left: 1.2%;
    width: 95%;
  }

  .modelAns2UPKWordLmtErrDiv1 {
    margin-top: -7.5%;
    margin-left: 87%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .modelAnswer2lbl {
    padding-left: 3%;
  }
}

.modelAns2UPKWordLmtErrDiv {
  margin-top: -4%;
  margin-left: 93.7%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .modelAns2UPKWordLmtErrDiv {
    margin-top: -2.5%;
    margin-right: -4%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  /* .modelAns2UPKWordLmtErrDiv {
    margin-right: -80%;
    margin-top: 0.5%;
  } */
}

.modelAnswer2ErrMsg {
  margin-top: 0%;
}

@media screen and (max-width: 950px) and (min-width: 800px) {
  .modelAnswer2ErrMsg {
    margin-top: 3%;
    margin-left: 2%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .modelAnswer2ErrMsg {
    margin-top: 1%;
    margin-left: 1%;
  }
}

.paddTop {
  padding-top: 6%;
}

.paddTopCAS {
  padding-top: 3%;
}

.optionErrorMargin {
  margin-left: 18px;
}

/* .marginLeft {
  margin-left: 33px !important;
} */

.qDescMarLeft {
  margin-left: -19px;
}

.widthAnswer {
  width: 107.5%;
}

.marRighttremaining {
  margin-right: 0.5%;
}

.mrRightErrorREmaining {
  margin-right: 1.3%;
  margin-top: 4px;
}

@media screen and (max-width: 845px) and (min-width: 800px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -63% !important;
    margin-left: 0% !important;
  }

  .mcqPanelPosition1 {
    margin-top: -36.6% !important;
    margin-left: 0% !important;
  }

  .upkimgDiv {
    margin-top: -36.3%;
  }

  .pddLeft {
    padding-left: 8.5%;
  }

  .mrLeft {
    margin-left: 3.8%;
  }

  .qsErrCAS {
    margin-left: 1%;
    width: 82%;
    margin-top: -1% !important;
  }

  .marginLeft {
    margin-left: 27px !important;
  }

  .optionErrorMargin {
    margin-left: 24px;
  }

  .answerOptions {
    width: 98.5% !important;
  }

  .qDescMarLeft {
    margin-left: -16px;
  }

  .widthAnswer {
    width: 114.3%;
  }

  .marRighttremaining {
    margin-right: 4.2%;
  }

  .mrRightErrorREmaining {
    margin-right: -0.5%;
  }

  .imgbBox {
    width: 109%;
  }

  .ddlDrvCompWidth {
    width: 101.5%;
  }
}

@media screen and (max-width: 1260px) and (min-width: 1005px) {
  .pddLeft {
    padding-left: 6.5% !important;
  }

  .mrLeft {
    margin-left: 2.8%;
  }

  .marginLeft {
    margin-left: 18px !important;
  }

  .optionErrorMargin {
    margin-left: 19px;
  }

  .answerOptions {
    width: 97.5% !important;
  }

  .qDescMarLeft {
    margin-left: -17px;
  }

  .widthAnswer {
    width: 107%;
  }

  .marRighttremaining {
    margin-right: 3.7%;
  }

  .mrRightErrorREmaining {
    margin-right: 0.8%;
  }

  .imgbBox {
    width: 104%;
  }
}

@media screen and (max-width: 1005px) and (min-width: 845px) {
  .pddLeft {
    padding-left: 6.5%;
  }

  .mrLeft {
    margin-left: 4%;
  }

  .marginLeft {
    margin-left: 30px !important;
  }

  .optionErrorMargin {
    margin-left: 20px;
  }

  .answerOptions {
    width: 97% !important;
  }

  .qDescMarLeft {
    margin-left: -17px;
  }

  .widthAnswer {
    width: 110%;
  }

  .marRighttremaining {
    margin-right: 4.7%;
  }

  .mrRightErrorREmaining {
    margin-right: 0.5%;
  }

  .imgbBox {
    width: 106%;
  }
}

@media screen and (max-width: 1170px) and (min-width: 1005px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -20%;
    margin-left: 0%;
  }

  .mcqPanelPosition1 {
    margin-top: 0%;
    margin-left: 0%;
  }

  .upkimgDiv {
    margin-top: -1.5%;
  }

  .pddLeft {
    padding-left: 4.5% !important;
  }

  .mrLeft {
    margin-left: 2.8% !important;
  }

  .marginLeft {
    margin-left: 29px !important;
  }

  .optionErrorMargin {
    margin-left: 18px !important;
  }

  .answerOptions {
    width: 97.5% !important;
  }

  .qDescMarLeft {
    margin-left: -17px !important;
  }

  .widthAnswer {
    width: 110% !important;
  }

  .marRighttremaining {
    margin-right: 1.7% !important;
  }

  .mrRightErrorREmaining {
    margin-right: 1.2% !important;
  }

  .questnMrTopErr {
    margin-top: 6% !important;
  }

  .questnUPKMrTopErr {
    margin-top: 6% !important;
  }

  .questnCASMrTopErr {
    margin-top: 6% !important;
  }
}

@media screen and (max-width: 1345px) and (min-width: 1260px) {
  .mcqPanelPositionEdit {
    margin-top: 5%;
    margin-left: 0%;
  }

  .mcqPanelPosition {
    margin-top: -46.8% !important;
    margin-left: 0% !important;
  }

  .mcqPanelPosition1 {
    margin-top: -15.5% !important;
    margin-left: 0% !important;
  }

  .upkimgDiv {
    margin-top: -15.5%;
  }

  .pddLeft {
    padding-left: 3.9% !important;
  }

  .mrLeft {
    margin-left: 2.8%;
  }

  .marginLeft {
    margin-left: 30px !important;
  }

  .optionErrorMargin {
    margin-left: 19px;
  }

  .answerOptions {
    width: 97.5% !important;
  }

  .qDescMarLeft {
    margin-left: -17px;
  }

  .widthAnswer {
    width: 107%;
  }

  .marRighttremaining {
    margin-right: 1%;
  }

  .mrRightErrorREmaining {
    margin-right: 2% !important;
  }

  .imgbBox {
    width: 104%;
  }
}

.mcqPanelPositionEdit {
  margin-top: 5%;
  margin-left: 0%;
}

.mcqPanelPosition {
  margin-top: -20%;
  margin-left: 0%;
}

.mcqPanelPosition1 {
  margin-top: 0%;
  margin-left: 0%;
}

.labelQ {
  font-size: 18px;
  color: #111;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 0;
}

/* Custom classes for fields */

.questnMrTopNoErr {
  margin-top: 0.5%;
}

.questnUPKMrTopNoErr {
  margin-top: 0.5%;
}

.questnCASMrTopNoErr {
  margin-top: 0.5%;
}

.questnMrTopErr {
  margin-top: -2%;
}

.questnUPKMrTopErr {
  margin-top: -2%;
}

.questnCASMrTopErr {
  margin-top: -2%;
}

.errMsgCAS {
  color: #ff7591 !important;

  text-decoration: none !important;
}

.errMsgDAT {
  font-size: 11px;
  color: #ff7591 !important;

  text-decoration: none !important;
}

.errMsgMdAnsUPK {
  font-size: 11px;
  color: #ff7591 !important;

  text-decoration: none !important;
}

.errMsgUPK {
  font-size: 11px;
  color: #ff7591 !important;

  text-decoration: none !important;
}

.width1stcol {
  width: 80%;
}

.CASOptWrdLmt {
  margin-top: 0%;
}

.CASOptWrdLmtErr {
  margin-top: -1.5%;
}

.optCWrdLmtMrTop {
  margin-top: 4.2%;
  margin-right: -3%;
}

.optCWrdLmtErrMrTop {
  margin-top: 0.5%;
  margin-right: -4%;
}

.optDWrdLmtMrTop {
  margin-top: 0.2%;
}

.optDWrdLmtErrMrTop {
  margin-top: -2.2%;
  margin-right: -1%;
}

/* .UPKModel2Err {
  margin-left: 0.5%;
  margin-top: -4.5%;
} */

.DATHead {
  margin-bottom: 0px;
  padding-left: 1%;
  padding-top: 2%;
  /* padding-left: 1.8%; */
}

.showAns2 {
  display: inline !important;
  margin-top: -11%;
}

.imgstyle {
  width: 107.3% !important;
}

.readonly {
  opacity: 0.4;
  pointer-events: none;
  background: none !important;
}

.sample-qus-margin {
  margin-top: -50%;
}

@media screen and (min-width: 1400px) {
  .sample-qus-margin {
    margin-top: -10%;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1399px) {
  .sample-qus-margin {
    margin-top: -40%;
  }
}