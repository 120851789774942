html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto-Light', sans-serif !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Page container to handle the flex layout */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure the container fills the entire viewport */
}

/* Content area to take up remaining space */
.content-wrap {
  flex-grow: 1;
  /* This will take up any extra space available */
}

.modal-body {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.pointer {
  cursor: pointer;
}

.colorNav {
  background-color: blue;
}

/* .scroll-container {
  height: auto;
  max-height: calc(100vh - 76px);

  overflow-y: auto;
  box-sizing: border-box;
} */

.navbar-new {
  /* padding-top: 50px; */
  width: 100%;
}

@media only screen and (max-width: 1399px) {

  html,
  body {
    margin: 0;
    padding: 0;
    height: 97.2%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* Header */

.header {
  background: #fafafa;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
}

.login-container {
  float: right;
  /* padding: 16px 10px; */
  margin-right: 16px;
  margin-left: 15%;
}

.icons {
  margin-left: 25px;
  height: 16px;
  width: 16px;
}

.iconsLogoutPerson {
  /* margin-left: 25px; */
  /* height: 20px;
  width: 20px;
  margin-top: 8px; */
  width: 20px;
  cursor: pointer;
}

.iconsLogoutPerson1 {
  /* margin-left: 25px; */
  /* height: 20px;
  width: 20px;
  margin-top: 8px; */
  width: 20px;
  cursor: pointer;
}

.icons:hover {
  cursor: pointer;
}

textarea {
  border: 1px solid #dedede;
}



.footer {
  background: #e8e8e8;
  text-align: center;
  overflow: hidden;
  clear: both;
  color: #111;
  font-size: 14px;
  text-decoration: none;
}

.copyrightText {
  font-family: 'Roboto' !important;
  position: relative;
  color: #111 !important;
  opacity: 1;
}

@media only screen and (min-width: 1400px) {
  .float-rightChar {
    float: right !important;
    position: absolute;
    right: -7px;
    top: 7px;
    font-size: 12px;
    color: #0f0f0f !important;
  }

  .marT-Newsfeed {
    margin-top: 1.5rem;
  }


}

@media only screen and (max-width: 1099px) {
  .float-rightChar {
    float: right !important;
    position: absolute;
    right: -4px;
    /* top: 7px; */
    font-size: 12px;
    color: #0f0f0f !important;
  }

  .marT-Newsfeed {
    margin-top: 0.2rem;
  }
}

@media only screen and (max-width: 1399px) and (min-width: 1100px) {
  .float-rightChar {
    float: right !important;
    position: absolute;
    right: -4px;
    top: 7px;
    font-size: 12px;
    color: #0f0f0f !important;
  }

  .marT-Newsfeed {
    margin-top: 1rem;
  }


}

@media only screen and (min-width: 900px) and (max-width: 1099px) {


  .copyrightText {
    font-size: 14px;
  }
}



@media only screen and (max-width: 630px) {
  .copyrightText {
    font-size: 10px;
  }
}

.footeritems {
  display: grid;
  grid-template-columns: 40px 12% 20% auto;
  /* grid-column-gap: 15px; */
}

a {
  color: #111;
  text-decoration: none;
}

.btn:hover {
  text-decoration: none;
}

.cancelModal {
  width: 30% !important;
  padding-top: 16% !important;
  min-width: 500px !important;
}

.footeratag {
  font-family: 'Roboto' !important;
  text-decoration: none;
  cursor: pointer;
}

.footeratag:hover {
  text-decoration: underline;
}

.layout {
  display: grid;
  grid-template-rows: auto auto auto;
  /* row-gap: 10px; */
}

.react-select__control {
  min-height: 40px !important;

  /* height: 38px !important; */
}

.react-select__indicator-separator {
  display: none;
}

.linemargin {
  padding-top: 15px;
  border-right: solid lightgrey 3px;
  margin-right: 4%;
}

@media screen and (max-width: 2200px) and (min-width: 1500px) {
  .linemargin {
    margin-right: 12%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1050px) {
  .login-container {
    margin-left: 10%;
  }
}

@media screen and (max-width: 1050px) and (min-width: 973px) {
  .login-container {
    margin-left: 3%;
  }
}

/* @media screen and (max-width: 972px) and (min-width: 938px) {
  .login-container{
    margin-left: 3%;
  }
} */

@media screen and (max-width: 972px) and (min-width: 700px) {
  .login-container {
    margin-left: 0%;
  }
}

.topMenu:hover {
  color: green !important;
}

/* .headerColor {
  color: #686868 !important;
} */

.activTab {
  font-family: 'Roboto', sans-serif !important;
  color: #111 !important;
  font-weight: bold !important;
}

/* li.nav-item {
  margin: 0 5px 0 0;
} */

input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
  height: 20px;
  margin-top: 10px;
  width: 20px;
}

/* .navbar-nav {
  display: flex;
  flex-direction: row !important;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: space-between;
  align-items: center;
} */

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.widthhhh {
  width: 10%;
}

/* .navbar-nav .dropdown-menu {
  position: absolute !important;
  float: left;
} */

/* .modal.show {
  display: grid !important;
  align-content: space-around;
} */

.ResetAllBtn {
  text-align: right;
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .SysLowBtn {
    top: 3%;
    right: 2%;
    position: absolute;
  }

  .SysLowGrid {
    margin-top: 10%;
  }

  .SysLowGridC {
    margin-top: 10%;
  }

  .fontFamily {
    font-family: 'Roboto', sans-serif !important;
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .SysLowBtn {
    top: 3%;
    right: 2%;
    position: absolute;
  }

  /* .SysLowGrid{
        margin-top: 10%;
    } */

  .SysLowGridC {
    margin-top: 12%;
  }

  .fontFamily {
    font-family: 'Roboto', sans-serif !important;
    font-size: 11px !important;
  }
}

.nav-link-dropdown-hover .dropdown-item:hover {
  color: #111 !important;
}

.show>.dropdown-toggle .no-transform:is(svg) {
  transform: none;
}

/* Loading overlay */

.loading-view {
  height: 100%;
  width: 100%;
  background-color: #111;
  position: absolute;
  z-index: 3;
  opacity: 0.2;
  top: 0;
  left: 0;
}

.loading-spinner {
  position: absolute;
  z-index: 4;
  width: 38px !important;
  height: 38px !important;
  color: #111;
  left: 50%;
  top: 45%;
}

/* @media (max-width: 1399px) {
  .loading-view {
    height: 103.5%;
  }
} */