.news-headline:hover {
  cursor: pointer;
  color: #007f00;
}

.homeheader {
  color: #007f00;
  margin-left: 2%;
  margin-top: 4%;
  font-size: 30px;
  font-family: 'Univers';
  font-weight: normal;
}

.head-text {
  position: relative;
  z-index: 1;
}

.crop-container {
  width: 100%;
  height: 130px;
  overflow: hidden;
}

.crop-container img {
  /* position: absolute; */
  /* or position: relative */
  width: calc(100vw) !important;
  min-height: 250px;
  max-height: 475px;
  /* left: -10px; */
  top: -150px;
}

.text-on-image {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
}

.text-on-image-contact {
  position: absolute;
  left: 0%;
  top: 70px;
  width: 100%;
}

.text-on-image-contact h1 {
  font-size: 40px;
  color: white;
}

.text-on-image h1 {
  font-size: 40px;
  color: white;
}



.body-container {
  background-color: #fff;
  display: block;
  margin: 0 auto;
  width: 100%;
  z-index: 2;
  position: relative;
  padding-bottom: -70px;
  margin-top: -345px;
}

@media (max-width: 992px) {
  .crop-container img {
    width: calc(130vw) !important;
  }

  .creditcard-container {
    display: none !important;
  }
}

@media (max-width: 650px) {
  .crop-container img {
    width: calc(190vw) !important;
  }

  .crop-container1 img {
    width: calc(100vw + 2000px) !important;
    top: 0px;
  }

  .text-on-image h1 {
    font-size: 24px;
    padding-top: 12px;
  }

  .text-on-image-contact h1 {
    font-size: 24px;
    padding-top: 12px;
  }



}

.p-2 {
  padding: 0.35rem !important;
}

/*
.Competency-Assessmen .text-style-1 {
    font-family: Univers;
    font-weight: 900;
  } */

.homescreen {
  margin: 1% 3% 10px 3%;
  /* height: 45vw; */
  /* margin: 10% 15%;
    background-color: black;
    height: 100%;
    width: 100%; */
}

.Competency-Assessmen {
  width: 1195px;
  height: 24px;
  margin: 21px 0 22px 326px;
  opacity: 0.8;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: 4.97px;
  text-align: center;
  color: #007f00;
}

.Competency-Assessmen .text-style-1 {
  font-family: Univers;
  font-weight: 900;
}

.homegrid {
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-column-gap: 10px;
  height: 100%;
  /* margin-bottom: 50px; */
}

.graphgrid {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
}

.graphgrid2 {
  width: 100%;
  /* display: grid; */
  grid-template-rows: 50% 50%;
  grid-row-gap: 10px;
  /* max-height:650px; */
}

.textHome {
  font-size: 16px;
  /* text-align: justify; */
  /* text-decoration: underline; */
}

.feedgrid {
  background-color: #e8e8e8;
  width: 100%;
  display: grid;
  grid-template-columns: 55% 15% 20%;
  grid-column-gap: 10px;
  padding: 5px 10px;
  color: #111;
  /* opacity: 0.44; */
  /* max-height:650px; */
}

.feeddatagrid {
  background-color: white;
  width: 100%;
  display: grid;
  grid-template-columns: 62% 13% 25%;
  padding: 1px 10px;
  cursor: pointer;

  /* grid-column-gap: 10px; */
  /* max-height:650px; */
}

.priorityicon {
  width: 18px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.chartheading {
  background-color: white;
  height: 55px;
  margin-bottom: 0px;
  padding-top: 10px;
}

.chartheadingold {
  background-color: white;
  height: 55px;
  margin-bottom: 5px;
  padding-top: 10px;
  display: grid;
  grid-template-columns: 92% 6%;
}

.kebabdropdown {
  /* color: black !important; */
  background-color: transparent;
  border: none;
  /* float: right; */
  /* width: 0px; */
  padding: 0px;
}

.kebabdropdown:hover {
  background-color: transparent;
}

.btnpadding {
  padding: 0px;
}

.dropdownicon {
  display: grid;
  grid-template-columns: 80% 20%;
  width: 100%;
}

/* .btn-secondary:hover, .btn-darker-secondary:hover {
  border-color: #dedede !important;
} */

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle,
.btn-darker-secondary:not(:disabled):not(.disabled):active,
.btn-darker-secondary:not(:disabled):not(.disabled).active,
.show>.btn-darker-secondary.dropdown-toggle {
  border-color: #dedede !important;
}

/* .dropdown{
  float: left;
} */

.aligndropdown {
  padding-right: 25px;
}

.pheading {
  padding: 9.5px 9.5px;
  margin-bottom: 0px;
  color: #111;
  /* opacity: 0.8; */
}

.feedgrid2 {
  background-color: #fafafa;
  width: 100%;
  display: grid;
  grid-template-columns: 55% 15% 20%;
  grid-column-gap: 10px;
  padding: 2px 10px;
  /* max-height:650px; */
}

@media screen and (max-width: 1399px) {
  .reports {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    height: 236px;
    /* margin-top: 6.5px; */
    /* box-shadow: 4px 3px 8px 1px lightgrey; */
  }
}

@media screen and (min-width: 1400px) {
  .reports {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;

    /* margin-top: 6.5px; */
    /* box-shadow: 4px 3px 8px 1px lightgrey; */
  }
}

.headgrid1 {
  display: grid;
  grid-template-columns: 80% 20%;
  grid-column-gap: 6px;
  background-color: #007f00;
  height: 133px;
  overflow-x: hidden;
}

.headgrid {
  display: grid;
  grid-template-columns: 20% 80%;
  grid-column-gap: 6px;
  text-transform: capitalize;
  background-color: #007f00;
  height: 133px;
  overflow-x: hidden;
}

.dropright {
  width: 100%;
}

.bheading {
  margin-left: 10px;
  /* color: #686868; */
  /* text-transform: capitalize; */
  /* font-size: 16px; */
  /* font-weight: bold; */
}

.chartborder {
  width: 100%;
  border: white solid 1px;
  box-shadow: 4px 3px 8px 1px lightgrey;
}

.gridrow2 {
  height: 100%;
  width: 98%;
}

.divtop {
  margin-top: 9px;
}

.fitheight {
  height: 13.8%;
  box-shadow: 4px 3px 8px 1px lightgrey;
  margin-top: 10px;
}

.right {
  border-right: #c8c8c8 solid 2px;
}

.left {
  border-left: #c8c8c8 solid 2px;
  padding-left: 5px;
}

.divpadding {
  padding: 15px 0px;
  min-height: 160px;
}

.newsfeedpadding {
  padding: 5px 10px;
  min-height: 166px;
  font-size: 14px;
}

.centertext {
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.feeddatagrid:hover {
  color: #007f00;
}

.favText {
  margin-bottom: 10px;
  padding-left: 0;
}



.lireports {
  font-size: 14px;
  color: #686868;
  margin: 5px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  font-weight: normal;
}

.Path-Copy {
  width: 16.8px;
  height: 16px;
  margin: 4.4px 266.2px 4.6px 12px;
  background-color: #fed63b;
}

@media screen and (min-width: 1000px) {
  .HomeAlignment1 {
    max-width: 97%;
    position: relative;
    top: -1px;
    /* margin-top: 50px; */
    margin-left: 11px;
  }

  .view-newsfeed {
    padding: 1rem 2rem;
    overflow-y: scroll;
    max-height: 81vh;
  }

  .HomeAlignment2 {
    max-width: 97%;
    position: relative;
    top: -9px;
    margin-left: 11px;
  }

  .HomeAlignment3 {
    /* max-width: 97%; */
    /* position: relative; */
    margin-top: 4px;
  }
}

@media screen and (max-width: 999px) {
  .HomeAlignment1 {
    max-width: 50%;
  }

  .view-newsfeed {
    padding: 1rem 2rem;
    overflow-y: scroll;
    max-height: 65vh;
  }

  .HomeAlignment2 {
    max-width: 50%;
  }
}

/* .kebabdropdown {
  width: 12px;
} */

/* 
@media screen and (max-width: 1675px) and (min-width: 1295px) {
  .fitheight {
    height: 12.1%;
  }
}

@media screen and (max-width: 1294px) and (min-width: 1205px) {
  .fitheight {
    height: 10.5%;
  }
}

@media screen and (max-width: 1204px) and (min-width: 1202px) {
  .fitheight {
    height: 12%;
  }

  .homegrid {
    grid-template-columns: 65% 35%;
  }
  .kebabdropdown{
    width: 12px;
  }
}

@media screen and (max-width: 1202px) and (min-width: 1110px) {
  .fitheight {
    height: 12%;
  }

.homegrid {
  grid-template-columns: 65% 35%;
}
.mt-55{
  margin-top: 2.9rem !important;
}
.homeText{
  font-size: 12px;
}
.centertext {
  text-align: left !important;
}
.favText{
  padding-left: 0 !important;
}
b{
  font-size: 14px;
}
.kebabdropdown{
  width: 12px;
}
}

@media screen and (max-width: 1109px) and (min-width: 1077px) {
  .fitheight {
    height: 10.5%;
  }
  .mt-55{
    margin-top: 3.5rem !important;
  }
.homegrid {
  grid-template-columns: 65% 35%;
}

.homeText{
  font-size: 12px;
}
.centertext {
  text-align: left !important;
}
.favText{
  padding-left: 0 !important;
}
b{
  font-size: 14px;
}
.kebabdropdown{
  width: 12px;
}
}


@media screen and (max-width: 1077px) and (min-width: 1033px) {
  .fitheight {
    height: 65% !important;
  }
  .mt-55 {
    margin-top: 1.25rem !important;
}
.homegrid {
  grid-template-columns: 65% 35%;
}
.homeText{
  font-size: 12px;
}
.centertext {
  text-align: left !important;
}
.favText{
  padding-left: 0 !important;
}
b{
  font-size: 12px;
}
.kebabdropdown{
  width: 12px;
}
}

@media screen and (max-width: 1032px) and (min-width: 1000px) {
  .fitheight {
    height: 65% !important;
  }
  .mt-55{
    margin-top: 0.8rem !important;
  }

.homegrid {
  grid-template-columns: 65% 35%;
}
.textHome{
  font-size: 12px;
}
b{
  font-size: 10px;
}
.centertext {
  text-align: left;
}
.favText{
  padding-left: 0;
}
.kebabdropdown{
  width: 12px;
}
}


@media screen and (max-width: 1000px) and (min-width: 960px) {
  .fitheight {
    height: 75% !important;
  }

.homegrid {
  grid-template-columns: 65% 35%;
}
.textHome{
  font-size: 12px;
}
.mt-55 {
  margin-top: 1.7rem !important;
}
.kebabdropdown{
  width: 12px;
}

}

@media screen and (max-width: 992px) and (min-width: 800px) {
  .fitheight {
    height: 10.5%;
  }

.homegrid {
  grid-template-columns: 60% 40%;
}
} */

/* @media (min-width: 992px)
{

} */

/* 
@media screen and (max-width: 1199px) and (min-width: 1183px) {
  .divpadding {
    padding: 15px 0px;
  }
  .reports{
    grid-template-columns: 40% 60%;
  }
  .fitheight {
    height: 9%;
  }
  .lireports{
    margin: 9% 0px;
  }
}


@media screen and (max-width: 1241px) and (min-width: 1183px) {
  .divpadding {
    padding: 15px 0px;
  }
  .fitheight {
    height: 9%;
  }
  .lireports{
    margin: 9% 0px;
  }
}

@media screen and (max-width: 1270px) and (min-width: 1242px) {
  .fitheight {
    height: 10.6%;
  }
  .lireports{
    margin: 9% 0px;
  }
}

@media screen and (max-width: 1675px) and (min-width:1491px) {
  .fitheight {
    height: 10.8%;
  }
  .lireports{
    margin: 8.5% 0px;
  }
  
}

@media screen and (max-width: 1490px) and (min-width: 1271px){
  .fitheight {
    height: 10.8%;
  }
  .lireports{
    margin: 8.5% 0px;
  }
  
} */

.breadcrumb-item+.breadcrumb-item::before {
  content: '>' !important;
  color: white;
}

code {
  font-family: 'Roboto', sans-serif !important;
}

.h2 {
  font-size: 24px !important;
  font-family: 'Roboto-Medium', sans-serif !important;
}

.h3 {
  font-size: 18px !important;
  font-family: 'Roboto-Medium', sans-serif !important;
}

.h4 {
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif !important;
}

.h4-1 {
  font-size: 16px !important;
  font-family: 'Roboto-Medium', sans-serif !important;
}

.h4-2 {
  font-size: 16px !important;
  font-family: 'Roboto-Medium', sans-serif !important;
}

.h5 {
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif !important;
}

.h5-1 {
  font-size: 14px !important;
  font-family: 'Roboto-Medium', sans-serif !important;
}

.h5-2 {
  font-size: 14px !important;
  /* font-family: 'Roboto-Medium', sans-serif !important; */
}

.h6 {
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif !important;
}

.h6-1 {
  font-size: 12px !important;
  font-family: 'Roboto-Medium', sans-serif !important;
}

.h7 {
  font-size: 11px !important;
  font-family: 'Roboto', sans-serif !important;
}

.support_text {
  margin-bottom: 8px;
}

.support-myfav-container {
  margin-top: 0.5rem;
}

@media screen and (min-width: 801px) and (max-width: 1280px) {
  .support_text {
    margin-bottom: 4px;
  }

  .support-myfav-container {
    margin-top: 0.25rem;
    margin-bottom: 7px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1080px) {
  .support_text {
    margin-bottom: 0px;
  }

  .support-myfav-container {
    /* margin-top: 0.25rem; */
    margin-bottom: 34px;
  }
}