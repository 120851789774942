.title-index {
  z-index: 0;
}

.breadcrumb {
  /* background-color: #007f00; */
  /* added from merge pr */
  align-items: baseline;
  height: 25px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
  padding-block: 0.75rem;
}

.desclength {
  padding-top: 3px;
  font-size: 12px;
  color: #0f0f0f;
}

.requiredAstrik {
  color: red;
}

/* custom-modal.css */

.modal-90 {
  width: 90%;
  max-width: 90%;
}

.modal-70 {
  width: 70%;
  max-width: 70%;
}

.modal-60 {
  width: 60%;
  max-width: 60%;
}

/* custom-modal.css */
.modal-50 {
  width: 50%;
  max-width: 50%;
}

@media (max-width: 1199px) {

  /* custom-modal.css */
  .modal-90,
  .modal-70,
  .modal-60,
  .modal-50 {
    width: 97%;
    max-width: 97%;
  }
}

/* .btn:hover {
  border-color: cornflowerblue;
} */

.bp-select>div {
  background-color: rgba(0, 0, 0, 0);
  font-family: 'Roboto', sans-serif !important;
  font-size: 20px;
  font-weight: 600;
}

.alignCenter {
  text-align: center;
  border-color: transparent !important;
}

.bp-select>div {
  background-color: rgba(0, 0, 0, 0);
  font-family: 'Roboto', sans-serif !important;
  font-size: 20px;
  font-weight: 600;
}

.alignRight {
  text-align: right;
  border-color: transparent !important;
}

.alignLeft {
  text-align: left;
  border-color: transparent !important;
}

.pr-10 {
  margin-right: 10px;
}

.iconsStyle {
  color: green;
  margin-right: 0px !important;
}

.menuStyle {
  background-color: transparent !important;
  border: none !important;
  color: black !important;
  box-shadow: none !important;
}

.menuStyle:active {
  border: none !important;
}

.dropdownbtns {
  color: #686868 !important;
  padding: 0px !important;
  background-color: transparent !important;
  border: none !important;
  font-size: 18px !important;
  box-shadow: none !important;
}

.dropdownMenu {
  margin-top: -30px;
  margin-left: 7px;
}

.pr-10 {
  margin-right: 10px;
}

.page-header-row {
  height: 133px !important;
  width: auto;
}

.modalPopupBGColour {
  background-color: rgb(232, 232, 232);
  /* padding-left: 27px !important; */
  height: 76px;
}

.crossIconPopup {
  margin: 20px;
}

.description {
  height: 100%;
  margin-top: 4px;
  border: solid 1px #d8d8d8;
  padding: 10px;
}

.rightfloat {
  float: right;
}

.leftfloat {
  float: left;
}

.contactpage {
  height: 133px;
  background-color: #007f00;
}

.headerstyle {
  height: 100%;
  padding-top: 70px;
  margin-left: 5%;
  font-weight: 800;
}

.marginleft5 {
  margin-left: 5%;
}

.privacyerrmsg {
  color: #a80000;
  font-size: 11px;
}

.inlinemodal {
  width: 50% !important;
}

.privacyheader {
  text-align: center;
  margin-bottom: 0px;
  margin-top: auto;
  font-weight: 800;
  color: #363636;
}

.newsfeedheader {
  font-weight: 800;
  color: #363636;
  margin-left: 35px;
}

.closemodalbtn {
  float: right;
  cursor: pointer;
}

.download {
  bottom: 0;
  cursor: pointer;
  /* float: right; */
}

.labelStyle {
  padding-left: '15px';
  margin-bottom: '10px';
}

.menuCss {
  font-family: 'Roboto', sans-serif !important;
  font-size: 20px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: 0.51px !important;
  text-align: center !important;
  color: #686868 !important;
}

.bottomMargin {
  margin-bottom: -10px;
}

/* .modal.show:focus {
  background: rgb(0, 0, 0, 0.4) !important;
} */

.anchortag {
  color: blue !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.alertImg {
  float: right !important;
  margin-right: -20px !important;
}

.redBorder {
  border: 1px solid red !important;
}

.redOutline {
  outline: 1px solid red;
}

.anchortag {
  color: blue !important;
  text-decoration: underline !important;
}

.signature {
  margin: '1% 0px';
}

.privacyheader {
  margin-top: 17px !important;
}

.privacysuccess {
  margin-bottom: 17px !important;
}

.viewfeed {
  display: grid;
  grid-template-columns: 50% 50%;
}

.margindown {
  margin-bottom: 2rem;
  white-space: pre-wrap;
}

.pdown {
  margin-bottom: 5px;
}

.questndesc-left {
  margin-left: -11px !important;
}

.questncellstyles {
  margin-left: 12px !important;
}

.cellborder {
  border: none !important;
}

.headlineclass {
  font-weight: normal;
  opacity: 0.8;
  text-overflow: ellipsis;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}

.activTab {
  font-family: 'Roboto', sans-serif !important;
  color: #111 !important;
  font-weight: bold !important;
}

.activeNavLink {
  font-family: 'Roboto', sans-serif !important;
  color: #111 !important;
  font-weight: bold !important;
}

.activemenu {
  font-weight: normal;
}

dropdown {
  overflow: hidden;
}

.personinfoPopStyle {
  line-height: 35px;
  font-family: 'Roboto', sans-serif !important;
  color: #686868;
  font-size: 16px !important;
}

.personinfoPopStyle .selectedlang {
  cursor: pointer;
  color: #111;
  font-weight: bold;
}

.personinfoPopStyle span:hover {
  color: rgb(0, 127, 0);
}

.tooltip-link::after {
  border-color: red transparent transparent transparent !important;
}

.arrowdata {
  border-color: red transparent transparent transparent !important;
  color: red;
}

input#session-date {
  position: relative;
  overflow: hidden;
}

input#session-date::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(12);
}

.modalStyle .modal-content {
  max-width: 80% !important;
  margin-left: 1.7% !important;
  margin-right: 2% !important;
}

.cross-icon-reports {
  display: contents;
  cursor: pointer;
}

.Value-Copy-11 {
  width: 400px;
  height: 48px;
  margin: -67px 6px 12px 885px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
}

.navlink-notification-count {
  color: black;
  font-size: 11px;
  background-color: #edac1a;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  width: 21px;
  height: 21px;
}

.navlink-notification-count>div {
  display: grid;
  place-content: center;
}

.mytraining-count {
  right: -1px;
}

.assessment-count {
  right: -1px;
}

@media (max-width: 992px) {
  .mytraining-count {
    left: 100px;
  }

  .assessment-count {
    left: 107px;
  }
}

@media only screen and (min-width: 1200px) {
  .Value-Copy-12 {
    position: relative;
    left: -3%;
    font-size: 16px;
    display: table-caption;
    width: 170%;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
  }
}

@media only screen and (min-width: 1400px) {
  .assessment-icon {
    margin-right: 5px;
    margin-bottom: 2px;
  }

  .home-icon {
    margin-bottom: 4px;
  }

  .reports-icon {
    margin-bottom: 2px;
  }
}

/* .btn-brand-primary {
  color: #111 !important;
  border-color: #007f00;
} */

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .Value-Copy-12 {
    position: relative;
    left: -5%;
    font-size: 16px;
    display: table-caption;
    width: 390%;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
  }

  .reportBtn {
    font-size: 16px !important;
    background-color: white !important;
    color: rgb(17, 17, 17) !important;
    font-weight: 600;
    border: 1px solid #007f00;
    white-space: nowrap;
  }

  .reportsBtnPos {
    position: relative;
    /* right: -10%; */
    min-width: fit-content;
  }

  .assessment-icon {
    margin-right: 5px;
    margin-left: 9px;
    height: 20px;
  }

  .home-icon {
    margin-bottom: 4px;
    height: 20px;
  }

  .reports-icon {
    margin-left: 11px;
    height: 20px;
  }

  /* .settings-icon{
      height: 20px;
    } */
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  .Value-Copy-12 {
    position: relative;
    right: 0;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    width: 369%;
    line-height: 1.5;
    display: table-caption;
    top: 14px;
    letter-spacing: normal;
    color: #fff;
  }

  .reportBtn {
    font-size: 10px !important;
    background-color: white !important;
    color: rgb(17, 17, 17) !important;
    border: 1px solid #007f00;
    font-weight: 600;
    white-space: nowrap;
  }

  .reportsBtnPos {
    position: relative;
    right: 0;
    min-width: fit-content;
  }

  .gridHtWdmanageemployee {
    height: 539px;
    /* min-width: 90%; */
    width: 93%;
    padding-top: 2%;
    margin: 2rem;
  }

  .assessment-icon {
    margin-right: 5px;
    margin-left: 9px;
    height: 13px;
  }

  .home-icon {
    margin-bottom: 4px;
    height: 15px;
  }

  .reports-icon {
    margin-left: 11px;
    height: 14px;
  }

  .settings-icon {
    height: 20px;
    margin-right: -4px !important;
  }

  /* .jobtitle-lefthead {
  width: 20% !important;
  margin-left: 5% !important;
}
.jobtitle-leftcell {
  margin-left: 5% !important;
  width: 20% !important;
}
.empdesc-lefthead {
  width: 27% !important;
} */
}

@media only screen and (max-width: 999px) {
  .assessment-icon {
    margin-right: 5px;
    margin-left: 9px;
    height: 13px;
  }

  .home-icon {
    margin-bottom: 4px;
    height: 15px;
  }

  .reports-icon {
    margin-left: 11px;
    height: 14px;
  }

  .settings-icon {
    height: 20px;
    margin-right: -4px !important;
  }
}

.Value-Copy-11 .text-style-1 {
  font-weight: 900;
}

.Value-Copy-12 .text-style-1 {
  font-weight: 900;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}

body:before {
  content: '';
  height: 100%;
  float: left;
  width: 0;
}

.button-heading {
  color: #fff;
  font-size: 14px;
}

.title-button {
  background-color: white !important;
  white-space: nowrap;
  font-size: 14px !important;
  width: 168px;
  font-family: 'Roboto' !important;
}

.main-heading {
  font-size: 38px;
  /* color: #fff; */
  font-family: 'Roboto';
  line-height: '1.4';
}

@media only screen and (max-width: 900px) {
  .button-heading {
    font-size: 12px;
  }

  .breadcrumb {
    font-size: 12px;
  }

  .title-button {
    font-size: 12px !important;
    width: 150px;
  }

  .main-heading {
    font-size: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .button-heading {
    font-size: 11px;
  }

  .breadcrumb {
    font-size: 11px;
  }

  .title-button {
    font-size: 10px !important;
    width: 123px;
  }

  .main-heading {
    font-size: 24px;
  }
}

.hide {
  display: none;
}

.tskR {
  display: inline;
  position: absolute;
  /* margin-top: 20px;
  margin-left: 32px; */
}

.taskRetInfoIcon {
  /* margin-left: -50%; */
  color: #000000 !important;
  /* margin-top: 10px; */
  cursor: pointer;
}

#tskRetInfo:hover+.hide {
  display: inline-table;
}

table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

.infoBox {
  width: 40%;

  /* margin: 76px 43px 33px 236px;
  padding: 19px 24px 24px 54px;
  border-radius: 5px;
  box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
  border: solid 1px #4fc3f7;
  background-color: #ecfafe;
  margin-left: 110%;
  margin-top: -9%; */
}

.infoHeading {
  font-family: 'Roboto', sans-serif !important;

  font-size: 16px;
  font-weight: 900;
}

.infotext {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  color: rgba(17, 17, 17, 0.8);
}

.iconsLogoutPersoncontact {
  height: 20px;
  width: 20px;
  margin-top: 8px;
  margin-right: 25px;
  cursor: pointer;
  float: right;
}

textarea {
  resize: none !important;
}

/* Manage Question style starts */
.questndesc-lefthead {
  /* margin-left: -1.8% !important; */
  width: 50% !important;
  font-size: 12px !important;
}

@media only screen and (min-width: 1401px) {
  .agquestionheader {
    margin-left: 10px;
    padding-left: 50px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .agquestionheader {
    margin-left: 10px;
    padding-left: 40px !important;
  }
}

@media only screen and (max-width: 600px) {
  .example {
    background: red;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 992) {

  /* li.nav-item {
    margin: 0 5px 0 0;
  } */
  a.nav-link>div {
    margin-top: 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1099) {
  .mt-55 {
    margin-top: -1.3rem !important;
  }

  /* li.nav-item {
    margin: 0 10px 0 0;
  } */
  .textHome {
    font-size: 13px !important;
  }

  .m-144 {
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1199) {
  .mt-55 {
    margin-top: -1.2rem !important;
  }

  a.nav-link>div {
    margin-top: 5px;
  }

  /* li.nav-item {
    margin: 0 20px 0 0;
  } */
  .textHome {
    font-size: 16px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mt-55 {
    margin-top: 1.3rem !important;
  }

  a.nav-link>div {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 1299px) {
  .newsfeedmargin {
    background-color: white;
    margin-top: 6.5px;
    box-shadow: 4px 3px 8px 1px lightgrey;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1399px) {
  .newsfeedmargin {
    background-color: white;
    margin-top: -2px;
    box-shadow: 4px 3px 8px 1px lightgrey;
  }
}

@media only screen and (min-width: 1400px) {
  .mt-55 {
    margin-top: -1.4rem !important;
  }

  .newsfeedmargin {
    background-color: white;
    margin-top: 8.5px;
    box-shadow: 4px 3px 8px 1px lightgrey;
  }

  /* li.nav-item {
    margin: 0 60px 0 0;
  } */
  a.nav-link>div {
    margin-top: 0px;
  }
}

/* @media screen and (max-width: 992px) and (min-width: 768px) {
  .questndesc-lefthead {
    margin-left: -2.5% !important;
    width: 55.8% !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 993px) {
  .questndesc-lefthead {
    margin-left: -2.3% !important;
    width: 55.5% !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1201px) {
  .questndesc-lefthead {
    margin-left: -2.3% !important;
    width: 55.5% !important;
    font-size: 12px !important;
  }
  .mt-55 {
    margin-top: 0.4rem !important;
}
}
@media screen and (min-width: 1920px) {
  .questndesc-lefthead {
    margin-left: -2.3% !important;
    width: 55.5% !important;
    font-size: 12px !important;
  }
  .mt-55 {
    margin-top: -5.9rem!important;
}
} */

.questntype_head {
  width: 12% !important;
  font-size: 12px !important;
  margin-left: 33% !important;
}

.questcritical_head {
  width: 11% !important;
  font-size: 12px !important;
  margin-left: 13% !important;
}

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .questntype_head {
    margin-left: 30.5% !important;
    width: 15.8% !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .questntype_head {
    margin-left: 30.7% !important;
    width: 15.5% !important;
    font-size: 12px !important;
  }
} */

.questnversion_head {
  width: 9% !important;
  font-size: 12px;
  margin-left: 27.9% !important;
}

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .questnversion_head {
    margin-left: 23.5% !important;
    width: 12.5% !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .questnversion_head {
    margin-left: 23.7% !important;
    width: 12.5% !important;
    font-size: 12px !important;
  }
} */
.questnmcq_head {
  width: 10% !important;
  font-size: 12px !important;
  margin-left: 20% !important;
}

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .questnmcq_head {
    margin-left: 13.4% !important;
    width: 16.5% !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .questnmcq_head {
    margin-left: 13.7% !important;
    width: 16.8% !important;
    font-size: 12px !important;
  }
} */

.questneditdel_head {
  width: 10% !important;
  font-size: 12px !important;
  margin-left: 7% !important;
}

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .questneditdel_head {
    width: 13% !important;
    font-size: 12px !important;
    margin-left: 7% !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .questneditdel_head {
    width: 13% !important;
    font-size: 12px !important;
    margin-left: 8% !important;
  }
} */

.questndesc-leftcell {
  width: 50% !important;
  font-size: 12px;
}

.questntype_cell {
  width: 12% !important;
  font-size: 12px !important;
  margin-left: 33% !important;
}

.questnmcq_cell {
  width: 10% !important;
  font-size: 12px !important;
  margin-left: 20% !important;
}

.questncritical_cell {
  width: 11% !important;
  font-size: 12px !important;
  margin-left: 13% !important;
}

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .questnmcq_cell {
    width: 10% !important;
    font-size: 12px !important;
    margin-left: 15% !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .questnmcq_cell {
    width: 10% !important;
    font-size: 12px !important;
    margin-left: 15% !important;
  }
} */

.deleteQsSuccess {
  margin-left: 190%;
}

.gridCrudIcons_Question {
  justify-content: space-between;
  width: 4.6%;
  display: flex;
}

/* Manage Question style ends */

/* Manage Threats style starts */
/* .threatdesc-left {
  /* margin-left: -10px !important; */
/* }  */

.threatdesc-lefthead {
  font-size: 12px !important;
}

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .threatdesc-lefthead {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .threatdesc-lefthead {
    font-size: 12px !important;
  }
} */

.threattype_head {
  width: 14% !important;
  font-size: 12px !important;
  margin-left: 32% !important;
}

.threatversion_head {
  width: 12% !important;
  font-size: 12px;
  margin-left: 24% !important;
}

.threatmcq_head {
  width: 16% !important;
  font-size: 12px !important;
  margin-left: 14% !important;
}

.threateditdel_head {
  width: 45% !important;
  font-size: 12px !important;
  margin-left: 15% !important;
}

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .threateditdel_head {
    width: 70% !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .threateditdel_head {
    width: 46% !important;
    font-size: 12px !important;
  }
} */

.threatdesc-leftcell {
  margin-left: -2.5% !important;
  width: 71% !important;
  font-size: 12px;
}

.threattype_cell {
  width: 10% !important;
  font-size: 12px !important;
  margin-left: 33% !important;
}

.threatversion_cell {
  width: 10% !important;
  font-size: 12px !important;
  margin-left: 25% !important;
}

.threatmcq_cell {
  width: 10% !important;
  font-size: 12px !important;
  margin-left: 15% !important;
}

.threateditdel_cell {
  width: 36% !important;
  margin-left: 11% !important;
}

.deleteThrtSuccess {
  margin-left: 190%;
}

.gridCrudIcons_Threat {
  display: flex;
  margin-left: -20px;
  justify-content: space-between;
}

.gridCrudIcons_Employee {
  justify-content: space-between;
  width: 27%;
  display: flex;
}

/* Manage Threats style ends */

.createThreatHead {
  padding-left: 2.7%;
  /* font-weight: 800 !important; */
  /* padding-top: 2%; */
  padding-top: 3.2%;
}

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .createThreatHead {
    margin-bottom: 0px;
    padding-left: 5.2%;
    padding-top: 10%;
  }
} */

/* @media screen and (max-width: 1080px) and (min-width: 950px) {
  .createThreatHead {
    margin-bottom: 0px;
    padding-left: 4.3%;
    padding-top: 7%;
  }
} */

.Head {
  padding-left: 2%;
  display: initial;
  /* vertical-align: text-bottom; */
  color: #111 !important;
}

/* @media screen and (max-width: 950px) and (min-width: 800px) {
  .Head {
    padding-left: 3%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 950px) {
  .Head {
    padding-left: 2.5%;
  }
} */

.breadCrumbFont {
  font-family: 'Roboto', sans-serif !important;
}

.modal-70 {
  max-height: fit-content !important;
  zoom: 99%;
}

.ag-theme-alpine .ag-header {
  border-radius: none !important;
}

.ag-header-cell-text {
  overflow: hidden !important;
  white-space: initial;
  text-align: center;
}

ag-theme-alpine [class^='ag-'],
.ag-theme-alpine [class^='ag-']:focus,
.ag-theme-alpine [class^='ag-']:after,
.ag-theme-alpine [class^='ag-']:before {
  box-sizing: content-box !important;
}

.ag-theme-alpine [class^='ag-'],
.ag-theme-alpine [class^='ag-']:focus,
.ag-theme-alpine [class^='ag-']:after,
.ag-theme-alpine [class^='ag-']:before {
  box-sizing: inherit !important;
}

label {
  display: inline-block;
  /* margin-top: 0.5rem; */
  margin-bottom: 0 !important;
  color: #111 !important;
}

/* .dropdown-menu.show {
  width: 100% !important;
} */
@media screen and (min-width: 1000px) {
  .modal-content {
    height: fit-content;
    overflow-x: inherit !important;
    /* max-height: 90vh !important; */
  }
}

@media screen and (max-width: 999px) {
  .modal-content {
    height: fit-content;
    overflow-x: inherit !important;
    /* max-height: 85vh !important; */
  }
}

/* @media screen and (min-width: 1200px) {
  .modal-70.modal-md {
    margin: 4%;
  }
} */

/* .agquestionheader {
  margin-left: 10px;
  padding-left: 50px !important;
} */

@media screen and (min-width: 769px) {
  .btn-create {
    background-color: white !important;
    float: right;
    font-weight: 600 !important;
    position: relative;
    white-space: nowrap;
    font-size: 16px !important;
    right: -15%;
  }
}

@media screen and (max-width: 769px) {
  .btn-create {
    background-color: white !important;
    float: right;
    font-weight: 600 !important;
    position: relative;
    white-space: nowrap;
    font-size: 16px !important;
  }
}

.btn-create-newsfeed {
  background-color: white !important;
  float: right;
  color: black !important;
  font-weight: 600 !important;
  position: relative;
  margin-right: 10%;
  top: -17px;
  font-size: 0.9375rem !important;
  padding: 4px !important;
  white-space: nowrap;
}

.dropdown-menu-scroll {
  max-height: 250px;
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.btn-cancel {
  border: 1px solid #111;
}

/* Overlay */
@media screen and (min-width: 1000px) {
  .overlay-heading {
    color: white;
    font-size: 36px;
    font-weight: bold;
    bottom: -15px;
    position: relative;
  }

  .m50 {
    margin-right: 50px;
  }

  .m27 {
    margin-right: 27px;
  }
}

@media screen and (min-width: 600px) and (max-width: 999px) {
  .overlay-heading {
    color: white;
    font-size: 30px;
    font-weight: bold;
    bottom: -15px;
    position: relative;
    white-space: nowrap;
  }

  .m27 {
    margin-right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .m50 {
    margin-right: 11%;
  }
}

.standard-listgroup-item {
  padding-top: 30px;
  padding-bottom: 30px;
}

.custom-listgroup-item {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (min-width: 1501px) and (max-width: 1680px) {
  .custom-listgroup-item {
    width: calc(100% - 30px)
  }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .custom-listgroup-item {
    width: calc(100% - 50px)
  }
}

.listgroup-bt {
  border-bottom: 1px #f3f2f2 solid;
  width: 100%;
}

.deleteToast .modal-content {
  max-width: 100%;
}

.deleteToast1 {
  max-width: 100%;
  /* margin-top: 2%; */
  margin-top: 18%;
}

.btn-brand-secondary:focus,
.btn-brand-secondary.focus {
  color: #111111;
  border-color: transparent;
  box-shadow: none !important;
}

.btn-secondary:focus,
.btn-secondary.focus,
.btn-darker-secondary:focus,
.btn-darker-secondary.focus {
  color: #fff;
  background-color: #dedede;
  border-color: #dedede;
  box-shadow: 0 0 4px 0 #ffffff00 !important;
}

textarea:focus-visible {
  background-color: #ffffff00 !important;
  outline: none;
}

.btn-darker-tertiary:focus,
.btn-darker-tertiary.focus {
  color: #111111;
  border-color: #ffffff !important;
  box-shadow: none;
}

.modal-70-scrollable .modal-body {
  overflow-y: inherit;
  height: auto;
}

.empname {
  text-align: left;
  /* font-size: 15px; */
  margin: 8px 0 0 8px;
  /* font-weight: 600; */
  width: inherit;
  /* border-bottom: 1px solid lightgray !important; */
  padding-bottom: 9px;
}

.optnQ {
  text-align: right;
  white-space: nowrap;
  display: flex;
  align-items: baseline;
}

.optnGap {
  display: grid;
  grid-template-columns: 33% 49%;
  row-gap: 10px;
  column-gap: 18%;
}

:focus-visible {
  outline: none !important;
}

.btn-brand-tertiary:focus,
.btn-brand-tertiary.focus {
  color: #111111;
  border-color: transparent;
  box-shadow: 0 0 4px 0 transparent !important;
}

#addHazard .css-b62m3t-container {
  padding: 0%;
  margin-left: 2%;
}

#addHazard .css-b62m3t-container .css-26l3qy-menu .css-4ljt47-MenuList {
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  margin-bottom: 8px;
  margin-top: 8px;
  position: fixed !important;
  z-index: 1;
  box-sizing: border-box;
  width: 46% !important;
}

#addHazard .css-b62m3t-container .css-26l3qy-menu .css-11unzgr {
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  margin-bottom: 8px;
  margin-top: 8px;
  position: fixed !important;
  z-index: 1;
  box-sizing: border-box;
  width: 46% !important;
}

/* css-11unzgr */

.css-1n7v3ny-option {
  background-color: #deebff;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 60px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

/* #addLOActivity .css-b62m3t-container .css-26l3qy-menu .css-4ljt47-MenuList {
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  margin-bottom: 8px;
  margin-top: 8px;
  position: fixed !important;
  z-index: 1;
  box-sizing: border-box;
  width: 31.8% !important;
}

#addLOActivity .css-b62m3t-container .css-26l3qy-menu .css-11unzgr {
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  margin-bottom: 8px;
  margin-top: 8px;
  position: fixed !important;
  z-index: 1;
  box-sizing: border-box;
  width: 32.2% !important;
} */

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent !important;
}

.btn-darker-tertiary:hover {
  color: #111111;
  border-color: transparent !important;
}

.reportBtn:hover {
  background-color: white !important;
  color: #007f00 !important;
  border: 1px solid #007f00;
}

.btn-brand-tertiary:hover {
  color: #111111;
  border-color: #dedede !important;
  box-shadow: 0px 0px 0px 0px transparent !important;
}

.btn-brand-tertiary {
  box-shadow: 0px 0px 0px 0px transparent !important;
}

.stickyStyles {
  display: grid;
  grid-template-columns: auto auto;
  height: 147px;
  /* height: 190px; */
  padding-right: 15px;
  border: 1px solid #dedede;
  /* border-right: 1px solid #dedede; */
}

.css-8mmkcg {
  color: #1119;
}

.css-1s2u09g-control {
  border-color: rgb(222 222 222) !important;
  cursor: pointer !important;
}

.css-1pahdxg-control {
  border-radius: 0px !important;
  min-height: 46px !important;
}

.redBorderRequired {
  border: 1px solid red !important;
}

@media screen and (min-width: 869px) {
  .reportsComp {
    display: flex !important;
    flex-wrap: nowrap !important;
    margin-left: -5%;
  }
}

@media screen and (max-width: 868px) {
  .headerColor {
    font-size: 10.5px;
    vertical-align: middle;
    color: #686868;
  }

  .iconsLogoutPerson {
    /* margin-left: 25px; */
    width: 30px !important;
    height: 30px !important;
    cursor: pointer;
    margin-left: -10px;
  }

  .reportsComp {
    margin-left: -30%;
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 768px) and (max-width: 899px) {
  .headerColor {
    font-size: 12px;
    vertical-align: middle;
    color: #686868;
  }
}

@media screen and (min-width: 900px) and (max-width: 1024px) {
  .headerColor {
    font-size: 18px;
    vertical-align: middle;
    color: #686868;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .headerColor {
    font-size: 18px;
    vertical-align: middle;
    color: #686868;
  }
}

@media screen and (min-width: 1200px) {
  .headerColor {
    font-size: 24px;
    color: #686868;
    vertical-align: middle;
  }
}

@media screen and (max-width: 1199px) {
  .gridHeaderStyleModulenumber {
    width: 27% !important;
  }

  .gridHeaderStyleModuleNAme {
    width: 40% !important;
    left: 30% !important;
  }

  .gridHeaderStyleassignEmp {
    width: 33% !important;
    left: 70% !important;
  }

  .gridCrudIcons_assignEmp {
    justify-content: center;
    width: 33%;
    display: flex;
  }

  .questneditdel_cell {
    /* width: 100% !important; */
    margin-left: 8% !important;
    width: 14% !important;
  }
}

@media screen and (min-width: 1200px) {
  .gridHeaderStyleModulenumber {
    width: 20% !important;
  }

  .gridHeaderStyleModuleNAme {
    width: 50% !important;
    left: 20% !important;
  }

  .gridHeaderStyleassignEmp {
    width: 30% !important;
    left: 70% !important;
  }

  .gridCrudIcons_assignEmp {
    justify-content: center;
    width: 20%;
    display: flex;
  }

  .questneditdel_cell {
    /* width: 100% !important; */
    margin-left: 7% !important;
    width: 10% !important;
  }
}

.headingAssignEmp {
  margin-left: 0.8% !important;
  font-weight: bold;
  font-stretch: normal;
  margin-bottom: 0;
  font-style: normal;
  letter-spacing: normal;
  color: #111111 !important;
}

.gridiconCenter {
  margin: 0 5% !important;
}

.gridHeaderStyleEmpJob {
  width: 20% !important;
  left: 53% !important;
}

.gridHeaderStyleEmpLocation {
  width: 23% !important;
  left: 30% !important;
}

.gridHeaderStylepStart {
  width: 27% !important;
  left: 73% !important;
}

@media only screen and (max-width: 799px) {
  .assignEmptomodulechkbx {
    width: 15% !important;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 999px) {
  .questnversion_cell {
    width: 10% !important;
    font-size: 12px !important;
    margin-left: 24.7% !important;
  }

  .reportBtn {
    font-size: 9px !important;
    margin: 0;
    padding: 10px 15px;
  }
}

@media only screen and (min-width: 1000px) {
  .questnversion_cell {
    width: 9% !important;
    font-size: 12px !important;
    margin-left: 27.9% !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1199px) {
  .assignEmptomodulechkbx {
    width: 9% !important;
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .assignEmptomodulechkbx {
    width: 5% !important;
    font-size: 12px !important;
  }

  .gridHeaderStyleEmp {
    width: 25% !important;
    left: 5% !important;
  }
}

@media only screen and (max-width: 1199px) {
  .gridHeaderStyleEmp {
    width: 23% !important;
    left: 8% !important;
  }
}

.assignEmpchkbx {
  width: 10% !important;
  font-size: 12px !important;
}

.gridHeaderStyle {
  width: 26% !important;
}

.gridiconCenter {
  margin: 0 5% !important;
}

/* .btn-group-lg>.btn, .btn-lg {
  border-radius: 0.3125rem !important;
} */

/* .css-26l3qy-menu {
  box-shadow: none !important;
  margin-top: -7px !important;
} */

/* #addLOActivity .css-b62m3t-container .css-26l3qy-menu {
  box-shadow: none !important;
  margin-top: -7px !important;
} */

#addHazard .css-b62m3t-container .css-26l3qy-menu {
  box-shadow: none !important;
  margin-top: -7px !important;
}

.step-item {
  /* Your regular styles for the step item */
  margin-bottom: 10px;
  /* Adjust as needed */
  cursor: pointer;
  padding: 1px;
  width: 35px;
  height: 20px;
}

.x-mark {
  position: relative;
  display: inline-block;
}

.x-mark::after,
.x-mark::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  /* Adjust the width of the X */
  height: 20px;
  /* Adjust the height of the X */
  background-color: red;
}

.x-mark::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.x-mark::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.css-1okebmr-indicatorSeparator {
  background-color: white !important;
  width: 0px;
}

.modalHeaderGlobal {
  font-size: 20px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #111 !important;
}

.manageDataCreateEdit {
  width: 80%;
  /* margin-left: 10% !important;
  padding-top: 12%; */
}

.manageRegionCtyLocCreateEdit {
  width: 43%;
  margin-left: 29% !important;
  /* padding-top: 10%; */
}

.manageRegionPUCreateEdit {
  width: 43%;
  margin-left: 29% !important;
  /* padding-top: 12%; */
}

.manageRegionDeanonymiseGrid {
  width: 51%;
  margin-left: 24% !important;
  /* padding-top: 2%; */
}

.manageRegionAssignSMGrid {
  width: 61%;
  margin-left: 20% !important;
  /* padding-top: 2%; */
}

.manageDataCreateEditHeading {
  font-weight: bold;
  margin-left: 2px !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  fill: #111 !important;
}

.LasttrainDate {
  margin-left: -3%;
}

.crossIconPopupAssgnEmp {
  margin-top: 24% !important;
  margin-left: -10%;
}

@media screen and (max-width: 1199px) {}

@media screen and (min-width: 1200px) {}

@media screen and (max-width: 1099px) {
  .manageRegionPUCreateEdit {
    width: 85%;
    margin-left: 10% !important;
    /* padding-top: 9%; */
  }

  .manageRegionCtyLocCreateEdit {
    width: 85%;
    margin-left: 9% !important;
  }

  .manageRegionDeanonymiseGrid {
    width: 90%;
    margin-left: 8% !important;
  }

  .manageRegionAssignSMGrid {
    width: 103%;
    margin-left: -1.5% !important;
    /* padding-top: 9%; */
  }
}

@media screen and (min-width: 1100px) and (max-width: 1399px) {
  .manageRegionPUCreateEdit {
    width: 43%;
    margin-left: 28% !important;
    /* padding-top: 9%; */
  }

  .manageRegionCtyLocCreateEdit {
    width: 43%;
    margin-left: 29% !important;
    /* padding-top: 9%; */
  }

  .manageRegionDeanonymiseGrid {
    width: 51%;
    margin-left: 24% !important;
    /* padding-top: 9%; */
  }

  .manageRegionAssignSMGrid {
    width: 80%;
    margin-left: 10% !important;
    /* padding-top: 0%;
  }
}

@media screen and (min-width: 1400px) {
  .manageRegionPUCreateEdit {
    width: 43%;
    margin-left: 29% !important;
    /* padding-top: 12%; */
  }

  .manageRegionCtyLocCreateEdit {
    width: 43%;
    margin-left: 29% !important;
    /* padding-top: 10%; */
  }

  .manageRegionDeanonymiseGrid {
    width: 69%;
    margin-left: 16% !important;
    /* padding-top: 3%;
  }
  .manageRegionAssignSMGrid {
    width: 81%;
    margin-left: 12% !important;
    /* padding-top: 2%; */
  }
}

.ThrtH {
  height: 525px;
}

@media only screen and (min-width: 1024px) {
  .ThrtImg {
    width: 98%;
    display: block;
  }
}

@media only screen and (max-width: 1023px) {
  .ThrtImg {
    width: 98%;
    display: none;
  }
}

.assignModToEmpJobmrLeft {
  margin-left: 0.8%;
}

.assignModToEmpLocmrLeft {
  margin-left: 1.8%;
}

.assignModToEmpAssgnmrLeft {
  margin-left: 2.8%;
}

@media screen and (max-width: 1099px) {
  .newsfeedheader {
    font-weight: 800;
    color: #363636;
    margin-left: 35px;
    margin-top: 30px;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88 !important;
    letter-spacing: normal;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1399px) {
  .newsfeedheader {
    font-weight: bold !important;
    color: #363636;
    margin-left: 35px;
    margin-top: 33px;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88 !important;
    letter-spacing: normal;
  }
}

@media screen and (min-width: 1400px) {
  .newsfeedheader {
    font-weight: bold !important;
    color: #363636;
    margin-left: 35px;
    margin-top: 30px;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88 !important;
    letter-spacing: normal;
  }
}

.modal-70-scrollable .modal-content {
  max-height: calc(100vh - 1.5rem) !important;
}

@media screen and (max-width: 999px) {
  .long-term-absentee {
    display: grid;
    grid-template-columns: 25px auto;
    column-gap: 10px;
    margin-top: 20%;
    align-items: baseline;
    white-space: nowrap;
    font-size: 13px;
  }
}

@media screen and (min-width: 1000px) {
  .long-term-absentee {
    display: grid;
    grid-template-columns: 25px auto;
    column-gap: 10px;

    align-items: baseline;
  }

  .marginBottom {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 599px) {
  #createButtonDDL {
    margin-right: 50px;
    margin-top: -50px;
  }

  .overlay-heading {
    color: white;
    font-size: 26px;
    font-weight: bold;
    bottom: -15px;
    position: relative;
    white-space: nowrap;
  }
}

@media screen and (min-width: 1200px) {
  .lastTrainedDate {
    height: 45px;
    position: absolute;
    bottom: 2px;
    right: -15%;
    border: 1px solid green;
    font-weight: 600;
    background-color: white !important;
    white-space: nowrap;
    color: #111 !important;
    width: auto;
    position: absolute;
    right: -15%;
    float: right;
    /* margin-left: 17%; */
    /* //padding: 5% 21%; */
  }
}

.lastTrainedDate :hover {
  border: 2px solid #007f00 !important;
}

@media screen and (max-width: 1199px) and (min-width: 800px) {
  .lastTrainedDate {
    height: 45px;
    position: absolute;
    bottom: 2px;
    right: -15%;
    border: 1px solid green;
    font-weight: 600;
    background-color: white !important;
    white-space: nowrap;
    color: #111 !important;
    width: auto;
    float: right;
  }
}

@media screen and (max-width: 799px) {
  .lastTrainedDate {
    height: 45px;
    position: absolute;
    bottom: 2px;
    right: -15%;
    border: 1px solid green;
    font-weight: 600;
    background-color: white !important;
    white-space: nowrap;
    color: #111 !important;
    width: auto;
    float: right;
  }
}

/* .css-b62m3t-container {
  border: 0px !important;
} */

.react-select__option--is-selected {
  background: none !important;
}

@media only screen and (min-width: 1000px) {
  .css-1n7v3ny-option {
    background-color: transparent;
    font-size: 18px !important;
    width: 100%;
    color: rgba(17, 17, 17, 0.7) !important;
    font-weight: 400;
  }

  .css-yt9ioa-option {
    background-color: transparent;
    font-size: 18px !important;
    width: 100%;
    color: rgba(17, 17, 17, 0.7) !important;
    font-weight: 400;
  }
}

@media only screen and (max-width: 999px) {
  .css-1n7v3ny-option {
    background-color: transparent;
    font-size: 14px !important;
    width: 100%;
    color: rgba(17, 17, 17, 0.7) !important;
    font-weight: 400;
  }

  .css-yt9ioa-option {
    background-color: transparent;
    font-size: 14px !important;
    width: 100%;
    color: rgba(17, 17, 17, 0.7) !important;
    font-weight: 400;
  }
}

.css-1n7v3ny-option:hover {
  color: green !important;
}

.css-9gakcf-option:hover {
  color: green !important;
}

.css-9gakcf-option {
  background-color: transparent;
  font-size: 18px !important;
  width: 100%;
  color: rgba(17, 17, 17, 0.7) !important;
  font-weight: 400;
}

.css-qc6sy-singleValue {
  /* font-family: "Roboto", sans-serif !important; */
  font-size: 16px !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  color: #111 !important;
}

.css-lr9fot-singleValue {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  color: #111 !important;
}

@media screen and (max-width: 999px) {
  .m-20 {
    position: absolute;
    top: 0;
    width: -webkit-fill-available;
    /* left: -5%; */
  }

  .not-f-region {
    top: 21% !important;
  }

  .not-f-threats {
    top: 21% !important;
  }

  .user-migration-btn {
    width: 160px !important;
    font-size: 10px !important;
    margin-top: 10px;
  }

  .toaststyle {
    top: -80% !important;
    left: -40%;
    width: 110%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1399px) {
  .m-20 {
    position: absolute;
    top: 0;
    width: -webkit-fill-available;
    left: -5%;
  }

  .not-f-region {
    top: 18% !important;
    left: -5%;
  }

  .not-f-threats {
    top: 27% !important;
    left: 12% !important;
  }

  .user-migration-btn {
    width: 210px !important;
  }

  .toaststyle {
    top: -80% !important;
    left: -4%;
    width: 110%;
  }
}

@media screen and (min-width: 1400px) {
  .m-20 {
    position: absolute;
    top: 0;
    width: -webkit-fill-available;
    left: -5%;
  }

  .not-f-region {
    top: 12% !important;
    left: -5%;
  }

  .not-f-threats {
    top: 18% !important;
    left: 12% !important;
  }

  .user-migration-btn {
    width: 210px !important;
  }

  .toaststyle {
    top: -80% !important;
    left: 0;
    width: 110%;
  }

  .reports-code-btn {
    height: 60px;
    font-size: 16px;
    width: 75% !important;
    background: white;
    color: #111 !important;
    font-weight: 600;
  }

  .m-144 {
    margin-left: 11rem;
  }

  .m-111 {
    margin-left: 11rem;
  }

  .m-112 {
    margin-left: 7rem;
  }

  .reportsBtnPos {
    text-align: right;
  }

  .bpDataprivacyBtn {
    margin-left: 105px !important;
  }

  .bpCodeofConductBtn {
    text-align: start !important;
  }
}

.privacyStatementmodal {
  max-height: 85vh !important;
}

@media only screen and (min-width: 999px) {
  .AssignEmpToast {
    left: 38%;
  }
}

@media only screen and (min-width: 1400px) {
  .assignEmpDateClass {
    left: 68% !important;
    top: 30% !important;
  }

  .assignModuleDateClass {
    left: 56% !important;
    top: 34% !important;
  }
}

@media only screen and (max-width: 1399px) {
  .assignEmpDateClass {
    left: 70% !important;
    top: 6% !important;
  }

  .assignModuleDateClass {
    left: 65% !important;
    top: 18% !important;
  }
}

@media only screen and (max-width: 998px) {
  .assignEmpDateClass {
    left: 45% !important;
    top: 5% !important;
  }

  .assignModuleDateClass {
    left: 31% !important;
    top: 7% !important;
  }

  .Value-Copy-12 {
    position: absolute;
    left: -39px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    width: 116%;
    line-height: 1.5;
    display: table-caption;
    top: 14px;
    letter-spacing: normal;
    color: #fff;
  }

  .reports-code-btn {
    min-width: 116% !important;
    white-space: nowrap;
    font-size: 10px;
    background-color: white !important;
    color: #111 !important;
    font-weight: 600;
  }

  .AssignEmpToast {
    left: 0;
  }
}

/* React Select Dropdown styling */
.css-4ljt47-MenuList {
  max-height: 180px !important;
}

.css-26l3qy-menu {
  margin-top: 0 !important;
}

.disabledIcons {
  pointer-events: none;
  color: #e8e8e8 !important;
  opacity: 0.4;
}

.disabledCreate {
  color: #e8e8e8 !important;
}

/* for Dropdown heights in dev envt */

.css-11unzgr {
  max-height: 180px !important;
}

/* modal popping out fix */
.modal.modal-static .modal-70 {
  -webkit-transform: none !important;
  transform: none !important;
}

#newsfeed__modal .modal-content {
  max-height: 90vh !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.emptychart_text {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptychart_textheading {
  font-size: 1.25rem;
  color: #111;
}

.nav-banner-header {
  background-color: #fbdb65;
  text-align: center;
  padding: 6px;
  /* font-weight: bold; */
  font-family: 'Roboto';
  font-size: 13px;
}

/* .assignEmpDateClass{
  left: 70% !important;
}

.assignModuleDateClass{
  left: 60% !important;
} */

.infobox-dat2valtext {
  font-size: 20px;
  white-space: pre-wrap;
  text-align: center;
  font-weight: bold;
  opacity: 0.7;
}

.ModalLayoutAss {
  display: grid;
  grid-template-columns: 100%;
  padding: 0px 20px;
}

.assessment_notification {
  color: black;
  font-size: 13px;
  font-family: 'Roboto-Medium';
  width: 27px;
  height: 27px;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grayBack {
  background-color: #e8e8e8;
  font-size: 12px;
  color: #111;
}

.amberBack {
  background-color: #edac1a;
}

.title-index {
  z-index: 0;
}

.disableEmail {
  display: none;
}

.noteClass {
  color: #686868 !important;
  font-size: 12px;
  font-weight: bold;
}

.disable-containerClick {
  pointer-events: none;
}

.nav-wrapper-pointer {
  cursor: not-allowed;
}

/* .mytraining-icon {
  display: inline-block;
}

.mytraining-icon svg {
  color: #007f00 !important;
  margin-right: 1px !important;
  opacity: 1 !important;
  transition: none !important;
}

.mytraining-icon svg g g path {
  stroke: currentColor;
} */

.critical-que-indicator {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.critical-icon {
  width: 32px;
  height: 30px;
  cursor: pointer;
}

.comm-header-bg {
  background-color: #007f00;
  position: relative;
}

.comm-header-bgPosition {
  position: relative;
}

.critical-info-box {
  width: 350px;
  height: auto;
  padding: 10px 10px 16px 15px;
  border-radius: 5px;
  box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
  border: solid 1px #4fc3f7;
  background-color: #ecfafe;
  color: #111;
  z-index: 3;
  position: absolute;
  right: 33px;
  bottom: 16px;
}

.managedata-margin {
  margin-right: 15px;
}

@media only screen and (max-width: 990px) {
  .managedata-margin {
    margin-right: 7px !important;
  }
}

.userinfo-flexcontainer {
  display: flex;
  flex-direction: column;
}

.userdisplayname {
  font-weight: bold;
  color: #000;
}

/*  Language Modal...*/

#modal-language {
  max-width: 65% !important;
}

#lang_modalheader .modal-title {
  padding: 0px 0px 0px 20px;
}

.languages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 3rem;
}

@media (max-width: 992px) {
  .languages {
    grid-template-columns: auto;
  }

  #modal-language {
    max-width: 79% !important;
  }
}

/*  ...Language Modal */

.btn-language.btn.active:hover {
  cursor: not-allowed;
}

.userprofile_ddl {
  width: max-content !important;
}

[data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;

  border-radius: 10px;
  width: 400px;
}

[data-tooltip]:hover:before {
  opacity: 1;

  background-color: #fff;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
  color: #111;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 5px;
  width: max-content;
  margin-top: 42px;
  margin-left: -50px;
  margin-right: 200px;
}

[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
}

.greenClass {
  background-color: green !important;
}

.txtWhileColour {
  color: white !important;
}

.btn.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* UI issue fixes caused by :- Bootstrap upgrade from v4 - v5 */
.btn svg {
  opacity: 1;
  color: #111;
}

.btn:has(svg) {
  display: inline-block;
}

body {
  line-height: 1.5;
}

.edit-assessment-checkbox:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.5;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.react-select--is-disabled {
  background-color: rgb(250, 250, 250);
}